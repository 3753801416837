import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { toast as myToast } from "react-toastify";
import catalogAPIs from "../api/catalogAPIs";

export const useCreateCatalog = (origin) => {
  const navigate = useNavigate();
  const quaryClient = useQueryClient();
  return useMutation({
    mutationFn: catalogAPIs.createCatalog,
    onSuccess: (response) => {
      const { data } = response;
      if (data?.status === 200) {
        quaryClient.invalidateQueries("catalogData");
        quaryClient.removeQueries("catalogCountData");
        navigate(origin.backPath);
        myToast.success(data?.message || "Catalog created successfully!", {
          position: "top-right",
          autoClose: 2000,
        });
      } else {
        myToast.error(`Error: ${data?.message || "Failed to create catalog"}`, {
          position: "top-right",
          autoClose: 2000,
        });
      }
    },
    onError: (error) => {
      const errorMessage = error.response?.data?.message || error.message || "An error occurred";
      myToast.error(`Error: ${errorMessage}`, {
        position: "top-right",
        autoClose: 2000,
      });
    },
  });
};
