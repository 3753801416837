import React, { useContext } from "react";
// import { Box, Divider, IconButton } from "@mui/material";
import { Box, Divider, IconButton, styled } from "@mui/material";

import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { SettingContext, useSettingContext } from "context/SettingContext";

import { ReactComponent as EditIcon } from "assets/icons/CatalogueCard/edit.svg";

import Button from "components/ui/Button";
// import styled from "styled-components";
// import CreateBtn from "layouts/SubComponets/CreateBtn";
import SettingBreadCrumbComponent from "components/ui/SettingBreadCrumbComponent";
import BreadCrumbComponent from "components/ui/BreadCrumb";
import EditUserChildHeader from "./EditUserChildHeader";
import SelectRoleBtn from "./SelectRoleBtn";
import { getAllRoles, removeRoles } from "features/Setting/Role/Apis/get-role";
import { toast } from "react-toastify";
import Unrestrict from "layouts/SubComponets/Unrestrict";
import IconsChild from "layouts/SubComponets/IconsChild";
import SyncButton from "layouts/SubComponets/SyncButton";

const StyledButton = styled(Button)(({ theme, variant, disabled }) => ({
  width: variant === "save" ? "168px" : variant === "cancel" ? "110px" : variant === "save-rights" ? "150px" : "160px",
  fontFamily: variant === "save-rights" ? "poppins" : "karla",
  fontWeight: 500,
  fontSize: "18px",
  lineHeight: "21.04px",
  height: "41px",
  borderRadius: "5px",
  color: disabled ? "#B0B0B0" : variant === "save" ? "#50C878" : variant === "cancel" ? "#E77B7B" : variant === "save-rights" ? "#50C878" : "#FFF",

  border: `1px solid ${disabled ? "#B0B0B0" : variant === "save" ? "#50C878" : variant === "cancel" ? "#E77B7B" : variant === "save-rights" ? "#50C878" : "#fff"}`,
  textTransform: "none",
  backgroundColor: "transparent",

  "&:hover": {
    backgroundColor: disabled ? "transparent" : variant === "save" ? "#50C878" : variant === "cancel" ? "#E77B7B" : variant === "save-rights" ? "#50C878" : "#fff",
    color: disabled ? "#B0B0B0" : variant === "save" ? "#fff" : variant === "cancel" ? "#fff" : variant === "save-rights" ? "#fff" : "#000",
  },
}));

function SettingChildHeader({ icon }) {
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  const {restrictBtn,MainSyncbutton,syncButton,categorySyncButton,syncMakeTypeButton,collectionSyncbutton}= useContext(SettingContext)

  const {
    createUser,
    createBtn,
    creatBtnName,
    setCreateRole,
    roleEditBtn,
    createRole,
    openEditPage,
    setOpenEditPage,
    setRoleEditBtn,
    isDltClick,
    isUpdate,
    setIsUpdate,
    setUndoRole,
    undoRole,
    isChange,
    setIsChange,
    isRightPage,
    enableSaveRightsBtn,
    setSaveRightModalOpen,
    isLimitPage,
    enableSaveLimitsBtn,
    isNameChanged,
    missingImagesCount,
    restricateCatagoryCount,
  } = useSettingContext();

  const navigation = useNavigate();
  const location = useLocation();
  const state = location.state;

  let nextName = ["Settings"];

  useEffect(() => {
    const pathnames = location.pathname.split("/").filter((x) => x);
    const newBreadcrumbs = [];
    let newPath = "";
    let pathLength = pathnames.length;
    pathnames.forEach((name, index) => {
      newPath = newPath + "/" + name;
      if (name === "missing-images") {
        newBreadcrumbs.push({
          name: `Missing Images - ${missingImagesCount}`,
          path: newPath,
        });
      } else if (name === "restricted-designs" && pathLength == 2) {
        let categoryCount = `${restricateCatagoryCount?.totalCategoryCount} (${restricateCatagoryCount?.totalDesginCount})`;
        newBreadcrumbs.push({
          name: `Restricted Category - ${categoryCount}`,
          path: newPath,
        });
      } else {
        newBreadcrumbs.push({
          name: nextName[index] ? nextName[index] : state ? state.name[index - 1] : "",
          path: newPath,
        });
      }
    });

    if (state && state.subName) {
      newBreadcrumbs.push({
        name: state.subName,
        path: "",
      });
    }

    setBreadcrumbs(newBreadcrumbs);
  }, [location, missingImagesCount, restricateCatagoryCount]);

  const handleUserFromOpen = () => {
    let state = {
      ...location?.state,
      name: [...location?.state?.name, "Create User"],
    };
    navigation("/Setting/users/addUser", { state });
  };

  const openCreateModal = () => {
    if (creatBtnName === "Create User") {
      handleUserFromOpen();
    }

    if (creatBtnName === "Create Role") {
      setCreateRole(true);
    }
  };

  const handleOpenEditPage = () => {
    setOpenEditPage(true);
    setRoleEditBtn(false);
  };

  const UndoDeleteRole = () => {
    handleCancelButton();

    setUndoRole(true);
    setIsChange(false);
  };

  const handleCancelButton = () => {
    let checkLocalStorageRemove = JSON.parse(localStorage.getItem("deletedRoles")) || [];
    let checkLocalStorageUpdate = JSON.parse(localStorage.getItem("updatedRoles")) || [];

    if (checkLocalStorageRemove.length > 0 || checkLocalStorageUpdate.length > 0) {
      setIsChange(true);
    } else {
      setIsChange(false);
    }
  };

  const goBackToRolePage = async () => {
    setOpenEditPage(false);
    setRoleEditBtn(true);
  };

  // const handleRoleSave = async () => {
  //   let checkRolesToBeRemove = JSON.parse(localStorage.getItem("deletedRoles")) || [];
  //   const checkRolesToBeUpdate = JSON.parse(localStorage.getItem("updatedRoles")) || [];

  //   if (checkRolesToBeRemove.length > 0) {
  //     // let roleIds = checkRolesToBeRemove.map((obj) => obj._id);
  //     let roleIds = checkRolesToBeRemove.map((obj) => {
  //       return { roleId: obj._id, name: obj.roleName };
  //     });
  //     // console.log("=========", checkRolesToBeRemove);
  //     let isDelete = true;

  //     let response = await removeRoles(roleIds, isDelete);

  //     // here also need to call update api of  role or need to change in payload by sending isDelet : "true"/"false"
  //     if (response.status === 200) {
  //       setOpenEditPage(false);
  //       setRoleEditBtn(true);
  //       localStorage.removeItem("deletedRoles");

  //       // getAllRoles();
  //     } else {
  //       alert("error");
  //     }
  //   }

  //   if (checkRolesToBeUpdate.length > 0) {
  //     let roleIds = checkRolesToBeUpdate.map((obj) => {
  //       return { roleId: obj.roleToBeUpdated._id, name: obj.updatedRoleName };
  //     });
  //     let isDelete = false;
  //     let response = await removeRoles(roleIds, isDelete);

  //     if (response.status === 200) {
  //       setOpenEditPage(false);
  //       setRoleEditBtn(true);
  //       localStorage.removeItem("updatedRoles");

  //       // getAllRoles();
  //     } else {
  //       alert("error");
  //     }
  //   }
  // };

  const handleRoleSave = async () => {
    const handleRoles = async (roles, isDelete, storageKey) => {
      if (roles.length === 0) return;

      const roleIds = roles.map(({ _id, roleName, roleToBeUpdated, updatedRoleName }) => ({
        roleId: _id || roleToBeUpdated._id,
        name: roleName || updatedRoleName,
      }));

      const response = await removeRoles(roleIds, isDelete);
      if (response.status === 200) {
        setOpenEditPage(false);
        setRoleEditBtn(true);
        setIsChange(false);
        localStorage.removeItem(storageKey);
        // toast.success(response.message, {
        //   autoClose: 1000,
        //   hideProgressBar: false,
        // });
      } else {
        alert("Error saving roles");
      }
    };

    const updatedRoles = JSON.parse(localStorage.getItem("updatedRoles")) || [];
    const deletedRoles = JSON.parse(localStorage.getItem("deletedRoles")) || [];

    await handleRoles(updatedRoles, false, "updatedRoles");
    await handleRoles(deletedRoles, true, "deletedRoles");
  };

  const handleSaveRightsModal = () => {
    setSaveRightModalOpen(true);
  };

  const handleSaveLimitsModal = () => {
    // alert("handleSaveLimitsModal");
    setSaveRightModalOpen(true); // same using for Rights page modal
  };

  return (
    <div style={{ position: "sticky", zIndex: "1000", top: "70px", background: "black" }}>
      <Box
        sx={{
          border: "1px solid #000",
          display: "flex",
          justifyContent: "center",
          padding: "0px",
          paddingBottom: "10px",
          position: "sticky",
          zIndex: "999",
          top: "70px",
          backgroundColor: "black !important",
        }}
        maxWidth={"100%"}
      >
        <Divider orientation="horizontal" flexItem style={{ borderColor: "#444444", width: "95.4%" }} />
      </Box>
      {state?.isEditUser ? (
        <>
          <EditUserChildHeader />
        </>
      ) : (
        <>
          <div style={{ display: "grid", gridAutoFlow: "column", paddingTop: "10px" }}>
            <div className="child-header-sub" style={{ marginLeft: "20px", marginRight: "20px" }}>
              {!openEditPage ? (
                <>
                  {icon}
                  <SettingBreadCrumbComponent breadcrumbs={breadcrumbs} />
                  {syncButton && <SyncButton value={"set"}/>}
                  {syncMakeTypeButton && <SyncButton value={"makeType"}/> }
                  {collectionSyncbutton && <SyncButton value={"collection"} />  }
                  {categorySyncButton && <SyncButton value={"SubCategory"} />  }
                  {MainSyncbutton && <SyncButton />  }                  
                  <div style={{ position: "absolute", right: "45px" }}>{restrictBtn && <Unrestrict location={location} />}</div>
                </>
              ) : (
                <h7 style={{ color: "#fff", fontFamily: "poppins", fontSize: "24px", fontWeight: "400" }}>Edit Page</h7>
              )}
            </div>
            <div className="child-header-sub" style={{ justifyContent: "flex-end", marginRight: "20px" }}>
              {!openEditPage ? (
                <>
                  {roleEditBtn && (
                    <IconButton disableRipple onClick={handleOpenEditPage}>
                      <EditIcon />
                    </IconButton>
                  )}

                  {createBtn && <StyledButton onClick={openCreateModal}>{creatBtnName}</StyledButton>}

                  {/* ----------------------- Rights Page ----------------------------- */}
                  {isRightPage && <SelectRoleBtn name={"Select Role"} />}
                  {isRightPage && (
                    <StyledButton variant="save-rights" disabled={!enableSaveRightsBtn} onClick={handleSaveRightsModal}>
                      Save Rights
                    </StyledButton>
                  )}

                  {/* ----------------------- Limits Page ----------------------------- */}

                  {isLimitPage && <SelectRoleBtn name={"Select User"} />}
                  {isLimitPage && (
                    <StyledButton variant="save-rights" disabled={!enableSaveLimitsBtn} onClick={handleSaveLimitsModal}>
                      Save Limits
                    </StyledButton>
                  )}
                </>
              ) : (
                <>
                  <StyledButton variant="save" disabled={!(isDltClick || isNameChanged)} onClick={handleRoleSave}>
                    Save Changes
                  </StyledButton>

                  <StyledButton variant="cancel" onClick={isChange ? UndoDeleteRole : goBackToRolePage}>
                    Cancel
                  </StyledButton>
                </>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default SettingChildHeader;
