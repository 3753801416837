import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Breadcrumbs, Typography } from "@mui/material";
import SeparatorIcon from "components/Icons/SeparatorIcon";

const SettingBreadCrumbComponent = ({ breadcrumbs, onBreadcrumbClick }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isRestricted = location?.state?.isRestricted || false;

  const handleClick = (breadcrumb) => {
    let state = { ...location.state, name: [...location?.state?.name, breadcrumb.name], subName: "" };

    const pathnames = breadcrumb.path.split("/").filter((x) => x);
    if (breadcrumb.name === "Set") {
      onBreadcrumbClick(breadcrumb);
    }

    switch (pathnames[0]) {
      case "Catalog": {
        if (pathnames.length === 1) {
          state.view = false;
          state.edit = false;
        } else if (pathnames.length === 2) {
          state.edit = false;
          state.view = true;
        } else {
          state.subName = "Edit";
        }
        break;
      }
      default: {
        break;
      }
    }

    navigate(breadcrumb.path, { state });
  };

  return (
    <Breadcrumbs aria-label="breadcrumb" separator={<SeparatorIcon />}>
      {breadcrumbs.map((breadcrumb, index) => (
        <span key={index}>
          {index === breadcrumbs.length - 1 ? (
            <Typography color="textPrimary" sx={{ fontFamily: "Poppins, sans-serif", fontWeight: "400", fontSize: "24px" }}>
              {breadcrumb.name}
            </Typography>
          ) : (
            <Typography
              color="textPrimary"
              sx={{ fontFamily: "Poppins, sans-serif", fontWeight: "400", fontSize: "24px", color: "inherit", cursor: "pointer" }}
              onClick={!isRestricted ? () => handleClick(breadcrumb, index) : null}
            >
              {breadcrumb.name}
            </Typography>
          )}
        </span>
      ))}
    </Breadcrumbs>
  );
};

export default SettingBreadCrumbComponent;
