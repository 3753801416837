import React, { useEffect, useState } from "react";
import Modal from "components/ui/Modal";
import "../../../../styles/sharelinkmodal.css";
import { Box, FormControl, FormLabel, Grid, OutlinedInput, Typography, useMediaQuery, styled } from "@mui/material";
import Button from "components/ui/Button";
import { ThemeProvider, useTheme } from "@mui/material/styles";
import custumTheme from "../../../../../../thems/catalogueShareLinkThems";
import PhoneInput from "react-phone-input-2";
import { useCatalogSettingContext } from "context/CatalogSettingContext";
import "react-phone-input-2/lib/style.css";
import apiClient from "lib/api-client";
import debounce from "lodash.debounce";

const CustomButton = styled(Button)(({}) => ({
  width: "61%",
  fontFamily: "Karla",
  fontWeight: 400,
  fontSize: "18px",
  lineHeight: "22px",
  height: "50px",
  borderRadius: "5px",
  color: "#00000",
  border: `1px solid #ffffff `,
  textTransform: "none",
  backgroundColor: "#50C878",
  boxShadow: "none",
  "&:hover": {
    backgroundColor: "#50C878",
    boxShadow: "none",
  },
  "&.Mui-disabled": {
    backgroundColor: "#444444",
    color: "#000000",
    border: "1px solid #444444",
  },
}));

const fetchClientName = async (value, setLinkData, setIsAlredyFetch) => {
  setIsAlredyFetch(false);
  try {
    const response = await apiClient.post("/api/v1/catalogSetting/catalog-client/getClientName", {
      mobileNumber: value,
    });

    if (response.status === 200) {
      const clientName = response.data?.clientName || "";

      if (clientName) {
        setLinkData((data) => ({
          ...data,
          clientName,
        }));
        setIsAlredyFetch(true);
      } else {
        setLinkData((data) => ({
          ...data,
          clientName: "",
        }));
      }
    }
  } catch (error) {
    console.error("Error fetching client name:", error);
  }
};

const debouncedFetchClientName = debounce(fetchClientName, 500);

function CatalogueAddAndEditClientDetails({ open, setOpen, handleRef, isError, isSuccess }) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { updateClient, setUpdateClient, isEditClinet, setIsEditClinet } = useCatalogSettingContext();
  const [isAlredyFetch, setIsAlredyFetch] = useState(false);
  const [linkData, setLinkData] = useState({
    clientName: "",
    mobileNumber: "",
    hours: "",
  });
  const [isPhoneValid, setIsPhoneValid] = useState(false);
  const handleOnChangeEvent = (e) => {
    const { name, value } = e.target;
    setLinkData((data) => ({
      ...data,
      [name]: value,
    }));
  };

  const handlePhoneChange = (value) => {
    setLinkData((data) => ({
      ...data,
      mobileNumber: value,
    }));

    if (!isEditClinet && value && value.length >= 5) {
      const newMobileNumber = `+91 ${value.slice(2)}`;
      debouncedFetchClientName(newMobileNumber, setLinkData, setIsAlredyFetch);
    }
  };

  const handleClose = () => {
    setOpen(false);
    handleResetForm();
    setUpdateClient({});
    setIsAlredyFetch(false);
  };

  const handleValidation = (inputNumber, country) => {
    const dialCode = country.dialCode;
    const cleanedNumber = inputNumber.replace(/\s+/g, "");
    // For India, ensure it's exactly 10 digits after the country code
    if (dialCode === "91") {
      const numberWithoutCode = cleanedNumber.replace(`${dialCode}`, "");
      return numberWithoutCode.length === 10;
    }

    // For other countries, check if the number length matches the country's typical length
    let typicalLength = country.format.match(/\./g)?.length;
    let numberWithoutCode = cleanedNumber.replace(`${dialCode}`, "");
    numberWithoutCode = `${dialCode}${numberWithoutCode}`;
    if (typicalLength >= 17) {
      typicalLength = 15;
    }
    return numberWithoutCode.length === typicalLength;
  };

  const handleSubmitForm = () => {
    const newMobileNumber = `+91 ${linkData.mobileNumber.slice(2)}`;
    const newData = isEditClinet
      ? {
          ...updateClient,
          mobileNumber: newMobileNumber,
          clientName: linkData.clientName,
        }
      : {
          // id: Math.floor(1000 + Math.random() * 9000),
          mobileNumber: newMobileNumber,
          clientName: linkData.clientName,
          hours: Number(linkData.hours),
          // firstLogIn: 0,
          // noOfLogIn: 0,
        };

    handleRef(newData);
  };

  useEffect(() => {
    if (isSuccess) {
      handleClose();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      setOpen(true);
    }
  }, [isError]);

  const handleResetForm = () => {
    setLinkData({
      clientName: "",
      mobileNumber: "",
      hours: "",
    });
  };

  useEffect(() => {
    if (isEditClinet && updateClient) {
      setIsAlredyFetch(false);
      setLinkData({
        clientName: updateClient.clientName || "",
        mobileNumber: updateClient.mobileNumber || "",
        hours: "",
      });
    }
  }, [updateClient, isEditClinet]);

  return (
    <ThemeProvider theme={custumTheme}>
      <Modal open={open} handleClose={handleClose} style={{ width: isSmallScreen ? "400px" : "400px", mr: 2.5 }}>
        <Grid container spacing={2.5}>
          <Grid item lg={12}>
            <Typography className="catalogue-share-link-modal-heading">{!isEditClinet ? "Add Mobile Number" : "Edit Client Details"}</Typography>
          </Grid>
          <Grid item lg={12} width={"100%"}>
            <FormControl fullWidth>
              <FormLabel>Mobile Number</FormLabel>
              <PhoneInput
                country={"in"}
                value={linkData.mobileNumber}
                onChange={handlePhoneChange}
                placeholder="Phone Number"
                countryCodeEditable={false}
                disableDropdown={true}
                autoFormat={true}
                inputProps={{
                  name: "mobileNumber",
                  required: true,
                  autoFocus: true,
                  autoComplete: "off",
                }}
                inputStyle={{
                  backgroundColor: !isPhoneValid ? "white" : "white",
                }}
                dropdownStyle={{
                  height: !isEditClinet ? "150px" : "120px",
                  fontSize: "20px",
                  paddingTop: "10px",
                  width: "360px",
                  marginTop: "15px",
                  borderRadius: "5px",
                }}
                isValid={(inputNumber, country) => {
                  const isValid = handleValidation(inputNumber, country);
                  setIsPhoneValid(isValid);
                  return isValid;
                }}
              />
            </FormControl>
          </Grid>
          <Grid item lg={12} width={"100%"}>
            <FormControl fullWidth>
              <FormLabel>Client Name</FormLabel>
              <OutlinedInput
                spellCheck={false}
                autoFocus={false}
                autoComplete="off"
                type="text"
                value={linkData.clientName}
                onChange={handleOnChangeEvent}
                placeholder="Client Name"
                name="clientName"
                id="clientName"
                readOnly={isAlredyFetch}
              />
            </FormControl>
          </Grid>

          {!isEditClinet && (
            <Grid item lg={12} width={"100%"}>
              <FormControl>
                <FormLabel>Hours</FormLabel>
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                  <OutlinedInput
                    sx={{ width: "55px", textAlign: "center" }}
                    autoComplete="off"
                    value={linkData.hours}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^\d{0,2}$/.test(value)) {
                        const numericValue = parseInt(value, 10);
                        if (!isNaN(numericValue) && numericValue >= 1 && numericValue <= 72) {
                          handleOnChangeEvent(e);
                        } else if (value === "") {
                          handleOnChangeEvent(e);
                        }
                      }
                    }}
                    placeholder="HH"
                    name="hours"
                    id="hours"
                    type="text"
                    inputProps={{
                      maxLength: 2,
                      inputMode: "numeric",
                    }}
                  />
                  <Typography className="catalogue-share-link-modal-hours">Between 1 To 72 Hours</Typography>
                </Box>
              </FormControl>
            </Grid>
          )}
          <Grid item lg={12} display="flex" justifyContent="center" sx={{ width: "100%" }}>
            <CustomButton
              variant="contained"
              disabled={
                (updateClient?.clientName === linkData.clientName && updateClient?.mobileNumber === linkData.mobileNumber) ||
                linkData.clientName === "" ||
                (!isEditClinet && linkData.hours === "") ||
                linkData.mobileNumber === "" ||
                !isPhoneValid
                // linkData.mobileNumber.length < 12
              }
              onClick={handleSubmitForm}
            >
              {!isEditClinet ? "Add Number & Share" : "Save Details"}
            </CustomButton>
          </Grid>
        </Grid>
      </Modal>
    </ThemeProvider>
  );
}

export default CatalogueAddAndEditClientDetails;
