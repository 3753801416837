import { Padding } from '@mui/icons-material';
import { Card, Grid, Typography } from '@mui/material';
import { SettingContext } from 'context/SettingContext';
import React, { useContext, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

const SyncPage = () => {

  const syncData = [
    {name:"Sub-Category",path:"subCategory",value:26},
    {name:"Make Type",path:"makeType",value:26},
    {name:"Sets",path:"sets",value:26},
    {name:"Collection",path:"collection",value:26},
    {name:"Restrict design",path:"restrict",value:26}
  ]

  const {setMainSyncButton} = useContext(SettingContext);

  const navigate = useNavigate();
  const location = useLocation();
  let preState = location.state;

  const style = {
    display:"flex",
    justifyContent: "space-between",
    alignItems:"center",
    fontFamily: "Karla",
    fontWeight: "400",
    fontSize: "20px",
    minWidth: 275,
    maxWidth: "317px",
    height: 62,
    borderRadius: "5px",
    padding:"0px 24px"
  };

  const handleClick = (name, path) => {    
    const state = { ...preState, name: [preState.name && preState.name[0], `${name}`] };    
    navigate(`/Setting/synchronize/${path}`, { state });        
  };

  useEffect(()=>{
    setMainSyncButton(true);
    return ()=>{
      setMainSyncButton(false);
    }
  },[])

  return (
    <div>      
      <Grid
        container
        className="Grid-padding"
        sx={{ paddingLeft: "18px", paddingRight: "18px" }}
        rowSpacing={{ xs: 1, sm: 2, md: 2, lg: 2, xl: 3 }}
        columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 2, xl: 3 }}
        justifyContent="space-beetween"
        alignItems="center"
      >
        {syncData.map((el) => { 
          const { name, path,value } = el;
          return (
            <Grid item xs={12} sm={12} md={4} lg={3} xl={2.3} key={name} onClick={() => handleClick(name, path)}>              
              <Card style={style} >
                <Typography fontSize={"20px"} fontFamily={"Karla"}>
                  {name}
                </Typography>
                <Typography fontSize={"20px"} fontFamily={"Karla"}>
                  {value}
                </Typography>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </div>
  )
}

export default SyncPage;