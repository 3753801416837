import { useFilter } from "context/FilterContext";
import { usePermissions } from "context/PermissionsContext";
import SortContext from "context/SortContext";
import Products from "features/Category/categoryProd/components/Products";
import apiClient from "lib/api-client";
import React from "react";
import { useLocation } from "react-router-dom";
import { findSpecificPage, findSpecificSubPage, hasAccessSubPage } from "utils/checkUserRigths";

const SubCategoriesProd = () => {
  const { setSortOptions, setSelectedSortValue } = React.useContext(SortContext);
  const { setFilter, setFilterValues, setBackendFilterValues } = useFilter();
  const location = useLocation();
  const permission = usePermissions();

  const hasAccessToSeeSales = hasAccessSubPage(findSpecificSubPage(findSpecificPage("Settings", permission), "Sales"), "view");

  React.useEffect(() => {
    setFilter(true);
    setSortOptions([
      { id: 1, name: "Design No. (Low to High)", sortBy: "Design No", order: "asc" },
      { id: 2, name: "Design No. (High to Low)", sortBy: "Design No", order: "desc" },
      { id: 3, name: "Design Date (Oldest to Newest)", sortBy: "Design Date", order: "asc" },
      { id: 4, name: "Design Date (Newest to Oldest)", sortBy: "Design Date", order: "desc" },
      { id: 5, name: "Gold Net Weight (Low to High)", sortBy: "Gold Net Weight", order: "asc" },
      { id: 6, name: "Gold Net Weight (High to Low)", sortBy: "Gold Net Weight", order: "desc" },
      { id: 7, name: "Diamond Weight (Low to High)", sortBy: "Diamond Weight", order: "asc" },
      { id: 8, name: "Diamond Weight (High to Low)", sortBy: "Diamond Weight", order: "desc" },
      { id: 9, name: "Sales (Low to High)", sortBy: "Sales", order: "asc", access: hasAccessToSeeSales },
      { id: 10, name: "Sales (High to Low)", sortBy: "Sales", order: "desc", access: hasAccessToSeeSales },
    ]);

    return () => {
      setFilter(false);
      setFilterValues({
        Labour: [],
        Class: [],
        "Diamond Pieces": [],
        "Diamond Weight": [],
        "Gold Gross-Weight": [],
        "Gold Net-Weight": [],
        "Design In Collection": [],
        "Design In Sets": [],
        "Highest Seller": [],
        "Best Seller Design": [],
      });
      setSortOptions([]);
      setSelectedSortValue(null);
    };
  }, []);

  React.useEffect(() => {
    const subCategoryName = location.state?.name[1].split("-")[0].trim();

    const fetchFilterData = async () => {
      try {
        const response = await apiClient.post(`/api/v1/collection/getDesignSlidersData`, { categoryName: location.state.name[0], subCategoryName: subCategoryName === "Blank" ? "" : subCategoryName });
        if (response.data && response.data.data) {
          const { diamondPieces, diamondWeight, grossWeight, netWeight } = response.data.data;
          let filterData = {
            Labour: ["R - Regular", "H - High", "X - Exclusive"],
            Class: ["Gold", "Silver", "Bronze"],
            "Design In Collection": ["All"],
            "Design In Sets": ["All"],
            "Diamond Pieces": diamondPieces || [],
            "Diamond Weight": diamondWeight || [],
            "Gold Gross-Weight": grossWeight || [],
            "Gold Net-Weight": netWeight || [],
            "Highest Seller": [],
            "Best Seller Design": ["All"],
          };
          // Update the state with the fetched data
          setBackendFilterValues((prev) => filterData);
          setFilterValues((prev) => filterData);
        }
      } catch (error) {
        console.error("Error fetching filter data:", error);
      }
    };

    fetchFilterData();
  }, []);

  return <Products />;
};

export default SubCategoriesProd;
