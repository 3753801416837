import Header from "components/Header/Header";
import Sidebar from "components/Sidebar/Siderbar";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import "./main-layout-style.css";
import { FilterProvider } from "context/FilterContext";
import SortContextProvider from "context/sortContextProvider";
import { GlobalProvider } from "context/GlobalContext";
import { ActivityHeader } from "components/Header/ActivityHeader";
import { usePermissions } from "context/PermissionsContext";
import { findSpecificPage, forwordRouteAccordingToRights, hasAccessPage } from "utils/checkUserRigths";
import { useEffect, useState } from "react";
import { CatalogSettingProvider } from "context/CatalogSettingContext";

const MainLayout = () => {
  const permissions = usePermissions();
  const [hasAccess, setHasAccess] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const state = location.state;
  const innerChnages = state && state.innerChnages ? state.innerChnages : false;
  const edit = state && state.edit ? state.edit : false;
  const isActivity = state && state.isActivity ? state.isActivity : false;
  useEffect(() => {
    if (permissions) {
      if (location.pathname === "/") {
        let nextRoute = forwordRouteAccordingToRights(permissions);
        navigate(nextRoute);
        return;
      }

      const path = location.pathname.split("/")[1];

      if (path === "Cart") {
        setHasAccess(true);
      } else {
        const accessGranted = hasAccessPage(findSpecificPage(location.pathname.split("/")[1], permissions), "view");
        setHasAccess(accessGranted);
      }
    }
  }, [location.pathname, permissions, navigate]);

  const AccessContent = () => {
    return hasAccess ? <Outlet /> : <p style={{ color: "black" }}>Not Access</p>;
  };

  return (
    <>
      <GlobalProvider>
        <FilterProvider>
          <SortContextProvider>
            <CatalogSettingProvider>
              <div className={`${!innerChnages && !edit ? "main-layout" : "main-layout-left"}`}>
                {!isActivity ? <Header /> : <ActivityHeader />}
                <div className="main-body">
                  {!innerChnages && !edit ? <Sidebar /> : ""}
                  <div className="content">
                    <AccessContent />
                  </div>
                </div>
              </div>
            </CatalogSettingProvider>
          </SortContextProvider>
        </FilterProvider>
      </GlobalProvider>
    </>
  );
};

export default MainLayout;
