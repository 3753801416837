import React, { useEffect } from "react";
import RenderSets from "features/set/components/Sets";
import SortContext from "context/SortContext";
import { useGlobalContext } from "context/GlobalContext";
import { useLocation } from "react-router-dom";
import { useRemoveDesings } from "features/set/hooks/useRemoveDesigns";
import { usePermissions } from "context/PermissionsContext";
import { findSpecificPage, findSpecificSubPage, hasAccessPage, hasAccessSubPage } from "utils/checkUserRigths";
import { useFilter } from "context/FilterContext";
import apiClient from "lib/api-client";

const Sets = () => {
  const { setSortOptions, setSelectedSortValue, setIsEdit, isEdit } = React.useContext(SortContext);
  const { setCreateBtn, setCreateBtnName, setCreateBtnOption, setSearchPlaceHolder } = useGlobalContext();
  const { filterValues, setIsSetFilter, setFilterValues, setBackendFilterValues } = useFilter();
  const location = useLocation();
  const innerChnages = location?.state?.innerChnages;
  const { handleCancel: UIUpdate } = useRemoveDesings();

  const permission = usePermissions();

  const setCreateAccess = hasAccessPage(findSpecificPage("Sets", permission), "add");
  const collectionViewAccess = hasAccessPage(findSpecificPage("Collection", permission), "view");
  const categoryViewAccess = hasAccessPage(findSpecificPage("Category", permission), "view");
  const setEditAccess = hasAccessPage(findSpecificPage("Sets", permission), "edit");

  const hasAccessToSeeSales = hasAccessSubPage(findSpecificSubPage(findSpecificPage("Settings", permission), "Sales"), "view");

  React.useEffect(() => {
    setSortOptions([
      { id: 1, name: "Set No. (Low to High)", sortBy: "Set Date", order: "asc" },
      { id: 2, name: "Set No. (High to Low)", sortBy: "Set Date", order: "desc" },

      { id: 3, name: "Design Pieces (Low to High)", sortBy: "Number of Styles", order: "asc" },
      { id: 4, name: "Design Pieces (High to Low)", sortBy: "Number of Styles", order: "desc" },

      { id: 5, name: "Set Date (Oldest to Newest)", sortBy: "Set Date", order: "asc" },
      { id: 6, name: "Set Date (Newest to Oldest)", sortBy: "Set Date", order: "desc" },

      { id: 7, name: "Gold Net Weight (Low to High)", sortBy: "Gold Weight", order: "asc" },
      { id: 8, name: "Gold Net Weight (High to Low)", sortBy: "Gold Weight", order: "desc" },

      { id: 9, name: "Diamond Weight (Low to High)", sortBy: "Diamond Weight", order: "asc" },
      { id: 10, name: " Diamond Weight (High to Low)", sortBy: "Diamond Weight", order: "desc" },

      { id: 11, name: "Sales (Low to High)", sortBy: "Sales", order: "asc", access: hasAccessToSeeSales },
      { id: 12, name: "Sales (High to Low)", sortBy: "Sales", order: "desc", access: hasAccessToSeeSales },
    ]);

    setIsSetFilter(true);

    //for create set btn
    if (!innerChnages) {
      setCreateBtn(setCreateAccess);
      setCreateBtnName("Create Set");
      setCreateBtnOption([
        { option: "By Category", link: "/Category", view: categoryViewAccess },
        { option: "By Collection", link: "/Collection", view: collectionViewAccess },
      ]);
      setSearchPlaceHolder("Search by set or design number");
      return () => {
        setCreateBtn(false);
        setCreateBtnName("");
        setCreateBtnOption([]);
        setSearchPlaceHolder("Search");
        setFilterValues({});
        setIsSetFilter(false);
      };
    }

    return () => {
      //for create set btn
      setSortOptions([]);
      setSelectedSortValue(null);
      setFilterValues({});
      setIsSetFilter(false);
    };
  }, [innerChnages, isEdit]);

  useEffect(() => {
    //for edit view
    if (isEdit === "click") {
      setSortOptions([]);
      setSelectedSortValue(null);
      setCreateBtn(false);
      setCreateBtnName("");
      setCreateBtnOption([]);
      setIsEdit("click");
      setFilterValues({});
      setIsSetFilter(false);
    } else {
      if (innerChnages) {
        setIsEdit("");
      } else {
        // setIsEdit("view");
        setIsEdit(setEditAccess ? "view" : "");
      }
    }
    // else {
    //   const set = JSON.parse(localStorage.getItem("set"));

    //   if (Array.isArray(set) && set.length > 0 && !innerChnages) {
    //     setIsEdit("view");
    //   } else {
    //     setIsEdit("");
    //   }
    // }
    return () => {
      setIsEdit("");
      // UIUpdate();
      // localStorage.removeItem("archivedDesigns");
      // localStorage.removeItem("removedDesignsBySet");
    };
  }, [isEdit]);

  //useEffect for remove the updateProductsData
  useEffect(() => {
    if (!location?.state?.cataloguUpdate) {
      sessionStorage.removeItem("updateProductsData");
    }
    return () => {
      setSortOptions([]);
      setSelectedSortValue(null);
    };
  }, []);

  useEffect(() => {
    console.warn("EDIT SET", isEdit);
    if (isEdit === "view") {
      // UIUpdate();
      // localStorage.removeItem("archivedDesigns");
      // localStorage.removeItem("removedDesignsBySet");
    }
  }, [isEdit]);

  React.useEffect(() => {
    const fetchFilterData = async () => {
      try {
        const response = await apiClient.get(`/api/v1/set/getSetDesignSlidersData`);
        if (response.data && response.data.data) {
          const { diamondPieces, diamondWeight, grossWeight, netWeight, designQuantity, collectionName } = response.data.data;
          let filterData = {
            // Labour: ["R - Regular", "H - High", "X - Exclusive"],
            // Class: ["Gold", "Silver", "Bronze"],
            // "Design In Collection": ["All"],
            // "Design In Sets": ["All"],
            "Diamond Pieces": diamondPieces || [],
            "Diamond Weight": diamondWeight || [],
            "Gold Gross-Weight": grossWeight || [],
            "Gold Net-Weight": netWeight || [],
            // "Highest Seller": [],
            // "Best Seller Design": ["All"],
            Pieces: designQuantity || [],
            Collections: collectionName || [],
            collectionName: [],
            piecesCount: [],
          };
          // Update the state with the fetched data
          setBackendFilterValues((prev) => filterData);
          setFilterValues((prev) => filterData);
        }
      } catch (error) {
        console.error("Error fetching filter data:", error);
      }
    };

    if (isEdit === "view" || innerChnages) {
      fetchFilterData();
    }
  }, [isEdit, innerChnages]);

  return (
    <>
      <RenderSets innerChnages={innerChnages} location={location} />
    </>
  );
};

export default Sets;
