import React, { useCallback, useEffect, useState } from "react";
import RoleData from "../dummydata/roledata";
import { Grid } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import Card from "components/ui/Card";
import { useSettingContext } from "context/SettingContext";
import { ReactComponent as Background } from "assets/icons/bg.svg";
import CreateRoleModal from "features/Setting/Role/Component/CreateRoleModal";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import { ReactComponent as EditIcon } from "assets/icons/edit_icon.svg";
import RemoveModal from "components/ui/RemoveModal/RemoveModal";
import { checkUserWithRole, createRoles, getAllRoles, removeRoles } from "../Apis/get-role";
import LoadingSmallCard from "components/ui/Skeleton/LoadingSmallCard";
import { ToastContainer, toast } from "react-toastify";
import { updateObjectAtPosition } from "../hooks/rolehooks";
import { usePermissions } from "context/PermissionsContext";
import { findSpecificPage, findSpecificSubPage, hasAccessSubPage } from "utils/checkUserRigths";

const RolePage = () => {
  const {
    setCreateBtn,
    setCreateBtnName,
    createRole,
    setRoleEditBtn,
    openEditPage,
    setOpenEditPage,
    setIsDltClick,
    undoRole,
    setUndoRole,
    setIsChange,
    setCreateRole,
    isUpdate,
    setIsUpdate,
    setIsNameChanged,
  } = useSettingContext();
  const [roleData, setRoleData] = useState([]);
  const [roleToDelete, setRoleToDelete] = useState(null);
  const [roleToUpdate, setRoleToUpdate] = useState(null);
  const [deletedRole, setDeletedRole] = useState([]);
  const [open, setOpen] = useState(false);
  const [removeModalOpen, setRemoveModalOpen] = useState(false);
  const [discardModalOpen, setDiscardModalOpen] = useState(false);
  const location = useLocation();
  const [roleName, setRoleName] = useState("");
  let [loading, setLoading] = useState(true);

  let preState = location.state;
  const permission = usePermissions();
  const hasAccessAddRole = hasAccessSubPage(findSpecificSubPage(findSpecificPage("Settings", permission), "Roles"), "add");
  const hasAccessEditRole = hasAccessSubPage(findSpecificSubPage(findSpecificPage("Settings", permission), "Roles"), "edit");
  const hasAccessDeleteRole = hasAccessSubPage(findSpecificSubPage(findSpecificPage("Settings", permission), "Roles"), "archive");

  const handleClick = (name, roleId) => {
    const state = { ...preState, name: [`${preState.name[0]}`, `${name}`] };
  };

  const fetchRoles = useCallback(async () => {
    try {
      const { data } = await getAllRoles();
      setRoleData(data);
      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch roles:", error);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    // setCreateBtn(true);
    setCreateBtn(hasAccessAddRole);

    setCreateBtnName("Create Role");

    return () => {
      setCreateBtn(false);
      setCreateBtnName("");
      setRoleEditBtn(false);
      setOpenEditPage(false);
      setCreateRole(false);
      setIsUpdate(false);
      setDiscardModalOpen(false);
    };
  }, [hasAccessAddRole]);

  useEffect(() => {
    fetchRoles();
  }, [openEditPage]);

  const style = {
    justifyContent: openEditPage ? "space-between" : "center",
    fontFamily: "Karla",
    fontWeight: "400",
    fontSize: "20px",
    color: "white",
    wordBreak: "break-word",
  };

  useEffect(() => {
    setOpen(createRole);
  }, [createRole]);

  useEffect(() => {
    if (roleData.length > 0) {
      setRoleEditBtn(hasAccessEditRole);
    }
    return () => {
      setRoleEditBtn(false);
    };
  }, [roleData]);

  useEffect(() => {
    setDiscardModalOpen(undoRole);
  }, [undoRole]);

  const handleCreateRole = async (RoleName) => {
    let data = await createRoles(RoleName);
    if (data.status === 200) {
      fetchRoles();
    } else {
      toast.error(`${data?.message}`, {
        autoClose: 1000, // Disappears after 1 seconds
        hideProgressBar: false, // Ensure progress bar is shown
      });
    }
  };

  const handleOpenRemoveModal = (roleId) => {
    setRoleToDelete(roleId);
    setRemoveModalOpen(true);
    setIsChange(true);
  };

  const handleOpenEditModal = (roleId, currRoleName) => {
    setRoleName(currRoleName);
    setRoleToUpdate(roleId);
    setIsUpdate(true);
    setOpen(true);
  };

  const handleDiscardRole = () => {
    HandleUndoDeleteRole();
  };

  const HandleUndoDeleteRole = () => {
    const roleRestore = JSON.parse(localStorage.getItem("deletedRoles")) || [];
    const updatedRoles = JSON.parse(localStorage.getItem("updatedRoles")) || [];

    if (roleRestore) {
      setRoleData((prevData) => [...prevData, ...roleRestore]);
      localStorage.removeItem("deletedRoles");
      setDeletedRole([]);
    }

    // Restore updated roles if available
    if (updatedRoles.length > 0) {
      setRoleData((prevData) => {
        // Update the prevData with updatedRoles
        const updatedData = prevData.map((role) => {
          const updatedRole = updatedRoles.find((updRole) => updRole.roleToBeUpdated._id === role._id);
          return updatedRole ? { ...role, ...updatedRole.updatedRoleName } : role;
        });
        return updatedData;
      });
      localStorage.removeItem("updatedRoles");
    }

    // setUndoRole(false);
    setDiscardModalOpen(false);
    setOpenEditPage(false);
    setIsDltClick(false);
    setUndoRole(false);
    setIsNameChanged(false);
  };

  const handleTempUpdatedRole = async (roleName) => {
    const updatedRoles = JSON.parse(localStorage.getItem("updatedRoles")) || [];
    const roleToBeUpdated = roleData.find((role) => role._id === roleToUpdate);
    if (!roleToBeUpdated) return;
    let indexOfDataToBeReplaced = roleData.findIndex((role) => role._id == roleToUpdate);
    if (indexOfDataToBeReplaced === -1) return;
    let updationRoleData = { updatedRoleName: roleName, roleToBeUpdated: roleData[indexOfDataToBeReplaced] };
    updatedRoles.push(updationRoleData);
    localStorage.setItem("updatedRoles", JSON.stringify(updatedRoles));

    if (updatedRoles.length > 0) {
      setIsNameChanged(true);
    } else {
      setIsNameChanged(false);
    }

    // Update the role name in roleData array using updateObjectAtPosition
    updateObjectAtPosition(roleData, indexOfDataToBeReplaced, "roleName", roleName, setRoleData);
    setIsChange(true);
  };

  const handleTempDeleteRole = async () => {
    try {
      // Store the deleted roles in localStorage
      const deletedRoles = JSON.parse(localStorage.getItem("deletedRoles")) || [];
      let updatedRoles = JSON.parse(localStorage.getItem("updatedRoles")) || [];

      const roleToBeDeleted = roleData.find((role) => role._id === roleToDelete);

      if (!roleToBeDeleted) return;

      // Check if the role is already in updatedRoles
      updatedRoles = updatedRoles.filter((updatedRole) => updatedRole.roleToBeUpdated._id !== roleToBeDeleted._id);

      // Update localStorage for updatedRoles after removing the role
      localStorage.setItem("updatedRoles", JSON.stringify(updatedRoles));

      // Check if any user is assigned with this role
      let checkUserWithRoleExist = await checkUserWithRole(roleToBeDeleted._id);

      if (checkUserWithRoleExist && checkUserWithRoleExist.status) {
        toast.error(checkUserWithRoleExist.message, {
          autoClose: 3000,
          hideProgressBar: false,
        });
        setRemoveModalOpen(false);

        return;
      }

      // If no users assigned, proceed with deletion
      deletedRoles.push(roleToBeDeleted);
      localStorage.setItem("deletedRoles", JSON.stringify(deletedRoles));

      // Update state
      setRoleData((prevData) => prevData.filter((role) => role._id !== roleToDelete));
      setIsDltClick(true);
      setRemoveModalOpen(false);
      setIsChange(true);
    } catch (error) {
      toast.error("Something went wrong. Please try again.", {
        autoClose: 3000,
        hideProgressBar: false,
      });
    }
  };

  const handleRoleSave = async () => {
    const handleRoles = async (roles, isDelete, storageKey) => {
      if (roles.length === 0) return;

      const roleIds = roles.map(({ _id, roleName, roleToBeUpdated, updatedRoleName }) => ({
        roleId: _id || roleToBeUpdated._id,
        name: roleName || updatedRoleName,
      }));

      const response = await removeRoles(roleIds, isDelete);

      if (response.status === 200) {
        setOpenEditPage(false);
        setRoleEditBtn(hasAccessEditRole);
        setIsChange(false);
        setDiscardModalOpen(false);
        setUndoRole(false);
        setIsNameChanged(false);
        localStorage.removeItem(storageKey);
        // toast.success(response.message, {
        //   autoClose: 1000,
        //   hideProgressBar: false,
        // });
      } else {
        alert("Error saving roles");
      }
    };

    const updatedRoles = JSON.parse(localStorage.getItem("updatedRoles")) || [];
    const deletedRoles = JSON.parse(localStorage.getItem("deletedRoles")) || [];
    await handleRoles(updatedRoles, false, "updatedRoles");
    await handleRoles(deletedRoles, true, "deletedRoles");
  };

  const btnInfo1 = [
    {
      btnName: "Remove",
      bgColor: "#E77B7B",
      click: () => {
        handleTempDeleteRole();
      },
    },
    {
      btnName: "Keep",
      bgColor: "#50c878",
      click: () => {
        setRemoveModalOpen(false);
        setUndoRole(false);
      },
    },
  ];

  const btnInfo2 = [
    {
      btnName: "Discard",
      bgColor: "#E77B7B",
      click: () => {
        handleDiscardRole();
      },
    },
    {
      btnName: "Keep",
      bgColor: "#50c878",
      click: () => {
        handleRoleSave();
        //may be need to call fetchRoles
      },
    },
  ];

  if (loading) return <LoadingSmallCard count={20} />;

  return (
    <>
      {roleData.length > 0 ? (
        <Grid
          container
          className="Grid-padding"
          sx={{ paddingLeft: "18px", paddingRight: "18px" }}
          rowSpacing={{ xs: 1, sm: 2, md: 2, lg: 2, xl: 3 }}
          columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 2, xl: 3 }}
          justifyContent="space-beetween"
          alignItems="center"
        >
          {roleData.map((data, i) => {
            return (
              <Grid item xs={12} sm={12} md={4} lg={3} xl={2.3} key={i}>
                <Card style={style}>
                  <div onClick={() => handleClick(data.roleName, data.Id)}>{data.roleName}</div>
                  <div style={{ display: "flex", justifyContent: "center", gap: "30px" }}>
                    {openEditPage && <EditIcon onClick={() => handleOpenEditModal(data._id, data.roleName)} />}
                    {openEditPage && hasAccessDeleteRole && <TrashIcon onClick={() => handleOpenRemoveModal(data._id)} />}
                  </div>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      ) : (
        <div style={{ disply: "flex", textAlign: "center", padding: "40px", justifyContent: "center", alignItems: "center" }}>
          <Background />
        </div>
      )}

      {removeModalOpen && <RemoveModal open={removeModalOpen} setOpen={setRemoveModalOpen} btnInfo={btnInfo1} heading=" Do you want to Remove this Role?" />}

      {discardModalOpen && <RemoveModal open={discardModalOpen} setOpen={setDiscardModalOpen} btnInfo={btnInfo2} heading=" Do you want to Discard the Changes?" />}

      <CreateRoleModal
        open={open}
        setOpen={setOpen}
        handleCreateRole={handleCreateRole}
        roleName={roleName}
        isUpdate={isUpdate}
        setIsUpdate={setIsUpdate}
        setRoleName={setRoleName}
        handleTempUpdatedRole={handleTempUpdatedRole}
      />
      <ToastContainer />
    </>
  );
};

export default RolePage;
