import React, { useEffect, useMemo, useState } from "react";
import Modal from "../Modal";
import { Box, FormControl, FormControlLabel, FormLabel, Grid, Radio, Typography, styled, ThemeProvider, InputBase, Input } from "@mui/material";
import Button from "../Button";
import "./editCatagory.css";
import renderIconForEditCatagory from "./renderIconForEditCatagory";
import customTheme from "thems/editCatagoryModalThems";
import apiClient from "lib/api-client";
import { CheckDuplicateSubCategory, fetchSubCatagoryList, formattedSubCategory, formattedSubCategoryPath, handleSuccessNavigate, radioOptions, sendDataForEditCatagory } from "utils/editCatagoryHelper";
import { useEditCatagory } from "hooks/useEditCatagory";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const CustomButton = styled(Button)(({}) => ({
  width: "100%",
  fontFamily: "Karla",
  fontWeight: 500,
  fontSize: "18px",
  lineHeight: "22px",
  height: "50px",
  borderRadius: "5px",
  color: "#000000",
  border: `1px solid #ffffff `,
  textTransform: "none",
  backgroundColor: "#50C878",
  boxShadow: "none",
  "&:hover": {
    backgroundColor: "#50C878",
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
  },
  "&.Mui-disabled": {
    backgroundColor: "#444444",
    color: "#000000",
    border: "1px solid #444444",
  },
}));

const CircularRadio = styled(Radio)(({ theme }) => ({
  color: "#000", // default color for radio button
  padding: "0px",
  "&.Mui-checked": {
    // color: "#4CAF50", // Green color when checked
    color: "#000000",
  },
}));

const EditCatagoryModal = ({ open, handleClose, heading, updateCatagory }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedValue, setSelectedValue] = useState("");
  const [newSubCatagory, setNewSubCatagory] = useState("");
  const [updatedRadioOptions, setUpdatedRadioOptions] = useState(radioOptions);
  const [radioData, setRadioData] = useState([]);
  const { mutate: updateCatagoryFun } = useEditCatagory({ onCompleted: () => handleSuccessNavigate(location, handleClose, navigate) });

  const handleRadioChange = (event) => {
    let newValue = event.target.value;
    if (newValue) {
      setSelectedValue(newValue);
      setNewSubCatagory("");
    }
  };

  const handleInputChange = (event) => {
    let newName = event.target.value;
    setNewSubCatagory(newName);
    if (newName) {
      setSelectedValue("");
    }
  };

  const handleSaveChanges = () => {
    if (newSubCatagory && updateCatagory.trim() === "UpdateSubCategory") {
      let isExitSubCategory = CheckDuplicateSubCategory(newSubCatagory, radioData);

      if (isExitSubCategory) {
        toast.error(
          `Error: This sub-category already exists. Please select it from the given list.`,
          {
            position: "top-right",
            autoClose: 2000,
          }
        );
        return;
      }
      const path = location?.pathname.split("/");
      if (formattedSubCategory(path[3].trim()) === formattedSubCategory(newSubCatagory)) {
        toast.error(`Error: Same sub-category entered. Please enter a different sub-category.`, {
          position: "top-right",
          autoClose: 2000,
        });
        return;
      }
    }
    if (selectedValue || newSubCatagory) {
      const selectedData = selectedValue || newSubCatagory;
      const updateStyleNumber = sendDataForEditCatagory(updateCatagory, selectedData);
      updateCatagoryFun(updateStyleNumber);
    }
  };

  useEffect(() => {
    const getSubCategories = async () => {
      if (heading && heading === "Update Sub-category") {
        let subCategories = await fetchSubCatagoryList(location);
        subCategories = subCategories.map((item) => ({ option: item, value: item }));
        setUpdatedRadioOptions((prevOptions) => ({
          ...prevOptions,
          [heading]: subCategories,
        }));
      }
    };

    getSubCategories();
  }, [heading]);

  const filteredRadioData = useMemo(() => {
    return updatedRadioOptions[heading] || [];
  }, [heading, updatedRadioOptions]);

  useEffect(() => {
    setRadioData(filteredRadioData);
  }, [filteredRadioData]);

  return (
    <ThemeProvider theme={customTheme}>
      <Modal open={open} handleClose={handleClose} style={{ width: "345px" }}>
        <Grid container spacing={2}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="main-grid">
            <Typography className="edit-catagory-heading">{heading}</Typography>
          </Grid>
          {heading === "Update Sub-category" && (
            <Grid className="sub-grid" item xl={12} lg={12} md={12} sm={12} xs={12} sx={{ marginTop: "10px", marginBottom: "5px" }}>
              <Typography className="edit-catagory-sub-heading">Existing Sub-Categories</Typography>
            </Grid>
          )}
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <div className="scrollable-grid" style={{ display: "flex", flexDirection: "column", maxHeight: "200px", overflowY: "auto", marginBottom: "10px" }}>
              <FormControl>
                {radioData &&
                  radioData.length > 0 &&
                  radioData?.map((item) => (
                    <FormControlLabel
                      key={item.option}
                      name={item.option}
                      value={item.value}
                      control={<CircularRadio checked={selectedValue === item.value} onChange={handleRadioChange} />}
                      label={
                        <Box display="flex" alignItems="center" width="100%">
                          {renderIconForEditCatagory(item.option)}
                          <Typography className="edit-catagory-modal-items-margin-left edit-catagory-modal-items">{item.option}</Typography>
                        </Box>
                      }
                      aria-labelledby={`radio-${item.value}`}
                    />
                  ))}
              </FormControl>
            </div>
          </Grid>

          {heading === "Update Sub-category" && (
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} sx={{ marginBottom: "12px" }}>
              <Box sx={{ display: "flex", justifyContent: "flex-start", flexDirection: "column" }}>
                <input
                  className="edit-catagory-input-text"
                  type="text"
                  id="new-sub"
                  autoComplete="off"
                  placeholder="New Sub-Category Name"
                  value={newSubCatagory}
                  onChange={handleInputChange}
                  aria-label="New Sub-Category Name"
                />
              </Box>
            </Grid>
          )}

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12} display="flex" width={"100%"} justifyContent="center">
            <CustomButton disableRipple disableFocusRipple variant="contained" disabled={!selectedValue && !newSubCatagory} onClick={handleSaveChanges}>
              Save Changes
            </CustomButton>
          </Grid>
        </Grid>
      </Modal>
    </ThemeProvider>
  );
};

export default EditCatagoryModal;
