import { useMutation, useQueryClient } from "@tanstack/react-query";

import { useGlobalContext } from "context/GlobalContext";
import { useNavigate } from "react-router-dom";
import { toast as myToast } from "react-toastify";
import catalogAPIs from "../api/catalogAPIs";

export const useEditCatalog = () => {
  const { setIsArchive } = useGlobalContext();
  const navigate = useNavigate();
  const quaryClient = useQueryClient();
  return useMutation({
    mutationFn: catalogAPIs.editCatalog,
    onSuccess: (data) => {
      if (data?.data?.status === 200) {
        quaryClient.invalidateQueries("catalogProducts");
        quaryClient.removeQueries("catalogCountData");
        setIsArchive(false);
        navigate("/Catalog");
        myToast.success(data?.data?.message || "Catalog updated successfully!", {
          position: "top-right",
          autoClose: 2000,
        });
      } else {
        myToast.error(`Error: ${data?.data?.message || "An unexpected error occurred"}`, {
          position: "top-right",
          autoClose: 2000,
        });
      }
    },
    onError: (error) => {
      myToast.error(`Error: ${error?.response?.data?.message || error.message || "An unexpected error occurred"}`, {
        position: "top-right",
        autoClose: 2000,
      });
    },
  });
};
