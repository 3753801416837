import React, { useEffect, useState } from "react";
import Product from "./Product";
import { Box, CircularProgress, Grid } from "@mui/material";
import SortContext from "context/SortContext";
import { useFilter } from "context/FilterContext";
import { useGlobalContext } from "context/GlobalContext";
import RemoveProduct from "components/ui/RemoveModal/RemoveModal";
import Home from "pages/Home";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import config from "configs/config";
import { useInView } from "react-intersection-observer";
import { useInfiniteQuery } from "@tanstack/react-query";
import { transformData, transformDataForTrayModal } from "utils/transformdata";
import ProductSkeleton from "components/ui/Skeleton/ProductSkeleton";
import apiClient from "lib/api-client";
import GoToTop from "components/ui/GoToTop";
import { usePermissions } from "context/PermissionsContext";
import { findSpecificPage, findSpecificSubPage, hasAccessSubPage } from "utils/checkUserRigths";
import { decodeStr, encodeStr, formatString } from "utils/encodeDecodeStr";

function SubCollectionProduct() {
  const queryClient = useQueryClient(); // Initialize query client
  const { setSortOptions, setSelectedSortValue, search, selectedSortValue, sortOptions, setIsEdit } = React.useContext(SortContext);
  const permission = usePermissions();
  const { setRemoveIcon, setTrashModalOpen, trashModalOpen } = useGlobalContext();
  const { collectionName, subcategoryId } = useParams();

  const [checkedItems, setcheckedItems] = useState({});

  const [collectionId, setCollectionId] = useState("");
  const [collectionProductData, setCollectionProductData] = useState([]);
  const { ref, inView } = useInView();
  const navigate = useNavigate();
  const location = useLocation(); // Capture the current location
  const [previousPath, setPreviousPath] = useState(null); // Store previous path

  const selectedOption = sortOptions.find((option) => option.id == selectedSortValue);
  const selectedSortBY = selectedOption?.sortBy;
  const selectedSortOrder = selectedOption?.order;
  const { filterValues, setFilter, setFilterValues, setBackendFilterValues } = useFilter();
  const labourValues =filterValues && filterValues?.Labour?.map((item) => item.split(" - ")[0]).filter(Boolean);
  const classType =filterValues && filterValues?.Class;

  // Numeric range filters
  const diamondPieces =filterValues && filterValues["Diamond Pieces"]; // Access numeric ranges directly
  const diamondWeight =filterValues && filterValues["Diamond Weight"];
  const goldGrossWeight = filterValues && filterValues["Gold Gross-Weight"];
  const goldNetWeight = filterValues && filterValues["Gold Net-Weight"];
  // Boolean filters or "Yes/No" type filters
  const designInCollection = filterValues && filterValues["Design In Collection"];
  const designInSets = filterValues && filterValues["Design In Sets"];
  const bestSeller = filterValues && filterValues["Best Seller Design"];
  const highestSeller = filterValues && filterValues["Highest Seller"];
  const hasAccessToSeeSales = hasAccessSubPage(findSpecificSubPage(findSpecificPage("Settings", permission), "Sales"), "view");

  let preState = location.state;

  // Set the previous path on component mount
  useEffect(() => {
    if (location.pathname && location.pathname !== "/") {
      const segments = location.pathname.split("/");
      const parentPath = segments.slice(0, -1).join("/");
      setPreviousPath(parentPath || "/");
    }
    //for taking user to previous scrolled position
    if (location.state?.scrollPosition !== undefined) {
      window.scrollTo(0, location.state.scrollPosition);
    }
  }, [location]);

  useEffect(() => {
    return () => {
      // Clear query data on unmount
      queryClient.removeQueries("collectiondesigns", { exact: true });
    };
  }, [queryClient]);

  useEffect(() => {
    setFilter(true);

    //---------------- Setting Sort Options --------------------\\
    setSortOptions([
      { id: 1, name: "Design No. (Low to High)", sortBy: "Design No", order: "asc" },
      { id: 2, name: "Design No. (High to Low)", sortBy: "Design No", order: "desc" },
      { id: 3, name: "Design Date (Oldest to Newest)", sortBy: "Design Date", order: "asc" },
      { id: 4, name: "Design Date (Newest to Oldest)", sortBy: "Design Date", order: "desc" },
      { id: 5, name: "Gold Net Weight (Low to High)", sortBy: "Gold Net Weight", order: "asc" },
      { id: 6, name: "Gold Net Weight (High to Low)", sortBy: "Gold Net Weight", order: "desc" },
      { id: 7, name: "Diamond Weight (Low to High)", sortBy: "Diamond Weight", order: "asc" },
      { id: 8, name: "Diamond Weight (High to Low)", sortBy: "Diamond Weight", order: "desc" },
      { id: 9, name: "Sales (Low to High)", sortBy: "Sales", order: "asc", access: hasAccessToSeeSales },
      { id: 10, name: "Sales (High to Low)", sortBy: "Sales", order: "desc", access: hasAccessToSeeSales },
    ]);
    //---------------- Filter Slider API --------------------\\

    const fetchFilterData = async () => {
      try {
        const response = await apiClient.post(`${config.baseUrl}/api/v1/collection/getDesignSlidersData`, {
          collectionName: location.state.name[0],
          collectionCatgeoryName: location.state.name[1].split("-")[0].trim(),
        });

        if (response.data && response.data.data) {
          const { diamondPieces, diamondWeight, grossWeight, netWeight } = response.data.data;
          let filterData = {
            Labour: ["R - Regular", "H - High", "X - Exclusive"],
            Class: ["Gold", "Silver", "Bronze"],
            "Design In Collection": ["All"],
            "Design In Sets": ["All"],
            "Diamond Pieces": diamondPieces || [],
            "Diamond Weight": diamondWeight || [],
            "Gold Gross-Weight": grossWeight || [],
            "Gold Net-Weight": netWeight || [],
            "Highest Seller": [],

            "Best Seller Design": ["All"],
          };
          // Update the state with the fetched data
          setBackendFilterValues(filterData);
          setFilterValues(filterData);
        }
      } catch (error) {
        console.error("Error fetching filter data:", error);
      }
    };

    fetchFilterData();

    return () => {
      setFilter(false);
      setSortOptions([]);
      setSelectedSortValue(null);
      setFilterValues({
        Labour: [],
        Class: [],
        "Diamond Pieces": [],
        "Diamond Weight": [],
        "Gold Gross-Weight": [],
        "Gold Net-Weight": [],
        "Design In Collection": [],
        "Design In Sets": [],
        "Highest Seller": [],
        "Best Seller Design": [],
      });
      setCollectionProductData([]);
    };
  }, []);

  //----------------- API Integration  ----------------\\

  //---------------- Filter Slider API --------------------\\
  // useEffect(() => {
  //   const fetchFilterData = async () => {
  //     try {
  //       const response = await apiClient.post(`${config.baseUrl}/api/v1/collection/getDesignSlidersData`, {
  //         collectionName: location.state.name[0],
  //         collectionCatgeoryName: location.state.name[1].split("-")[0].trim(),
  //       });
  //       if (response.data && response.data.data) {
  //         const { diamondPieces, diamondWeight, grossWeight, netWeight } = response.data.data;
  //         let filterData = {
  //           Labour: ["R - Regular", "H - High", "X - Exclusive"],
  //           Class: ["Gold", "Silver", "Bronze"],
  //           "Design In Collection": ["All"],
  //           "Design In Sets": ["All"],
  //           "Diamond Pieces": diamondPieces || [],
  //           "Diamond Weight": diamondWeight || [],
  //           "Gold Gross-Weight": grossWeight || [],
  //           "Gold Net-Weight": netWeight || [],
  //           "Highest Seller": [],

  //           "Best Seller Design": ["All"],
  //         };
  //         // Update the state with the fetched data
  //         setBackendFilterValues(filterData);
  //         setFilterValues(filterData);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching filter data:", error);
  //     }
  //   };

  //   fetchFilterData();
  // }, []);

  //---------------- Design Get API --------------------\\

  const fetchCollectionsDesign = async ({ pageParam = 1, queryKey }) => {
    const [
      ,
      {
        limit,
        sortBy,
        order,
        searchTerm,
        labourFilter,
        classFilter,
        diamondPieces,
        diamondWeight,
        goldGrossWeight,
        goldNetWeight,
        designInCollection,
        designInSets,
        categoryName,
        collectionName,
        bestSeller,
        highestSeller,
      },
    ] = queryKey;

    const payload1 = {
      collectionName,
      categoryName,
      pageNumber: pageParam,
      limit,
      searchTerm,
      sortBy,
      order,
      labourFilter,
      classFilter,
      diamondPieces,
      diamondWeight,
      goldGrossWeight,
      goldNetWeight,
      designInCollection,
      designInSets,
      bestSeller,
      highestSeller,
    };

    const response = await apiClient.post(`${config.baseUrl}/api/v1/collection/getDesignsInCollection`, payload1);
    setCollectionId(response.data.collectionId);

    return { data: response.data.designs, nextPage: response.data.pagination.pageNumber + 1, totalPages: response.data.pagination.totalPages, totalCount: response.data.pagination.totalDesigns };
  };
  let collectionNameIs = collectionName;
  let categoryNameIs = subcategoryId.split("-")[0];

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading, isError, error, refetch } = useInfiniteQuery({
    queryKey: ["collectiondesigns"],
    queryFn: ({ pageParam = 1 }) =>
      fetchCollectionsDesign({
        pageParam,
        queryKey: [
          "collectiondesigns",
          {
            limit: 30,
            sortBy: selectedSortBY,
            order: selectedSortOrder,
            searchTerm: search,
            labourFilter: labourValues?.length > 0 ? labourValues : "",
            classFilter: classType?.length > 0 ? classType : "",
            diamondPieces: diamondPieces.length > 0 ? diamondPieces : "",
            diamondWeight: diamondWeight.length > 0 ? diamondWeight : "",
            goldGrossWeight: goldGrossWeight.length > 0 ? goldGrossWeight : "",
            goldNetWeight: goldNetWeight.length > 0 ? goldNetWeight : "",
            designInCollection: designInCollection.length > 0 ? designInCollection : "",
            designInSets: designInSets.length > 0 ? designInSets : "",

            collectionName: collectionNameIs,
            categoryName: categoryNameIs,
            bestSeller: bestSeller[0] === "No" ? "NO" : bestSeller[0] === "Yes" ? "YES" : "",
            highestSeller: highestSeller?.length > 0 ? highestSeller : "",
          },
        ],
      }),
    getNextPageParam: (lastPage) => {
      if (lastPage.nextPage <= lastPage.totalPages) {
        return lastPage.nextPage;
      }
      return undefined;
    },
    staleTime: 5 * 60 * 1000,
    retry: 1,
    enabled: !!collectionNameIs && !!categoryNameIs,
  });

  useEffect(() => {
    if (data) {
      const allProducts = data.pages.flatMap((page) => page.data);
      setCollectionProductData(allProducts);
    }
  }, [data]);

  //----------- When user Copy Paste URL -------------------\\
  useEffect(() => {
    const decodedCollectionName = decodeURIComponent(collectionName);
    let collectionCatgeoryName = decodeURIComponent(subcategoryId).split("-")[0];
    let count = decodeURIComponent(subcategoryId).split("-")[1];

    if (!location.state?.name) {
      navigate(location.pathname, { state: { name: [decodedCollectionName, `${collectionCatgeoryName} - ${count}`] }, replace: true });
    }
  }, []);

  useEffect(() => {
    if (selectedSortValue || filterValues) {
      // queryClient.removeQueries("collectiondesigns", { exact: true });
      refetch();
    }
  }, [selectedSortValue, filterValues]);

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, fetchNextPage]);

  //------------------------------------------------------\\

  const handleCheckboxChange = (itemId) => (event) => {
    setcheckedItems((prevCheckedItems) => {
      const newCheckedItems = { ...prevCheckedItems };

      if (event.target.checked) {
        newCheckedItems[itemId] = true;
      } else {
        delete newCheckedItems[itemId];
      }

      return newCheckedItems;
    });
  };

  const btnInfo = [
    {
      btnName: "Remove",
      bgColor: "#E77B7B",
      click: async () => {
        const findRemoveProductIds = Object.keys(checkedItems);
        const currentScrollPosition = window.scrollY;
        const payload = {
          collectionId: collectionId,
          styleRefIds: findRemoveProductIds,
        };

        const removeDesignResponse = await apiClient.post(`${config.baseUrl}/api/v1/collection/removeDesign`, payload);

        if (removeDesignResponse.status === 200) {
          // queryClient.removeQueries("collectiondesigns", { exact: true });

          let currentPage = location.state.name[location.state.name.length - 1];

          if (removeDesignResponse.data.updatedCollection.numberOfStyles === 0) {
            navigate("/Collection");
          } else {
            let getCategories = removeDesignResponse.data.updatedCollection.categories;
            let totalCount = getCategories.reduce((acc, cat) => {
              return acc + cat.count;
            }, 0);
            let currentPageName = currentPage.split("-");
            currentPageName = currentPageName[0].split(" ");
            let findCurrentPageDesignCount;
            if (currentPageName[0] === "All") {
              findCurrentPageDesignCount = {
                categoryName: "All",
                count: totalCount,
              };
            } else {
              findCurrentPageDesignCount = getCategories.find((cat) => {
                if (cat.categoryName === currentPageName[0]) {
                  return cat.count;
                }
              });
            }

            if (!(findCurrentPageDesignCount?.count > 0)) {
              const currentPreState = { ...preState, name: [...preState.name] };

              navigate(previousPath || "/Collection", { state: currentPreState });
            } else {
              const updatedState = {
                ...location.state,
                name: [`${location.state.name[0]}`, `${currentPage.split(" - ")[0]} - ${findCurrentPageDesignCount?.count || 0}`],
                scrollPosition: currentScrollPosition,
              };

              // Navigate to the same path with the updated state for showing updated count
              navigate(location.pathname, { state: updatedState });
            }
          }

          setTrashModalOpen(false);
          setcheckedItems({});
          await refetch();
        } else {
          alert(removeDesignResponse.message);
        }
      },
    },
    {
      btnName: "Keep",
      bgColor: "#50C878",
      click: () => {
        setTrashModalOpen(false);
        setcheckedItems({});
      },
    },
  ];

  useEffect(() => {
    if (Object.keys(checkedItems).length > 0) {
      setRemoveIcon(true);
    } else {
      setRemoveIcon(false);
    }
    return () => setRemoveIcon(false);
  }, [checkedItems]);

  if (isError) return <div>Error: {error.message}</div>;

  return (
    <>
      {isLoading ? (
        <ProductSkeleton count={10} />
      ) : collectionProductData && collectionProductData.length > 0 ? (
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          sx={{ flex: "1 1 1", display: "flex", alignItems: "center", justifyContent: "flex-start", gap: "30px", rowGap: "32px" }}
          className="Grid-padding"
          rowGap={"16px"}
        >
          {collectionProductData?.map((product, ind) => {
            return <Product product={transformDataForTrayModal(product)} index={ind} key={product._id} handleCheckboxChange={handleCheckboxChange} checkedItems={checkedItems} />;
          })}
          <RemoveProduct open={trashModalOpen} setOpen={setTrashModalOpen} btnInfo={btnInfo} heading="Do you want to remove the Design?" />
        </Grid>
      ) : (
        <Home />
      )}
      <GoToTop />
      {/* Loader when fetching next page */}
      {isFetchingNextPage && <ProductSkeleton count={10} />}

      {/* The element to observe for triggering the next page fetch */}
      <div ref={ref} style={{ height: "1px" }} />
    </>
  );
}

export default SubCollectionProduct;
