import { CssBaseline, ThemeProvider } from "@mui/material";
import SubCollection from "features/collection/components/SubCollectionScreen/SubCollection";
import ChildHeaderLayout from "layouts/childHeaderLayout";
import MainLayout from "layouts/mainLayout";
import Catalogue from "pages/Catalogue";
import Categories from "pages/Categories";
import Collection from "pages/Collection";
import Settings from "pages/Setting";

import Home from "pages/Home";
import Login from "pages/Login";
import SubCategories from "pages/SubCategories";
import SubCategoriesProd from "pages/SubCategoriesProd";
import { createBrowserRouter } from "react-router-dom";
import customTheme from "thems/loginThems";
import { ReactComponent as CollectionIcon } from "assets/sidebar/enable/collection.svg";

import { ReactComponent as CatalogueIcon } from "assets/sidebar/enable/catalogue.svg";
import { ReactComponent as CategoryIcon } from "assets/sidebar/enable/category.svg";
import { ReactComponent as SetIcon } from "assets/sidebar/enable/sets.svg";
import { ReactComponent as SettingIcon } from "assets/sidebar/enable/setting.svg";

import SubCollectionProduct from "features/collection/components/CollectionProductScreen/SubCollectionProduct";
import Cart from "pages/Cart";
import Sets from "pages/Sets";
import { ReactComponent as Category2 } from "assets/sidebar/disable/category.svg";
import { ReactComponent as Collection2 } from "assets/sidebar/disable/collection.svg";
import { ReactComponent as Catlogue3 } from "assets/icons/disable/Catalog.svg";
import { ReactComponent as Sets2 } from "assets/sidebar/disable/sets.svg";
import CatalogSettingLayout from "../layouts/CatalogSetting/CatalogSettingLayout";
import { CatalogSetting } from "pages/CatalogSetting";
import { CatalogClientActivityScreen } from "features/catalogue/components/Settings/Activity/componets/CatalogClientActivityScreen";
import { CatalogSettingProvider } from "context/CatalogSettingContext";
import CatalogClientReportScreen from "features/catalogue/components/Settings/Report/componet/CatalogClientReportScreen";
import { GlobalProvider } from "context/GlobalContext";
import CatalogReportScreen from "features/catalogue/components/Settings/CatalogReport/componet/CatalogReportScreen";

import Archives from "features/Setting/archive/components/Archives";
import RolePage from "features/Setting/Role/Component/RolePage";
import SetArchives from "features/Setting/archive/components/SetArchives";
import CatalogArchiveScreen from "features/Setting/archive/components/CatalogArchiveScreen";
import CatalogProductArchiveScreen from "features/Setting/archive/components/CatalogProductArchiveScreen";
import { SettingProvider } from "context/SettingContext";
// import RoleDetailPage from "features/Setting/Role/Component/RoleDetailPage";
import UsersScreen from "features/Setting/users/componets/UsersScreen";
import { CreateUserFrom } from "features/Setting/users/componets/CreateUserFrom";
import SortContextProvider from "context/sortContextProvider";
import RightPage from "features/Setting/Rights/RightPage";
import CatalogueDynamic from "features/catalogue/components/CatalogueDynamic";
import MainActivity from "features/Setting/Activity/MainActivity";
import LimitsPage from "features/Setting/Limits/LimitsPage";
import ProtectedRoute from "./ProtectedRoute";
import SettingLayoutOne from "layouts/SettingLayout/SettingLayoutOne";
import Orders from "features/Setting/order/components/Orders";
import OrderDetails from "features/Setting/order/components/OrderDetails";
import { PermissionsProvider } from "context/PermissionsContext";
import CatagoryChildLayoutWraaper from "layouts/CatagoryChildLayoutWraaper";
import SetDesings from "features/set/components/SetDesings";
import SetChildLayoutWrapper from "layouts/SetChildLayoutWrapper";
import CollectionChildLayoutWraaper from "layouts/CollectionChildLayoutWraaper";
import CatalogChildLayoutWraaper from "layouts/CatalogChildLayoutWraaper";
import MissingImages from "features/Setting/Missing_Images/components/MissingImages";

import RestrictedDesignCategoryPage from "features/Setting/Restricted_Design_Page/RestrictedDesignCategoryPage";
import RestrictedDesignSubCategoryPage from "features/Setting/Restricted_Design_Page/RestrictedDesignSubCategoryPage";
import RestrictProductPage from "features/Setting/Restricted_Design_Page/RestrictProductPage/RestrictProductPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <ProtectedRoute>
        <PermissionsProvider>
          <GlobalProvider>
            <MainLayout />
          </GlobalProvider>
        </PermissionsProvider>
      </ProtectedRoute>
    ),
    children: [
      // {
      //   path: "",
      //   element: <Home />,
      // },
      {
        path: "Catalog",
        element: <CatalogChildLayoutWraaper pageName="Catalog" icon={<CatalogueIcon />} deIcon={<Catlogue3 />} />,
        children: [
          {
            path: "",
            element: <Catalogue />,
          },
          {
            path: ":id",
            element: <CatalogueDynamic />,
          },
        ],
      },

      {
        path: "Category",
        element: <CatagoryChildLayoutWraaper pageName="Category" icon={<CategoryIcon />} deIcon={<Category2 />} />,
        children: [
          {
            path: "",
            element: <Categories />,
          },
          {
            path: ":category",
            element: <SubCategories />,
          },
          {
            path: ":category/:subcategory",
            element: <SubCategoriesProd />,
          },
        ],
      },

      {
        path: "Collection",
        // element: <ChildHeaderLayout pageName="Collection" icon={<CollectionIcon />} deIcon={<Collection2 />} />,
        element: <CollectionChildLayoutWraaper pageName="Collection" icon={<CollectionIcon />} deIcon={<Collection2 />} />,

        children: [
          {
            path: "",
            element: <Collection />,
          },
          {
            path: ":collectionName",
            element: <SubCollection />,
          },
          {
            path: ":collectionName/:subcategoryId",
            element: <SubCollectionProduct />,
          },
        ],
      },
      {
        path: "Cart",
        element: <ChildHeaderLayout pageName="Cart" />,
        children: [
          {
            path: "",
            element: <Cart />,
          },
        ],
      },

      //set
      {
        path: "Sets",
        // element: <ChildHeaderLayout pageName="Sets" icon={<SetIcon />} deIcon={<Sets2 />} />,
        element: <SetChildLayoutWrapper pageName="Sets" icon={<SetIcon />} deIcon={<Sets2 />} />,
        children: [
          {
            path: "",
            element: <Sets />,
          },
          {
            path: ":id",
            element: <SetDesings />,
          },
        ],
      },
      //end
    ],
  },

  {
    path: "/login",
    element: (
      <ThemeProvider theme={customTheme}>
        <CssBaseline />
        <Login />
      </ThemeProvider>
    ),
  },

  {
    path: "CatalogSetting",
    element: (
      <ProtectedRoute>
        <PermissionsProvider>
          <GlobalProvider>
            <CatalogSettingProvider>
              <SortContextProvider>
                <CatalogSettingLayout />
              </SortContextProvider>
            </CatalogSettingProvider>
          </GlobalProvider>
        </PermissionsProvider>
      </ProtectedRoute>
    ),
    children: [
      {
        path: ":id",
        element: <CatalogSetting />,
      },
      {
        path: "Client-Activity/:id/:catalogClinetId",
        element: <CatalogClientActivityScreen />,
      },
      {
        path: "Client-Report/:id/:catalogClinetId",
        element: <CatalogClientReportScreen />,
      },
      {
        path: "Catalog-Report/:id",
        element: <CatalogReportScreen />,
      },
    ],
  },

  //setting
  {
    path: "Setting",
    element: (
      <ProtectedRoute>
        <PermissionsProvider>
          <GlobalProvider>
            <SortContextProvider>
              <SettingProvider>
                <SettingLayoutOne icon={<SettingIcon />} />,
              </SettingProvider>
            </SortContextProvider>
          </GlobalProvider>
        </PermissionsProvider>
      </ProtectedRoute>
    ),

    children: [
      {
        index: true,
        element: <Settings />,
      },
      {
        path: "main-activity",
        element: <MainActivity />,
      },
      {
        path: "orders",
        // element: <Orders />,
        children: [
          {
            index: true,
            element: <Orders />,
          },
          {
            path: ":orderId",
            element: <OrderDetails />,
          },
        ],
      },
      {
        path: "users",
        // element: <UsersScreen />,
        children: [
          {
            index: true,
            element: <UsersScreen />,
          },
          {
            path: "addUser",
            element: <CreateUserFrom />,
          },
          {
            path: "editUser/:id",
            element: <CreateUserFrom />,
          },
        ],
      },
      {
        path: "roles",
        // element: <RolePage />,
        children: [
          {
            index: true,
            element: <RolePage />,
          },
          // ,
          // {
          //   path: ":roleId",
          //   element: <RoleDetailPage />,
          // },
        ],
      },

      {
        path: "rights",
        element: <RightPage />,
      },
      {
        path: "profile",
        element: <h1 style={{ marginTop: "5rem" }}>Profile</h1>,
      },
      {
        path: "archives",
        children: [
          {
            index: true,
            element: <Archives />,
          },
          {
            path: "Sets",
            element: (
              <SortContextProvider>
                <SetArchives />
              </SortContextProvider>
            ),
          },
          {
            path: "Catalog",
            element: <CatalogArchiveScreen />,
          },
          {
            path: "Catalog/:id",
            element: <CatalogProductArchiveScreen />,
          },
        ],
      },
      {
        path: "limits",
        element: <LimitsPage />,
      },
      {
        path: "missing-images",
        element: <MissingImages />,
      },
      {
        path: "restricted-designs",
        element: "",
        children: [
          {
            index: true,
            element: <RestrictedDesignCategoryPage />,
          },
          {
            path: ":category",
            element: <RestrictedDesignSubCategoryPage />,
          },
          {
            path: ":category/:subcategory",
            element: <RestrictProductPage />,
          },
        ],
      },
    ],
  },

  {
    path: "*",
    element: <h1>NOT FOUND \ 404 \ </h1>,
  },
]);

export default router;
