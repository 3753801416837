import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useNavigation, useParams } from "react-router-dom";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, styled, IconButton, Skeleton } from "@mui/material";
import { ReactComponent as ClientActivity } from "assets/catalogSetting/activity.svg";
import { ReactComponent as ClientActivityReport } from "assets/catalogSetting/report.svg";
import { ReactComponent as ClientActivityEdit } from "assets/catalogSetting/edit.svg";
import { ReactComponent as CatalogReshare } from "assets/catalogSetting/reshare.svg";
import { ReactComponent as Background } from "assets/icons/bg.svg";
import { useCatalogSettingContext } from "context/CatalogSettingContext";
// import CatalogueShareLinkModule from "./CatalogueShareLinkModule";
import UploadExcelModule from "./UploadExcelModal";
import CatalogueAddAndEditClientDetails from "./CatalogueAddAndEditClientDetails";
import { useGetAllCatalogsClients } from "../hooks/useGetAllCatalogsClients";
import { useCreateCatalogClient } from "../hooks/useCreateCatalogClient";
import { useInView } from "react-intersection-observer";
import { toast } from "react-toastify";
import ClinetTableRowSkeleton from "../utilis/ClinetTableRowSkeleton";
import { useUpdateCatalogClient } from "../hooks/useUpdateCatalogClient";
import { covertingDateAndTime } from "../utilis/covertingDateAndTime";
import { usePermissions } from "context/PermissionsContext";
import { findSpecificPage, findSpecificSubPage, hasAccessPage, hasAccessSubPage } from "utils/checkUserRigths";
import { capitalEachLetter } from "utils/capitalEachLetter";
import { useUploadBulkClients } from "../hooks/useUploadBulkClients";

// Styled component for the entire table with padding
const StyledTableContainer = styled(TableContainer)(({ isLoading }) => ({
  backgroundColor: "transparent",
  maxHeight: "100%",
  minHeight: "100%",
  position: "relative",
  marginBottom: "0px",
  paddingBottom: "5px",
  overflowX: isLoading ? "hidden" : "auto", // Enable horizontal scrolling
  overflowY: "auto", // Optional: Hide vertical scrollbar if not needed
  // border: "1px solid red",

  "&::-webkit-scrollbar": {
    height: "0.5px !important",
    background: "#000000 !important",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#000000 !important",
    borderRadius: "8px !important",
  },

  // Scrollbar styles for Firefox
  scrollbarWidth: "thin" /* Thin scrollbar */,
  scrollbarColor: "#444444 #000000" /* Black thumb, white track */,
  scrollBehavior: "smooth",
}));

const StyledTable = styled(Table)(({}) => ({
  "& th, & td": {
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#FFFFFF",
    textAlign: "center",
  },
  "& th": {
    backgroundColor: "#444444",
  },
}));

const StyledTableHeaderCell = styled(TableCell)(({}) => ({
  fontFamily: "Poppins",
  padding: "10px",
  paddingLeft: "15px",
  paddingRight: "15px",
  border: "none",
  position: "sticky",
  top: 0,
  zIndex: 1,
  // Apply border-radius and borders to the first and last header cells
  "&:first-child": {
    borderTopLeftRadius: "5px",
    borderBottomLeftRadius: "5px",
  },
  "&:last-child": {
    borderTopRightRadius: "5px",
    borderBottomRightRadius: "5px",
    paddingRight: "25px",
  },
}));

const StyledTableBodyCell = styled(TableCell)(({ theme }) => ({
  fontFamily: "Karla",
  padding: "10px",
  paddingLeft: "15px",
  paddingRight: "15px",
  border: "none",
  // border: "1px solid red",
  "&:first-child": {
    paddingLeft: "5px",
  },
  "&:last-child": {
    paddingRight: "20px",
  },
}));

const StyledTableRow = styled(TableRow)(({}) => ({
  "& td": {
    paddingTop: "25px",
  },
  "&:first-of-type td": {
    paddingTop: "20px",
    // border: "1px solid red",
  },
  "&:last-of-type td": {
    padding: "0px",
    margin: "0px",
    // border: "1px solid red",
  },
}));

const CustomIconButton = styled(IconButton)(({ theme }) => ({
  padding: "0px",
  "&.Mui-disabled": {
    color: "lightgray",
    cursor: "not-allowed",
    opacity: 0.2,
  },
}));

const CatalogLinkUser = () => {
  const { id } = useParams();
  const navigation = useNavigate();
  let location = useLocation();
  // const [userShareLinkData, setUserShareLinkData] = useState();
  const [userShareLinkData, setUserShareLinkData] = useState(JSON.parse(sessionStorage.getItem("userShareLinkData")) || []);
  const { shareLinkOpen, setShareLinkOpen, uploadDataModal, setUploadDataModal, updateClient, setUpdateClient, isEditClinet, setIsEditClinet } = useCatalogSettingContext();
  const { ref, inView } = useInView({
    threshold: 1.0,
  });

  const permission = usePermissions();
  const hasViewClientReport = hasAccessSubPage(findSpecificSubPage(findSpecificPage("Catalog Setting", permission), "Client Report"), "view");
  const hasViewClientActivity = hasAccessPage(findSpecificPage("Client Activity", permission), "view");
  const hasEditClientMobileNumber = hasAccessSubPage(findSpecificSubPage(findSpecificPage("Catalog Setting", permission), "Add Mobile No."), "edit");

  const { data, isLoading, fetchNextPage, hasNextPage, isError: getError, isFetching, isFetchingNextPage, error } = useGetAllCatalogsClients({ catalogId: id });
  const { mutate: createCatalogClient, isError, isSuccess, error: addError } = useCreateCatalogClient();
  const { mutate: editCatalogClient, isError: editError, isSuccess: editSuccess, error: updateError } = useUpdateCatalogClient();

  //for the bulk upload clients custom hook
  const { mutate: uploadClients } = useUploadBulkClients();

  const handleClientActivityScreen = (curData) => {
    location = {
      ...location,
      state: {
        ...location.state,
        catalogId: id,
        subName: `- ${curData.mobileNumber.slice(3).trim()} (${curData.clientName})`,
      },
    };
    navigation(`/CatalogSetting/Client-Activity/${id}/${curData._id}`, { ...location });
  };

  const handleClientReportScreen = (curData) => {
    location = {
      ...location,
      state: {
        ...location.state,
        catalogId: id,
        subName: `- ${curData.mobileNumber.slice(3).trim()} (${curData.clientName})`,
      },
    };
    navigation(`/CatalogSetting/Client-Report/${id}/${curData._id}`, { ...location });
  };

  const handleNewAccesUserAdd = (newUserData) => {
    let newData = {
      catalogId: id,
      clientNumber: newUserData.mobileNumber,
      clientName: newUserData.clientName,
      sessionDuration: newUserData.hours,
    };
    createCatalogClient(newData);
    // if (!error) {
    //   setShareLinkOpen(false);
    // }
  };

  const handleAccesUserEdit = (editUserData) => {
    let updateClient = {
      catalogClinetId: editUserData?._id,
      clientNumber: editUserData?.mobileNumber,
      clientName: editUserData?.clientName,
    };

    editCatalogClient(updateClient);
    // setShareLinkOpen(false);
    // setUpdateClient({});
  };

  const fetchNextPageData = useCallback(() => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, isFetchingNextPage]);

  useEffect(() => {
    if (inView) {
      fetchNextPageData();
    }
  }, [inView, hasNextPage, isFetchingNextPage, fetchNextPage]);

  useEffect(() => {
    if (isError || editError) {
      setShareLinkOpen(true);
    } else {
      setShareLinkOpen(false);
    }
  }, [isError, editError]);

  const handleBulkUploadAccesUser = async (data) => {
    const { fileData } = data;
    if (!fileData || !id) {
      toast.error(`Something went wrong`, {
        position: "top-right",
        autoClose: 2000,
      });
      return;
    }
    uploadClients({ file: fileData, catalogId: id });
  };

  const isCatalogClinetsPresent = (data) => {
    if (data && data.pages && data.pages.length > 0) {
      return data.pages.some((page) => page.catalogClinets && page.catalogClinets.length > 0);
    }
    return false;
  };

  if (getError) {
    toast.error(`${error}`, {
      position: "top-right",
      autoClose: 2000,
    });
  }

  if (isLoading) {
    return (
      <StyledTableContainer sx={{ maxHeight: "72vh" }} isLoading={isLoading}>
        <StyledTable>
          <TableHead>
            <TableRow>
              <StyledTableHeaderCell style={{ minWidth: 220 }}>Mobile Number</StyledTableHeaderCell>
              <StyledTableHeaderCell style={{ minWidth: 220 }}>Client Name</StyledTableHeaderCell>
              <StyledTableHeaderCell style={{ minWidth: 250 }}>First Log-In</StyledTableHeaderCell>
              <StyledTableHeaderCell style={{ minWidth: 160 }}>No. Of Log-in</StyledTableHeaderCell>
              <StyledTableHeaderCell style={{ minWidth: 200 }}>Duration Remain</StyledTableHeaderCell>
              <StyledTableHeaderCell style={{ minWidth: 160 }}>Re-share</StyledTableHeaderCell>
              <StyledTableHeaderCell style={{ minWidth: 180 }}>Client Report</StyledTableHeaderCell>
              <StyledTableHeaderCell style={{ minWidth: 180 }}>Client Activity</StyledTableHeaderCell>
              <StyledTableHeaderCell style={{ minWidth: 100 }}>Edit</StyledTableHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.from({ length: 7 }, (_, index) => (
              <TableRow>
                {Array.from({ length: 9 }, (_, colIndex) => (
                  <StyledTableHeaderCell>
                    <Skeleton variant="text" margin="auto" width="100%" height="auto" />
                  </StyledTableHeaderCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </StyledTable>
      </StyledTableContainer>
    );
  }

  return (
    <>
      {isCatalogClinetsPresent(data) ? (
        // <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <div>
          <StyledTableContainer sx={{ maxHeight: "72vh" }}>
            <StyledTable>
              <TableHead>
                <TableRow>
                  <StyledTableHeaderCell style={{ minWidth: 220 }}>Mobile Number</StyledTableHeaderCell>
                  <StyledTableHeaderCell style={{ minWidth: 220 }}>Client Name</StyledTableHeaderCell>
                  <StyledTableHeaderCell style={{ minWidth: 250 }}>First Log-In</StyledTableHeaderCell>
                  <StyledTableHeaderCell style={{ minWidth: 160 }}>No. Of Log-in</StyledTableHeaderCell>
                  <StyledTableHeaderCell style={{ minWidth: 200 }}>Duration Remain</StyledTableHeaderCell>
                  <StyledTableHeaderCell style={{ minWidth: 160 }}>Re-share</StyledTableHeaderCell>
                  {hasViewClientReport && <StyledTableHeaderCell style={{ minWidth: 180 }}>Client Report</StyledTableHeaderCell>}
                  {hasViewClientActivity && <StyledTableHeaderCell style={{ minWidth: 180 }}>Client Activity</StyledTableHeaderCell>}
                  {hasEditClientMobileNumber && <StyledTableHeaderCell style={{ minWidth: 100 }}>Edit</StyledTableHeaderCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.pages?.map((page, pageIndex) => (
                  <React.Fragment key={pageIndex}>
                    {page?.catalogClinets?.map((curData) => {
                      return (
                        <StyledTableRow key={curData?._id}>
                          <StyledTableBodyCell>{curData?.mobileNumber}</StyledTableBodyCell>
                          <StyledTableBodyCell>{capitalEachLetter(curData?.clientName)}</StyledTableBodyCell>
                          <StyledTableBodyCell>{!curData?.firstLogIn ? "-" : covertingDateAndTime(curData?.firstLogIn?.loginDate)}</StyledTableBodyCell>
                          <StyledTableBodyCell>{curData?.noOfLogIn === 0 ? "-" : curData?.noOfLogIn}</StyledTableBodyCell>
                          <StyledTableBodyCell>{curData?.sessionInfo?.validDuration}</StyledTableBodyCell>
                          <StyledTableBodyCell>
                            <CustomIconButton disableFocusRipple disableRipple disabled={curData?.sessionInfo?.validDuration === "00:00:00"} onClick={() => alert("Re sahre Catalog Click")}>
                              <CatalogReshare />
                            </CustomIconButton>
                          </StyledTableBodyCell>
                          {hasViewClientReport && (
                            <StyledTableBodyCell>
                              <IconButton disableFocusRipple disableRipple sx={{ padding: "0px" }} onClick={() => handleClientReportScreen(curData)}>
                                <ClientActivityReport />
                              </IconButton>
                            </StyledTableBodyCell>
                          )}
                          {hasViewClientActivity && (
                            <StyledTableBodyCell>
                              <IconButton disableFocusRipple disableRipple sx={{ padding: "0px" }} onClick={() => handleClientActivityScreen(curData)}>
                                <ClientActivity />
                              </IconButton>
                            </StyledTableBodyCell>
                          )}
                          {hasEditClientMobileNumber && (
                            <StyledTableBodyCell>
                              <CustomIconButton
                                disableFocusRipple
                                disableRipple
                                disabled={curData.noOfLogIn > 0}
                                onClick={() => {
                                  setUpdateClient({
                                    ...curData,
                                    mobileNumber: curData?.mobileNumber?.replace("+", "").replace(/ /g, ""),
                                  });
                                  setIsEditClinet(true);
                                  setShareLinkOpen(true);
                                }}
                              >
                                <ClientActivityEdit />
                              </CustomIconButton>
                            </StyledTableBodyCell>
                          )}
                        </StyledTableRow>
                      );
                    })}
                  </React.Fragment>
                ))}
                {isFetchingNextPage ? <ClinetTableRowSkeleton TableRow={TableRow} Skeleton={Skeleton} StyledTableHeaderCell={StyledTableHeaderCell} /> : null}
                <StyledTableRow ref={ref}>
                  <StyledTableBodyCell colSpan={7} />
                </StyledTableRow>
              </TableBody>
            </StyledTable>
          </StyledTableContainer>
        </div>
      ) : (
        // </Paper>
        <div
          style={{
            display: "flex",
            textAlign: "center",
            margin: "auto",
            padding: "auto",
            justifyContent: "center",
            alignItems: "center",
            height: "70vh",
          }}
        >
          <Background />
        </div>
      )}

      {/* {<CatalogueShareLinkModule open={shareLinkOpen} setOpen={setShareLinkOpen} handleRef={handleNewAccesUserAdd} />} */}
      {
        <CatalogueAddAndEditClientDetails
          open={shareLinkOpen}
          setOpen={setShareLinkOpen}
          handleRef={!isEditClinet ? handleNewAccesUserAdd : handleAccesUserEdit}
          isError={isError || editError}
          isSuccess={isSuccess || editSuccess}
        />
      }
      {<UploadExcelModule open={uploadDataModal} setOpen={setUploadDataModal} handleRef={handleBulkUploadAccesUser} />}
    </>
  );
};

export default CatalogLinkUser;
