//--------- Creating Payload ---------------\\

// Helper function to convert loacl storage  data to the required backedn  payload format
export const convertSavedRightsToPayload = (pageInfo) => {
  const savedRights = JSON.parse(localStorage.getItem("savedRights")) || [];

  const convertedRoles = savedRights.map((role) => {
    const rightsData = pageInfo.map((page) => {
      const parentPageRights = role.CheckedRights?.find((item) => item.page === page.parentPage);
      const parentRights = page?.parentRights.reduce((rightsAcc, right) => {
        rightsAcc[right.toLowerCase()] = parentPageRights && parentPageRights.rights[right] !== undefined ? parentPageRights.rights[right] : false;
        return rightsAcc;
      }, {});

      const subRights = page?.childPages?.length
        ? page.childPages.map((childPage) => {
            const childPageRights = role.CheckedRights?.find((item) => item.page === childPage.childPageName);

            const subMenuRights = childPage?.childPageRights.reduce((subRightsAcc, right) => {
              subRightsAcc[right.toLowerCase()] = childPageRights && childPageRights.rights[right] !== undefined ? childPageRights.rights[right] : false;
              return subRightsAcc;
            }, {});

            return {
              subMenu: childPage?.childPageName,
              ...subMenuRights,
            };
          })
        : null;

      const isAllSelected = Object.values(parentRights).every((val) => val === true);

      return {
        menu: page.parentPage,
        ...parentRights,
        isAllSelected,
        subRights,
      };
    });

    return {
      roleName: role.RoleName,
      rights: rightsData,
    };
  });

  return convertedRoles;
};

const capitalizeFirstLetter = (str) => str.charAt(0).toUpperCase() + str.slice(1);

// Helper function to convert  backedn response   data in  local storage format
export const convertBackednResponseToLocalStorageFormat = (backendResponse, pageInfo) => {
  let convertedLocalStorageFormat;
  convertedLocalStorageFormat = {
    RoleName: backendResponse.roleName,
    isAdmin: backendResponse.isAdmin,
    CheckedRights: backendResponse.rights.flatMap((menuItem) => {
      // Find the corresponding parent page in pageInfo
      const matchingParent = pageInfo.find((page) => page.parentPage === menuItem.menu);
      if (!matchingParent) return [];

      // Filter and store only true values for parent rights
      const parentRights = Object.entries(menuItem).reduce((acc, [key, value]) => {
        if (key !== "menu" && key !== "subRights" && key !== "isAllSelected" && value === true) {
          acc[capitalizeFirstLetter(key)] = true;
        }
        return acc;
      }, {});

      // Only include parent rights if it has at least one true right
      const parentRightsEntry = Object.keys(parentRights).length > 0 ? { page: menuItem.menu, rights: parentRights } : null;

      // Filter and store only true values for child (sub-menu) rights
      const subRightsEntries = menuItem?.subRights
        ?.map((subItem) => {
          // Find the corresponding child page in pageInfo
          const matchingChild = matchingParent.childPages?.find((child) => child.childPageName === subItem.subMenu);
          if (!matchingChild) return null;

          const subMenuRights = Object.entries(subItem).reduce((acc, [key, value]) => {
            if (key !== "subMenu" && value === true) {
              acc[capitalizeFirstLetter(key)] = true;
            }
            return acc;
          }, {});

          return Object.keys(subMenuRights).length > 0 ? { page: subItem.subMenu, rights: subMenuRights } : null;
        })
        .filter((subItem) => subItem !== null); // Filter out null entries

      // Return the combined parent and subRights (filtered by true values)
      return [
        ...(parentRightsEntry ? [parentRightsEntry] : []), // Include parent if valid
        ...(subRightsEntries ? subRightsEntries : []), // Include all valid sub-menu rights
      ];
    }),
  };

  return convertedLocalStorageFormat;
};
