import React, { useEffect, useRef, useState } from "react";
import Modal from "../../../../components/ui/Modal";
import { Box, Grid, Input, OutlinedInput } from "@mui/material";
import { useSettingContext } from "context/SettingContext";
import Button from "../../../../components/ui/Button";
import { ToastContainer, toast } from "react-toastify";

import { ReactComponent as EditIcon } from "assets/icons/edit_black.svg";
import { updateCollectionDiscription } from "../CollectionProductScreen/api/getCollectionProducts";

function CollectionDiscriptionModal({ open, setOpen, data, setData }) {
  const [isInputValue, setIsInputValue] = useState(false);
  const [isEdit, setIsEdit] = useState(true);
  const inputRef = useRef();

  const handleClose = () => {
    setOpen(false);
  };

  const handleDiscriptionChange = (event) => {
    const inputValue = event.target.value;
    const trimmedValue = inputValue.trim();

    if (trimmedValue !== "") {
      setIsInputValue(true);
    } else {
      setIsInputValue(false);
    }

    setData((prev) => ({
      ...prev,
      collectionDiscription: inputValue,
    }));
  };

  const handleUpdateCollectionDiscription = async () => {
    let response = await updateCollectionDiscription(data);
    if (response.status === 200) {
      handleClose();
      setIsInputValue(false);
      setIsEdit(true);
      toast.success(`${response?.message}`, {
        autoClose: 1000,
        hideProgressBar: false,
      });
    } else {
      toast.error(`${response?.message}`, {
        autoClose: 1000,
        hideProgressBar: false,
      });
    }
  };

  //This useEffect is Used for Automatically Placing typing cursor at the end of the Existing Text in OutlineInput
  useEffect(() => {
    if (!isEdit && inputRef.current) {
      const length = inputRef.current.value.length;
      // Set cursor position to the end of the text
      inputRef.current.setSelectionRange(length, length);
      inputRef.current.focus();
    }
  }, [isEdit]);

  return (
    <Modal open={open} handleClose={handleClose} style={{ width: "400px", height: "auto", bgcolor: "#fff", scroll: "none" }}>
      <Grid container justifyContent={"center"} gap={"20px"}>
        <Grid item width="100%" display={"flex"} justifyContent={"space-between"} alignItems={"center"} gap={"10px"}>
          <h7 className="tray-heading" style={{ fontSize: "20px", fontWeight: "500", fontFamily: "Poppins", textAlign: "start", margin: "0", color: "black", padding: "0" }}>
            {`${data?.collectionName} Discription`}
          </h7>
          {isEdit && <EditIcon onClick={() => setIsEdit(false)} style={{ cursor: "pointer" }} />}
        </Grid>

        <Grid item width="100%">
          <OutlinedInput
            placeholder="Enter Collection Discription"
            value={data?.collectionDiscription || ""}
            multiline
            onChange={handleDiscriptionChange}
            inputRef={inputRef}
            sx={{
              width: "100%",
              height: "200px",
              borderRadius: "5px",
              color: "#000",
              fontSize: "18px",
              fontFamily: "karla",
              padding: "10px",
              marginBottom: "20px",
              outline: "none",
              textAlign: "start", // Align text to the left
              display: "flex", // Ensures content aligns properly
              alignItems: "flex-start", // Aligns text at the top
              pointerEvents: isEdit ? "none" : "auto",
              overflowY: "auto", // Enables vertical scrolling for overflow content

              // Scrollbar styles
              "&::-webkit-scrollbar": {
                width: "3px", // Thin scrollbar width
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "rgba(161, 156, 156, 0.5)", // Scrollbar thumb color
                borderRadius: "50px", // Rounded scrollbar thumb
              },
              "&::-webkit-scrollbar-thumb:hover": {
                backgroundColor: "rgba(132, 129, 129, 0.7)", // Darker thumb on hover
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: "#f0f0f0", // Scrollbar track color
                borderRadius: "50px", // Rounded scrollbar track
              },

              "&:hover .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              border: isEdit ? "1px solid grey" : "1px solid  #000000",
            }}
          />
          <Box textAlign={"center"}>
            {!isEdit && (
              <Button
                width="100%"
                style={{
                  border: "none",
                  background: isInputValue ? "#50C878" : "#444444",
                  pointerEvents: isInputValue ? "auto" : "none",
                  color: "#000",
                  width: "100%",
                  height: "50px",
                  borderRadius: "5px",
                  fontFamily: "karla",
                  fontSize: "18px",
                  fontWeight: "400",
                  textTransform: "none",
                }}
                onClick={handleUpdateCollectionDiscription}
              >
                Save Discription
              </Button>
            )}
          </Box>
        </Grid>
      </Grid>
    </Modal>
  );
}

export default CollectionDiscriptionModal;
