// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.info-data .info-data .main-grid {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}
.info-data .main-grid .info-data-heading {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
  color: #000000;
  padding-bottom: 0px !important;
}

.info-data .sub-grid {
  /* padding-top: 0px !important; */
}

.info-data .sub-grid .main-div-sub-grid {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 6px 0px !important;
}

.info-data .sub-grid .main-title {
  display: flex;
  align-items: center;
}

.info-data .sub-grid .sub-title {
  font-family: "Karla";
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/InfoModal/infomodal.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,mBAAmB;EACnB,2BAA2B;EAC3B,8BAA8B;AAChC;AACA;EACE,sBAAsB;EACtB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,cAAc;EACd,8BAA8B;AAChC;;AAEA;EACE,iCAAiC;AACnC;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;EACT,2BAA2B;AAC7B;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,oBAAoB;EACpB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,cAAc;AAChB","sourcesContent":[".info-data .info-data .main-grid {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n  padding-top: 8px !important;\n  padding-bottom: 8px !important;\n}\n.info-data .main-grid .info-data-heading {\n  font-family: \"Poppins\";\n  font-weight: 500;\n  font-size: 20px;\n  line-height: 22px;\n  color: #000000;\n  padding-bottom: 0px !important;\n}\n\n.info-data .sub-grid {\n  /* padding-top: 0px !important; */\n}\n\n.info-data .sub-grid .main-div-sub-grid {\n  display: flex;\n  align-items: center;\n  gap: 10px;\n  padding: 6px 0px !important;\n}\n\n.info-data .sub-grid .main-title {\n  display: flex;\n  align-items: center;\n}\n\n.info-data .sub-grid .sub-title {\n  font-family: \"Karla\";\n  font-weight: 400;\n  font-size: 16px;\n  line-height: 22px;\n  color: #000000;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
