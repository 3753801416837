import React from 'react'
import Card from '../components/Card'
import { Grid } from '@mui/material'
import { useEffect } from 'react'
import { useContext } from 'react'
import { SettingContext } from 'context/SettingContext'
import SingleSyncModal from 'components/ui/RemoveModal/RemoveModal'
import { useState } from 'react'


const SyncMakeTypePage = () => {
  const { setSyncMakeTypeButton, setSingleRevertModal, singleRevertModal } = useContext(SettingContext);

  const [currentSelctelement,setCurrentSelctelement] = useState(null);  

  const makeCardData = [
    { Name: "NS-00410", FromText: "R", ToText: "H" },
    { Name: "NS-00410", FromText: "R", ToText: "H" },
    { Name: "NS-00410", FromText: "R", ToText: "H" },
    { Name: "NS-00410", FromText: "R", ToText: "H" },
    { Name: "NS-00410", FromText: "R", ToText: "H" },
    { Name: "NS-00410", FromText: "R", ToText: "H" },
  ]

  const SingleSyncBtnInfo = React.useMemo(
    () => [
      {
        btnName: "Discard",
        bgColor: "#E77B7B",
        click: () => setSingleRevertModal(false),
      },
      {
        btnName: "Revert",
        bgColor: "#50C878",
        click: () => "",
      },
    ],
    []
  );

  const handleClick = (data)=>{
    setSingleRevertModal(true)  
    setCurrentSelctelement(data?.Name) 
  }

  useEffect(() => {
    setSyncMakeTypeButton(true);
    return () => {
      setSyncMakeTypeButton(false);
    }
  }) 

  return (
    <>
      <Grid container spacing={4}>
        {makeCardData.map((data, index) => (
          <Grid item xs={12} md={6} lg={4}>
            <Card key={index} data={data} handleClick={handleClick} />
          </Grid>
        ))}
      </Grid>

      {/* modal */}


      <SingleSyncModal open={singleRevertModal} setOpen={setSingleRevertModal} btnInfo={SingleSyncBtnInfo} heading={`Are you sure you want to revert ${currentSelctelement}?`} />
    </>
  )
}

export default SyncMakeTypePage