import React, { useCallback, useEffect, useState } from "react";
import Modal from "../Modal";
import { Grid, IconButton, Typography } from "@mui/material";
import "./saleDetail.css";
import "./yearlySales.css";
import { getIcon } from "utils/iconsConfig";
import TableSales from "./TableSales.jsx";
import TableYearlySale from "./TableYearlySale";
import { fetchDetails } from "./fetchDetails";

const SaleDetailsModal = ({ open, setOpen, styleNumber }) => {
  const [salesData, setSalesData] = useState([]);
  const [yearlySalesData, setYearlySetData] = useState([]);
  const [totalSales, setTotalSales] = useState(0);
  const [salesLoading, setSalesLoading] = useState(false);
  const [yearlySalesLoading, setYearlySalesLoading] = useState(false);
  const [salesError, setSalesError] = useState(null);
  const [yearlySalesError, setYearlySalesError] = useState(null);
  const [isYearSalesDetailsView, setIsYearSalesDetailsView] = useState(false);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen, setIsYearSalesDetailsView]);

  const handleToggleSales = useCallback(() => {
    setIsYearSalesDetailsView((prev) => !prev);
  }, []);

  useEffect(() => {
    if (open && styleNumber && salesData.length === 0) {
      const fetchSales = async () => {
        const data = await fetchDetails({ url: `/api/v1/styles/salesBagTest`, method: "post", body: { styleNumber }, setLoading: setSalesLoading, setError: setSalesError });
        if (data) {
          setSalesData(Object.entries(data?.data).map(([key, value]) => ({ name: key, sale: value })) || []);
          setTotalSales(data?.salesCount || 0);
        }
      };
      fetchSales();
    }
  }, [open, styleNumber, salesData.length]);

  useEffect(() => {
    if (isYearSalesDetailsView && styleNumber && yearlySalesData.length === 0) {
      const fetchYearlySales = async () => {
        const data = await fetchDetails({
          url: `/api/v1/styles/trendSalesYearly`,
          method: "get",
          params: {
            styleNumber,
          },
          setLoading: setYearlySalesLoading,
          setError: setYearlySalesError,
        });
        if (data?.data) setYearlySetData(data?.data || []);
      };
      fetchYearlySales();
    }
  }, [isYearSalesDetailsView, styleNumber, yearlySalesData.length]);

  return (
    <>
      <Modal open={open} handleClose={handleClose} style={{ width: isYearSalesDetailsView ? "285px" : "400px" }}>
        <Grid container spacing={2} className={isYearSalesDetailsView ? "yearly-sales-data" : "sale-modal"}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="main-grid">
            <Typography className={isYearSalesDetailsView ? "yearly-sales-data-heading" : "best-seller-heading"}>
              <IconButton disableFocusRipple disableRipple sx={{ p: 0, pr: 0.5 }}>
                {getIcon(isYearSalesDetailsView ? "date" : "sales", "black")}
              </IconButton>
              {isYearSalesDetailsView ? `Trend Of ${styleNumber}` : `Sales Of ${styleNumber}`}
            </Typography>
            <Typography>
              <IconButton disableFocusRipple disableRipple sx={{ p: 0, pl: 1.5 }} onClick={handleToggleSales}>
                {getIcon(isYearSalesDetailsView ? "sales" : "date", "black")}
              </IconButton>
            </Typography>
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="sub-grid scrollable-grid">
            {salesLoading || yearlySalesLoading ? (
              <h3>Loding ...</h3>
            ) : salesError || yearlySalesError ? (
              <h4 style={{ color: "red" }}>{salesError || yearlySalesError}</h4>
            ) : isYearSalesDetailsView ? (
              <TableYearlySale yearlySalesData={yearlySalesData} />
            ) : (
              <TableSales salesData={salesData} totalSales={totalSales} />
            )}
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};

export default SaleDetailsModal;
