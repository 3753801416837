const { createTheme } = require("@mui/material");

const customTheme = createTheme({
  components: {
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: "#000000",
          fontFamily: "Poppins",
          fontWeight: 500,
          fontSize: "20px",
          lineHeight: "30px",
          "&.Mui-focused": {
            color: "#000000",
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          "& .MuiFormControlLabel-label": {
            color: "#000000",
            fontFamily: "Karla",
            fontSize: "18px",
            fontWeight: 400,
            lineHeight: "21.04px",
          },
          gap: "25px",
          padding: "10px",
          margin: "0px",
          paddingLeft: "0px",
          marginLeft:"-2px"
        },
      },
    },
  },
});

export default customTheme;
