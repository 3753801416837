import React, { useEffect, useRef, useState } from "react";
import Modal from "../../../../components/ui/Modal";
import { Box, Grid, Input, OutlinedInput } from "@mui/material";
import { TextareaAutosize as MUITextareaAutosize } from "@mui/base/TextareaAutosize";

import { useSettingContext } from "context/SettingContext";
import Button from "../../../../components/ui/Button";
import { ToastContainer, toast } from "react-toastify";

import { ReactComponent as EditIcon } from "assets/icons/edit_black.svg";
import { updateCollectionDiscription } from "../CollectionProductScreen/api/getCollectionProducts";

import "./collectiondiscription.css";

function CollectionDiscriptionModal({ open, setOpen, data, setData }) {
  const [isInputValue, setIsInputValue] = useState(false);
  const [isEdit, setIsEdit] = useState(true);
  const inputRef = useRef();

  const handleClose = () => {
    setOpen(false);
  };

  const handleDiscriptionChange = (event) => {
    console.log("In HandleDiscriptionChange");
    const inputValue = event.target.value;
    const trimmedValue = inputValue.trim();

    if (trimmedValue !== "") {
      setIsInputValue(true);
    } else {
      setIsInputValue(false);
    }

    setData((prev) => ({
      ...prev,
      collectionDiscription: inputValue,
    }));
  };

  const handleUpdateCollectionDiscription = async () => {
    let response = await updateCollectionDiscription(data);
    if (response.status === 200) {
      handleClose();
      setIsInputValue(false);
      setIsEdit(true);
      toast.success(`${response?.message}`, {
        autoClose: 1000,
        hideProgressBar: false,
      });
    } else {
      toast.error(`${response?.message}`, {
        autoClose: 1000,
        hideProgressBar: false,
      });
    }
  };

  //This useEffect is Used for Automatically Placing typing cursor at the end of the Existing Text in OutlineInput
  // useEffect(() => {
  //   if (!isEdit && inputRef?.current) {
  //     const length = inputRef?.current?.value?.length;
  //     // Set cursor position to the end of the text
  //     inputRef?.current?.focus();
  //     inputRef?.current?.setSelectionRange(length, length);
  //   }
  // }, [isEdit]);

  return (
    <Modal open={open} handleClose={handleClose} style={{ width: "400px", height: "auto", bgcolor: "#fff", scroll: "none" }}>
      <Grid container justifyContent={"center"} gap={"20px"}>
        <Grid item width="100%" display={"flex"} justifyContent={"space-between"} alignItems={"center"} gap={"10px"}>
          <h6 className="tray-heading" style={{ fontSize: "20px", fontWeight: "500", fontFamily: "Poppins", textAlign: "start", margin: "0", color: "black", padding: "0" }}>
            {`${data?.collectionName} Discription`}
          </h6>
          {isEdit && <EditIcon onClick={() => setIsEdit(false)} style={{ cursor: "pointer" }} />}
        </Grid>

        <Grid item width="100%">
          <MUITextareaAutosize
            placeholder="Enter Collection Description"
            value={data?.collectionDiscription || ""}
            onChange={handleDiscriptionChange}
            readOnly={isEdit}
            maxRows={1}
            className="custom-textarea"
            style={{
              width: "100%",
              height: "200px",
              borderRadius: "5px",
              fontSize: "18px",
              fontFamily: "karla",
              padding: "10px",
              marginBottom: "20px",
              textAlign: "start",
              overflowY: "auto",
              border: isEdit ? "1px solid grey" : "1px solid black",
              resize: "none",
              outline: "none",
            }}
          />

          <Box textAlign={"center"}>
            {!isEdit && (
              <Button
                width="100%"
                style={{
                  border: "none",
                  background: isInputValue ? "#50C878" : "#444444",
                  pointerEvents: isInputValue ? "auto" : "none",
                  color: "#000",
                  width: "100%",
                  height: "50px",
                  borderRadius: "5px",
                  fontFamily: "karla",
                  fontSize: "18px",
                  fontWeight: "400",
                  textTransform: "none",
                }}
                onClick={handleUpdateCollectionDiscription}
              >
                Save Discription
              </Button>
            )}
          </Box>
        </Grid>
      </Grid>
    </Modal>
  );
}

export default CollectionDiscriptionModal;
