// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.yearly-sales-data .main-grid {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}
.yearly-sales-data .main-grid .yearly-sales-data-heading {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #000000;
}

.yearly-sales-data .sub-grid .table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #000000;
}

.yearly-sales-data .sub-grid .table .table-head th,
.yearly-sales-data .sub-grid .table .table-body td {
  font-family: "Karla";
  font-weight: 400;
  font-size: 18px;
  line-height: 21.04px;
  color: #000000;
  border: 1px solid black;
  padding: 8px;
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/SaleDetailsModal/yearlySales.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,mBAAmB;EACnB,2BAA2B;EAC3B,8BAA8B;AAChC;AACA;EACE,sBAAsB;EACtB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,yBAAyB;EACzB,yBAAyB;AAC3B;;AAEA;;EAEE,oBAAoB;EACpB,gBAAgB;EAChB,eAAe;EACf,oBAAoB;EACpB,cAAc;EACd,uBAAuB;EACvB,YAAY;EACZ,kBAAkB;AACpB","sourcesContent":[".yearly-sales-data .main-grid {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n  padding-top: 8px !important;\n  padding-bottom: 8px !important;\n}\n.yearly-sales-data .main-grid .yearly-sales-data-heading {\n  font-family: \"Poppins\";\n  font-weight: 500;\n  font-size: 18px;\n  line-height: 27px;\n  color: #000000;\n}\n\n.yearly-sales-data .sub-grid .table {\n  width: 100%;\n  border-collapse: collapse;\n  border: 1px solid #000000;\n}\n\n.yearly-sales-data .sub-grid .table .table-head th,\n.yearly-sales-data .sub-grid .table .table-body td {\n  font-family: \"Karla\";\n  font-weight: 400;\n  font-size: 18px;\n  line-height: 21.04px;\n  color: #000000;\n  border: 1px solid black;\n  padding: 8px;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
