import React, { useEffect } from "react";
import { useGetDesigns } from "../api/get-designs";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import { transformDataForTrayModal } from "utils/transformdata";
import "features/Category/categoryProd/components/product.css";
import { ProductCard } from "components/ui";
import ProductSkeleton from "components/ui/Skeleton/ProductSkeleton";
import { useGlobalContext } from "context/GlobalContext";
import InfoModal from "components/ui/InfoModal/InfoModal";

const SetDesings = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { data, error, isLoading } = useGetDesigns(id);
  const { setIsInfoBtn, infoModalOpen, setInfoModalOpen } = useGlobalContext();

  useEffect(() => {
    //When user copy paste URL
    const params = new URLSearchParams(location.search);

    if (!location?.state?.name) {
      const setNumberAndCount = decodeURIComponent(params.get("setNumber"));
      const state = {
        ...location.state,
        name: [setNumberAndCount],
      };
      navigate(`${location.pathname}${location.search}`, { state: state, replace: true });
    }

    setIsInfoBtn(true);
    return () => {
      setIsInfoBtn(false);
    };
  }, []);

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (isLoading) return <ProductSkeleton count={12} />;

  return (
    <>
      <Grid
        container
        className="Grid-padding"
        direction="row"
        justifyContent="flex-start"
        sx={{
          flex: "1 1 1",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: "32px",
          rowGap: "32px",
        }}
      >
        {data &&
          data?.designs?.map((design) => {
            const transformedProduct = transformDataForTrayModal(design);
            return (
              <Grid key={transformedProduct.id} item className="product">
                <ProductCard data={transformedProduct} style={{ maxWidth: "430px", minWidth: "430px" }} />
              </Grid>
            );
          })}
      </Grid>
      <InfoModal open={infoModalOpen} setOpen={setInfoModalOpen} setId={id} />
    </>
  );
};

export default SetDesings;
