import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getRestrictedSubCategories } from "./api/restrictedPageApis";
import Home from "pages/Home";
import { Card } from "components/ui";

import { Grid, Typography } from "@mui/material";
import LoadingSmallCard from "components/ui/Skeleton/LoadingSmallCard";
import { encodeStr } from "utils/encodeDecodeStr";

function RestrictedDesignSubCategoryPage() {
  console.log("============ INSIDE RestrictedDesignSubCategoryPage ============= ");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  const handleClick = (data) => {
    const state = {
      ...location.state,
      name: [`${location?.state?.name[0]}`, `${location?.state?.name[1]}`, `${data?.subCategory}`],
    };
    navigate(`/Setting/restricted-designs/${encodeStr(location?.state?.name[1])}/${encodeStr(data?.subCategory)}`, { state });
  };

  useEffect(() => {
    const fetchRestrictedSubCategories = async () => {
      let payload = {
        categoryName: location?.state?.name[1],
      };
      let response = await getRestrictedSubCategories(payload);

      if (response.status === 200) {
        // let categoryName = response.message.split(":")[1];
        setData(response.subCategories);
        setLoading(false);
      } else {
        alert("something went wrong");
      }
    };
    fetchRestrictedSubCategories();
  }, []);

  if (loading) return <LoadingSmallCard count={24} />;

  return (
    <>
      {data && data.length > 0 ? (
        <Grid
          container
          className="Grid-padding"
          direction="row"
          justifyContent="flex-start"
          sx={{ flex: "1 1 1", display: "flex", alignItems: "center", justifyContent: "flex-start", gap: "30px", rowGap: "32px" }}
        >
          {data &&
            data.map((ele, i) => (
              <Grid item key={i} onClick={() => handleClick(ele)}>
                <Card style={{ minWidth: 275, maxWidth: "317px", height: 62, borderRadius: "5px" }}>
                  <Typography mr={"auto"} fontSize={"20px"} fontWeight={400} fontFamily={"Karla"}>
                    {ele.subCategory}
                  </Typography>
                  <Typography ml={"auto"} fontSize={"20px"} fontWeight={400} fontFamily={"Karla"}>
                    {ele.count}
                  </Typography>
                </Card>
              </Grid>
            ))}
        </Grid>
      ) : (
        <Home />
      )}
    </>
  );
}

export default RestrictedDesignSubCategoryPage;
