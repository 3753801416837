import React from 'react'
import Card from '../components/Card'
import { Grid } from '@mui/material'
import { SettingContext } from 'context/SettingContext'
import { useContext } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import SingleSyncModal from 'components/ui/RemoveModal/RemoveModal'




const SyncSetsPage = () => {
    const { setSyncButton,setSingleRevertModal,singleRevertModal} = useContext(SettingContext);

    const [currentSelctelement,setCurrentSelctelement] = useState(null);
    
    const syncSetData = [
        { Name: "NS-00410 sets site1", FromText: "SET-1001,SET-1002", ToText: "Null" },
        { Name: "NS-00410 sets site2", FromText: "SET-1001,SET-1002", ToText: "Null" },
        { Name: "NS-00410 sets site3", FromText: "SET-1001,SET-1002", ToText: "Null" },
        { Name: "NS-00410 sets site4", FromText: "SET-1001,SET-1002", ToText: "Null" },
        { Name: "NS-00410 sets site5", FromText: "SET-1001,SET-1002", ToText: "Null" },
        { Name: "NS-00410 sets site6", FromText: "SET-1001,SET-1002", ToText: "Null" },
    ]

    const SingleSyncBtnInfo = React.useMemo(
        () => [
            {
                btnName: "Discard",
                bgColor: "#E77B7B",
                click: () => setSingleRevertModal(false),
            },
            {
                btnName: "Revert",
                bgColor: "#50C878",
                click: () => "",
            },
        ],
        []
    );

    const handleClick =(data) =>{
        setSingleRevertModal(true)  
        setCurrentSelctelement(data?.Name) 
    }

    useEffect(() => {
        setSyncButton(true)
        return () => {
            setSyncButton(false);
        }
    }, [])

    return (<>

        <Grid container spacing={4}>
            {syncSetData.map((data, index) => (
                <Grid item xs={12} md={6} lg={4}>
                    <Card data={data} handleClick={handleClick} />
                </Grid>
            ))}
        </Grid>

         {/** modal */}   
        <SingleSyncModal open={singleRevertModal} setOpen={setSingleRevertModal} btnInfo={SingleSyncBtnInfo} heading={`Are you sure you want to revert ${currentSelctelement}?`} />
   
    </>
    )
}

export default SyncSetsPage