import axios from "axios";
import config from "configs/config";
import apiClient from "lib/api-client";

//--------------- Save Rights ------------------------\\

export const getRestrictedCategories = async () => {
  let response = await apiClient.post(`${config.baseUrl}/api/v1/styles/getRestrictedCategories`);
  return response.data;
};

export const getRestrictedSubCategories = async (payload) => {
  let response = await apiClient.post(`${config.baseUrl}/api/v1/styles/getRestrictedSubCategories`, payload);
  return response.data;
};

export const fetchRestricatCategoryDataCount = async () => {
  try {
    const response = await apiClient.get(`${config.baseUrl}/api/v1/styles/getTotalCountCategoryWithDesign?isRestrict=true`);
    return response?.data;
  } catch (error) {
    console.error("Error in fetchRestricatCategoryDataCount:", error);
    throw error; 
  }
};

// http://localhost:8000/api/v1/styles/getStylesBySubCategory
