import { Box, styled } from "@mui/material";
import Button from "components/ui/Button";
import React, { useCallback, useMemo, useState } from "react";
import catalogueDataJson from "db/catalogueData.json";
import collectionDataJson from "db/collectionData.json";

import { useLocation, useNavigate } from "react-router-dom";
import RemoveModal from "components/ui/RemoveModal/RemoveModal";
import { useCreateCatalog } from "features/catalogue/hooks/useCreateCatalog";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { getUserDetail } from "utils/getUserDetailsHelper";
import useCreateCollection from "hooks/useCreateCollection";
import { useCreateSet } from "features/set/api/create-set";
import { useRemoveDesings } from "features/set/hooks/useRemoveDesigns";
import { useUpdateSet } from "features/set/api/update-set";
import debounce from "lodash.debounce";
import apiClient from "lib/api-client";
import config from "configs/config";
import { useQueryClient } from "@tanstack/react-query";

const StyledButton = styled(Button)(({ theme, bgcolor }) => ({
  width: "100%",
  fontFamily: "Poppins",
  fontWeight: 500,
  fontSize: "18px",
  lineHeight: "22px",
  height: "42px",
  borderRadius: "5px",
  color: bgcolor ? bgcolor : "#ffffff",
  border: `1px solid ${bgcolor ? bgcolor : "#ffffff"}`,
  textTransform: "none",
  backgroundColor: "transparent",
  "&:hover": {
    backgroundColor: bgcolor,
    color: "black",
  },
  "&.Mui-disabled": {
    backgroundColor: "#000000",
    color: "#8B8B8B",
    border: "1px solid #8B8B8B",
  },
}));

function CartShowHeader({ Search, StyledInputBase, btnTitle, setCountCart, countCart, origin }) {
  const [newName, setNewName] = useState("");
  const [collectionData, setCollectionData] = useState(collectionDataJson);
  const [clearCartModal, setClearCartModal] = useState(false);

  const { mutate: createCollection } = useCreateCollection();

  const navigate = useNavigate();
  const { mutate: createCatalog } = useCreateCatalog(origin);

  const handleDiscardChanges = useCallback(() => {
    setNewName("");
    setCountCart(0);
    sessionStorage.removeItem("CartData");
    sessionStorage.removeItem("createName");
    setClearCartModal(false);
    if (origin?.cataloguUpdate) {
      let state = {
        subName: "Edit",
        edit: true,
        innerChnages: false,
        name: origin?.backName,
        catalogueName: origin?.cataloguUpdate ? origin?.catalogueName : "",
        cataloguUpdate: origin?.cataloguUpdate ? false : true,
      };
      navigate(origin.backPath, { state });
    } else if (origin?.collectionUpdate) {
      let state = {
        innerChnages: false,
        name: origin?.backName,
        catalogueName: origin?.collectionUpdate ? origin?.catalogueName : "",
        collectionUpdate: origin?.collectionUpdate ? false : true,

        collection: true,
      };
      navigate(origin.backPath, { state });
    } else {
      navigate(origin.backPath);
    }
  }, [setCountCart]);

  //-------------------------------------Start cereat catalog---------------------------------------//

  const handleCreateCatalogue = useCallback(() => {
    const cartData = JSON.parse(sessionStorage.getItem("CartData")) || [];

    const findUniqueData = [...new Set(cartData.map((item) => item.id || item._id))];
    const userId = getUserDetail("_id") || "66e81ad067984093881d174b";

    const newCatalog = {
      catalogName: newName,
      userRef: userId,
      selectedDesignIds: findUniqueData,
    };
    createCatalog(newCatalog);
    sessionStorage.removeItem("createName");
  }, [createCatalog, newName]);

  //-------------------------------------end cereat catalog---------------------------------------//

  // ----------------------------------- Craeting Collection Start-------------------------------------------\\
  const queryClient = useQueryClient();
  const handleCreateCollection = useCallback(async () => {
    // const userInfo = JSON.parse(sessionStorage.getItem("userData"))._id; // issue here

    const userInfo = getUserDetail("_id");

    const cartInfo = JSON.parse(sessionStorage.getItem("CartData"));

    const getCartIds = cartInfo.map((data) => data.id);

    const newCollection1 = {
      collectionName: newName,
      userRef: userInfo, // A valid User ID
      selectedDesignIds: getCartIds,
    };

    createCollection(newCollection1, {
      onSuccess: (data) => {
        if (data?.data?.status === 200) {
          handleDiscardChanges();
          queryClient.removeQueries("collectionCountData");
          navigate(origin.backPath);
        } else if (data?.data?.status === 409) {
          toast.error(`${data?.data?.message}`, {
            autoClose: 1000, // Disappears after 1 seconds
            hideProgressBar: false, // Ensure progress bar is shown
          });
        }
      },
      onError: (error) => {
        console.error("Error creating collection:", error);
        toast.error(`${error?.response?.data?.message}`, {
          autoClose: 1000, // Disappears after 1 seconds
          hideProgressBar: false, // Ensure progress bar is shown
        });
      },
    });
  }, [newName, countCart, collectionData, handleDiscardChanges, navigate, origin.backPath]);

  //----------------- handleUpdateCollection start --------------------\\
  const handleUpdateCollection = async () => {
    let updateProductData = JSON.parse(sessionStorage.getItem("updateProductsData")) || [];
    let cartProductData = JSON.parse(sessionStorage.getItem("CartData")) || [];
    sessionStorage.removeItem("CartData");
    // updateProductData = [...updateProductData, ...cartProductData];
    updateProductData = cartProductData;

    sessionStorage.setItem("updateProductsData", JSON.stringify(updateProductData));
    setCountCart(0);
    let state = {
      innerChnages: false,
      name: origin?.backName,
      catalogueName: origin?.collectionUpdate ? origin?.catalogueName : "",
      collectionUpdate: origin?.collectionUpdate ? false : true,
      collection: true,
    };

    let payload = {
      collectionId: JSON.parse(sessionStorage.getItem("collectionIdOfProducts")),
      styleRefIds: updateProductData.map((style) => style.id),
    };
    let updateCollection = await apiClient.post(`${config.baseUrl}/api/v1/collection/addDesignsToCollection`, payload);

    if (updateCollection.status === 200) {
      queryClient.removeQueries("collectionCountData");
      toast.success(`${"Collection Updated Succesfully "}`, {
        autoClose: 1000,
        hideProgressBar: false,
      });
      sessionStorage.removeItem("updateProductsData");
      navigate(origin?.backPath, { state });
    } else {
      alert("Something Went wrong !!!");
    }
  };
  //----------------- handleUpdateCollection end ----------------------\\

  // ----------------------------------- Craeting Collection End -------------------------------------------\\

  //  ====================================UPDATING THE SET=============================
  const { createAddDesignsPayload } = useRemoveDesings();
  const { mutate: updateSetMutation } = useUpdateSet({
    onCompleted: () => {
      sessionStorage.removeItem("CartData");
      setCountCart(0);
      let state = {
        innerChnages: false,
        name: origin?.backName,
        catalogueName: origin?.cataloguUpdate ? origin?.catalogueName : "",
        setUpdate: origin?.cataloguUpdate ? false : true,
      };
      navigate(origin?.backPath, { state });
    },
  });

  const handleUpdateSet = () => {
    updateSetMutation(createAddDesignsPayload(origin.setId));
  };

  // =======================================END OF UPDATE SET===========================

  // ===================================CREATING THE SET================================
  const { mutate: createSet } = useCreateSet({
    onCompleted: () => {
      // Reset the cart and navigate back on successful creation
      sessionStorage.removeItem("CartData");
      setCountCart(0);
      navigate(origin.backPath);
    },
  });

  const handleCreateSet = useCallback(() => {
    createSet();
  }, [newName, countCart]);
  // =====================================END CREATE SET==================================

  const returnBtnTitle = useMemo(() => {
    let title;
    if (origin?.cataloguUpdate) {
      title = {
        name: "Update Catalog",
        input: false,
        placeholder: "",
        click: () => {
          let updatProductData = JSON.parse(sessionStorage.getItem("updateProductsData")) || [];
          let cartProductData = JSON.parse(sessionStorage.getItem("CartData")) || [];
          sessionStorage.removeItem("CartData");
          updatProductData = [...updatProductData, ...cartProductData];
          sessionStorage.setItem("updateProductsData", JSON.stringify(updatProductData));
          setCountCart(0);
          let state = {
            subName: "Edit",
            edit: true,
            innerChnages: false,
            name: origin?.backName,
            catalogueName: origin?.cataloguUpdate ? origin?.catalogueName : "",
            cataloguUpdate: origin?.cataloguUpdate ? false : true,
          };
          navigate(origin?.backPath, { state });
        },
      };
    } else if (origin?.setUpdate) {
      title = {
        name: "Update set",
        input: false,
        placeholder: "",
        click: handleUpdateSet,
      };
    } else if (origin?.collectionUpdate) {
      title = {
        name: "Update Collection",
        input: false,
        placeholder: "",
        click: handleUpdateCollection,
      };
    } else {
      switch (btnTitle) {
        case "New Catalog": {
          title = {
            name: "Create Catalog",
            click: handleCreateCatalogue,
            input: true,
            placeholder: "Catalog",
          };
          break;
        }
        case "New Collection": {
          title = {
            name: "Create Collection",
            click: handleCreateCollection,
            input: true,
            placeholder: "Collection",
          };
          break;
        }
        case "New Set": {
          title = {
            name: "Create Set",

            click: handleCreateSet,
            // click: () => {
            //   const products = JSON.parse(sessionStorage.getItem("CartData")) || [];
            //   localStorage.setItem("set", JSON.stringify(products));
            //   sessionStorage.removeItem("CartData");
            //   setCountCart(0);
            //   navigate(origin?.backPath);
            // },
            input: false,
            placeholder: "",
          };
          break;
        }
        default: {
          title = {
            name: "Update",
            click: () => {
              alert("Navigate To Edit Page");
            },
          };
        }
      }
    }
    return title;
  }, [btnTitle, handleCreateCatalogue, origin.backPath, origin]);

  const btnInfo = useMemo(
    () => [
      {
        btnName: returnBtnTitle.name,
        bgColor: "#50C878",
        click: returnBtnTitle.click,
      },
      {
        btnName: "Clear Cart",
        bgColor: "#E77B7B",
        click: () => {
          setClearCartModal(true);
        },
      },
    ],
    [returnBtnTitle, handleDiscardChanges]
  );

  const clearCartBtnInfo = [
    {
      btnName: "Clear",
      bgColor: "#E77B7B",
      click: () => handleDiscardChanges(),
    },
    {
      btnName: "Keep",
      bgColor: "#50C878",
      click: () => setClearCartModal(false),
    },
  ];

  const handleInputChange = useCallback((e) => {
    const updatedName = e.target.value;
    setNewName(updatedName);
    debounceSessionUpdateCreatName(updatedName);
  }, []);

  const debounceSessionUpdateCreatName = useCallback(
    debounce((updatedName) => {
      sessionStorage.setItem("createName", JSON.stringify(updatedName));
    }, 300),
    []
  );

  useEffect(() => {
    const createName = JSON.parse(sessionStorage.getItem("createName")) || "";
    if (createName) {
      setNewName(createName);
    }
  }, []);

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 4 }}>
      {returnBtnTitle.input ? (
        <Search>
          <StyledInputBase placeholder={`Nickname For ${returnBtnTitle.placeholder}`} onChange={handleInputChange} value={newName} inputProps={{ "aria-label": "search" }} />
        </Search>
      ) : null}

      {btnInfo.map((btn) => (
        <>
          <Box key={btn.btnName}>
            <StyledButton bgcolor={btn.bgColor} onClick={btn.click} disabled={btn.btnName === returnBtnTitle.name && returnBtnTitle.input ? !newName : false}>
              {btn.btnName}
            </StyledButton>
          </Box>
          {clearCartModal && <RemoveModal open={clearCartModal} setOpen={setClearCartModal} heading="Do you want to Clear the Cart?" btnInfo={clearCartBtnInfo} />}
        </>
      ))}
    </Box>
  );
}

export default CartShowHeader;
