import { LastPage } from "@mui/icons-material";
import { useInfiniteQuery } from "@tanstack/react-query";
import config from "configs/config";
import apiClient from "lib/api-client";

export const removeCollections = async (collectionIds) => {
  let Payload = {
    collectionId: collectionIds,
  };
  let response = await apiClient.put(`${config.baseUrl}/api/v1/collection/deleteCollections`, Payload);
  return response.data;
};

export const updateCollectionNames = async (UpdateData) => {
  let Payload = {
    collectionUpdatedData: UpdateData,
  };
  let response = await apiClient.put(`${config.baseUrl}/api/v1/collection/updateCollections`, Payload);
  return response.data;
};

export const updateCollectionDiscription = async (Payload) => {
  let response = await apiClient.put(`${config.baseUrl}/api/v1/collection/updateCollectionDiscription`, Payload);
  return response.data;
};

// --------------------

const fetchCollectionsDesign = async ({ pageParam = 1, queryKey }) => {
  const [
    ,
    {
      collectionName,
      categoryName,
      limit,
      searchTerm,
      sortBy,
      order,
      labourFilter,
      classFilter,
      diamondPieces,
      diamondWeight,
      goldGrossWeight,
      goldNetWeight,
      designInCollection,
      designInSets,
      bestSeller,
      highestSeller,
    },
  ] = queryKey;

  const payload1 = {
    collectionName,
    categoryName,
    pageNumber: pageParam,
    limit,
    searchTerm,
    sortBy,
    order,
    labourFilter: labourFilter.length > 0 ? labourFilter : "",
    classFilter: classFilter.length > 0 ? classFilter : "",
    diamondPieces: diamondPieces.length > 0 ? diamondPieces : "",
    diamondWeight: diamondWeight.length > 0 ? diamondWeight : "",
    goldGrossWeight: goldGrossWeight.length > 0 ? goldGrossWeight : "",
    goldNetWeight: goldNetWeight.length > 0 ? goldNetWeight : "",
    designInCollection: designInCollection.length > 0 ? designInCollection : "",
    designInSets: designInSets.length > 0 ? designInSets : "",
    bestSeller: bestSeller[0] === "No" ? "NO" : bestSeller[0] === "Yes" ? "YES" : "",
    highestSeller: highestSeller?.length > 0 ? highestSeller : "",
  };

  const response = await apiClient.post(`${config.baseUrl}/api/v1/collection/getDesignsInCollection`, payload1);

  if (response.status !== 200) {
    throw new Error("Error fetching categories");
  }

  //  setCollectionId(response.data.collectionId);

  return { data: response.data.designs, nextPage: response.data.pagination.pageNumber + 1, totalPages: response.data.pagination.totalPages, collectionId: response.data.collectionId };
};

export const useCollectionDesigns = ({
  categoryName = "",
  limit = 10,
  collectionName = "",
  searchTerm = "",
  sortBy = "",
  order = "asc",
  labourFilter = "",
  classFilter = "",
  diamondPieces = "",
  diamondWeight = "",
  goldGrossWeight = "",
  goldNetWeight = "",
  designInCollection = "",
  designInSets = "",
  bestSeller = "",
  highestSeller = "",
}) => {
  return useInfiniteQuery({
    queryKey: [
      "collectiondesigns",

      {
        collectionName,
        categoryName,
        limit,
        searchTerm,
        sortBy,
        order,
        labourFilter,
        classFilter,
        diamondPieces,
        diamondWeight,
        goldGrossWeight,
        goldNetWeight,
        designInCollection,
        designInSets,
        bestSeller,
        highestSeller,
      },
    ],

    queryFn: ({ pageParam = 1 }) =>
      fetchCollectionsDesign({
        pageParam,
        queryKey: [
          "collectiondesigns",

          {
            collectionName,
            categoryName,
            limit,
            searchTerm,
            sortBy,
            order,
            labourFilter,
            classFilter,
            diamondPieces,
            diamondWeight,
            goldGrossWeight,
            goldNetWeight,
            designInCollection,
            designInSets,
            bestSeller,
            highestSeller,
          },
        ],
      }),

    getNextPaageParam: (lastPage) => {
      if (lastPage.nextPage <= lastPage.totalPages) {
        return lastPage.nextPage;
      }
      return undefined;
    },
    staleTime: 5 * 60 * 1000,
    retry: 1,
  });
};
