import React from "react";
import { Tooltip as MUITooltip } from "@mui/material";
import { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

const LightTooltip = styled(({ className, ...props }) => <MUITooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    display: "flex", // Enables flexbox
    alignItems: "center", // Centers content vertically
    justifyContent: "center", // Centers content horizontally
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: "8px",
    fontFamily: "Karla",
    lineHeight: "22px",
    fontWeight: 700,
    maxWidth: 500,
    minWidth: "52px",
    padding: "0 5px",
    height: "15px",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
  },
}));

export default function Tooltip(props) {
  return props?.title !== null ? (
    <LightTooltip
      title={props?.title}
      placement={props?.placement || "bottom"}
      arrow
      slotProps={{
        popper: {
          sx: {
            [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]: {
              marginTop: props?.marginTop || "0px",
            },
            [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]: {
              marginBottom: props?.marginBottom || "0px",
            },
            [`&.${tooltipClasses.popper}[data-popper-placement*="right"] .${tooltipClasses.tooltip}`]: {
              marginLeft: props?.marginLeft || "0px",
            },
            [`&.${tooltipClasses.popper}[data-popper-placement*="left"] .${tooltipClasses.tooltip}`]: {
              marginRight: props?.marginRight || "0px",
            },
          },
        },
      }}
    >
      {props?.children}
    </LightTooltip>
  ) : (
    <>{props?.children}</>
  );
}
