import React from "react";
import { AsyncImage } from "loadable-image";
import watermarkImageUrl from "../../assets/img/watermark.png";

const ImageComp = ({ url, hasAccessToSeeWatermark }) => {
  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: "100%",
      }}
    >
      {/* Original image */}
      <AsyncImage
        src={url}
        style={{
          width: "100%",
          height: "100%",
          objectFit: "fill",
        }}
        loader={<div style={{ background: "#888", width: "100%", height: "100%" }} />}
        error={<div style={{ background: "#eee", width: "100%", height: "100%" }} />}
      />

      {hasAccessToSeeWatermark && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundImage: `url(${watermarkImageUrl})`,
            backgroundRepeat: "repeat",
            backgroundSize: "100px 100px",
            opacity: 1,
            pointerEvents: "none",
          }}
        />
      )}
    </div>
  );
};

export default ImageComp;
