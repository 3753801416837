import { useInfiniteQuery } from "@tanstack/react-query";
const { default: apiClient } = require("lib/api-client");

const getMissingImages = async ({ pageParam = 1, queryKey }) => {
  const [, { limit, search }] = queryKey;

  const response = await apiClient.post("/api/v1/styles/getMissingImagesStyles", {
    page: pageParam,
    limit,
    searchTerm: search,
  });

  if (response.status !== 200) {
    throw new Error("Error in the fetching data");
  }

  return {
    data: response?.data?.data?.missingImagesData,
    nextPage: response?.data?.data?.currentPage + 1,
    totalPages: response?.data?.data?.totalPages,
    totalCount: response?.data?.data?.totalCount,
  };
};

export const useGetMissingImages = ({ limit, search = "" }) => {
  return useInfiniteQuery({
    queryKey: ["missingImages", { limit, search }],
    queryFn: ({ pageParam = 1 }) => getMissingImages({ pageParam, queryKey: ["missingImages", { limit, search }] }),
    getNextPageParam: (LastPage) => {
      if (LastPage.nextPage <= LastPage.totalPages) {
        return LastPage.nextPage;
      }
      return undefined;
    },
    staleTime: 5 * 60 * 1000,
  });
};
