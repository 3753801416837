import React, { useEffect } from "react";
import { CircularProgress, Grid } from "@mui/material";
import Product from "./Product";
import SortContext from "context/SortContext";
import { useFilter } from "context/FilterContext";
import { useDesings } from "../api/get-desings";
import { useLocation, useNavigate } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { transformData } from "utils/transformdata";
import ProductSkeleton from "components/ui/Skeleton/ProductSkeleton";
import GoToTop from "components/ui/GoToTop";
import { ReactComponent as Background } from "assets/icons/bg.svg";
import debounce from "lodash.debounce";
import InfiniteScroll from "react-infinite-scroll-component";
import { useGlobalContext } from "context/GlobalContext";
import { formatString } from "utils/encodeDecodeStr";
import { usePermissions } from "context/PermissionsContext";
import { findSpecificPage, findSpecificSubPage, hasAccessSubPage } from "utils/checkUserRigths";

const Products = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const permission = usePermissions();

  const { selectedSortValue, sortOptions, search } = React.useContext(SortContext);
  const selectedOption = sortOptions.find((option) => option.id == selectedSortValue);
  const selectedSortBY = selectedOption?.sortBy;
  const selectedSortOrder = selectedOption?.order;
  const { filterValues } = useFilter();
  const { setEditCatagoryBtn, setEditCatagoryBtnOption, setIsEditCatagorySelectValue, setIsEditCatagoryData } = useGlobalContext();

  const hasAccessToDesigeClass = hasAccessSubPage(findSpecificSubPage(findSpecificPage("Category", permission), "Design Class"), "view");
  const hasAccessToMoveSubCategory = hasAccessSubPage(findSpecificSubPage(findSpecificPage("Category", permission), "Move Sub-Category"), "view");
  const hasAccessToMakeType = hasAccessSubPage(findSpecificSubPage(findSpecificPage("Category", permission), "Make Type"), "view");
  const hasAccessToRestrictDesigns = hasAccessSubPage(findSpecificSubPage(findSpecificPage("Category", permission), "Restrict Design"), "view");
  
  const labourValues = filterValues?.Labour?.map((item) => item.split(" - ")[0]).filter(Boolean);
  const classType = filterValues?.Class;
  const diamondPieces = filterValues && filterValues["Diamond Pieces"];
  const diamondWeight = filterValues && filterValues["Diamond Weight"];
  const goldGrossWeight = filterValues && filterValues["Gold Gross-Weight"];
  const goldNetWeight = filterValues && filterValues["Gold Net-Weight"];
  const designInCollections = filterValues && filterValues["Design In Collection"];
  const designInSets = filterValues && filterValues["Design In Sets"];
  const bestSeller = filterValues && filterValues["Best Seller Design"];
  const highestSeller = filterValues && filterValues["Highest Seller"];

  // Extract subcategory  name from the pathname if location.state is null
  const subCategoryNameFromPath = location.pathname.split("/")[2];

  // Extract subcategory type name from the pathname if location.state is null
  const subCategoryTypeNameFromPath = location.pathname.split("/").pop();

  const categoryName = location.state?.name[0];
  // const subCategoryName = location.state?.name[1];
  const subCategoryName = location.state?.name[1]?.split("-")[0]?.trim();
  const innerChnages = location?.state?.innerChnages ? location?.state?.innerChnages : false;
  const isEditCatagory = location?.state?.isEditCatagory;
  const updateCatagoryData = location?.state?.updateCatagory;

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading, isError, error } = useDesings({
    limit: 30,
    categoryName,
    subCategoryName,
    sortBy: selectedSortBY,
    sortOrder: selectedSortOrder,
    labour: labourValues,
    classType: classType,
    diamondPieces,
    diamondWeight,
    goldGrossWeight,
    goldNetWeight,
    designInCollections,
    designInSets,
    search,
    bestSeller,
    highestSeller,
  });

  useEffect(() => {
    if (!innerChnages) {
      setEditCatagoryBtn(true);
      setEditCatagoryBtnOption([
        { option: "Design Class", updateHeading: "Update Design Class", view: hasAccessToDesigeClass },
        { option: "Move Sub-Category", updateHeading: "Update Sub-category", view: hasAccessToMoveSubCategory },
        // { option: "Make Type", updateHeading: "Update Make Type", view: hasAccessToMakeType },
        { option: "Restrict Designs", updateHeading: "Restrict Design", view: hasAccessToRestrictDesigns },
      ]);
    }

    if (isEditCatagory) {
      setIsEditCatagoryData(JSON.parse(sessionStorage.getItem(updateCatagoryData)));
    }

    return () => {
      setEditCatagoryBtn(false);
      setEditCatagoryBtnOption([]);
      setIsEditCatagorySelectValue(false);
      setIsEditCatagoryData([]);
    };
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [selectedOption, filterValues]);

  // Update location.state if it's null
  useEffect(() => {
    let totalDesignCount = data?.pages[0]?.totalCount;
    if (!location.state?.name || location.state.name[1].includes("undefined")) {
      navigate(location.pathname, { state: { name: [formatString(subCategoryNameFromPath), `${formatString(subCategoryTypeNameFromPath)} - ${totalDesignCount}`] }, replace: true });
    }
  }, [data]);

  if (isLoading) return <ProductSkeleton count={10} />;

  if (isError) return <div>Error: {error.message}</div>;

  return (
    <>
      <InfiniteScroll
        dataLength={data?.pages.flatMap((page) => page.designs).length || 0} // Total items loaded
        next={fetchNextPage} // Fetch the next page of designs
        hasMore={!!hasNextPage} // Whether there are more pages to load
        loader={isFetchingNextPage && <CircularProgress sx={{ display: "flex", justifyContent: "center", margin: "20px auto" }} />}
        scrollThreshold={0.8} // Trigger next page when 80% scrolled
        endMessage={<p style={{ textAlign: "center" }}>You have seen it all!</p>}
      >
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          sx={{
            flex: "1 1 1",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: "30px",
            rowGap: "32px",
          }}
          className="Grid-padding"
        >
          {data?.pages?.map((page) =>
            page.designs.map((design) => {
              const transformedProduct = transformData(design);
              return <Product product={transformedProduct} key={design._id} />;
            })
          )}
        </Grid>
      </InfiniteScroll>

      <GoToTop />

      {data?.pages?.length === 0 ||
        (!data?.pages.some((page) => page?.designs?.length > 0) && (
          <div
            style={{
              display: "flex",
              textAlign: "center",
              margin: "auto",
              padding: "auto",
              justifyContent: "center",
              alignItems: "center",
              height: "78vh",
            }}
          >
            <Background />
          </div>
        ))}
    </>
  );
};

export default Products;
