import React, { useState } from "react";
import Modal from "../../../../components/ui/Modal";
import { Box, Grid, Input, OutlinedInput } from "@mui/material";
import { useSettingContext } from "context/SettingContext";
import Button from "../../../../components/ui/Button";
import { ToastContainer, toast } from "react-toastify";

function CollectionEditModal({ open, setOpen, currentCollectionData, setCurrentCollectionData, handleUpdateCollection, collectionData }) {
  const [isInputValue, setIsInputValue] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleRoleNameChange = (event) => {
    const inputValue = event.target.value;
    const trimmedValue = inputValue.trim();

    if (trimmedValue !== "") {
      setIsInputValue(true);
    } else {
      setIsInputValue(false);
    }

    setCurrentCollectionData((prev) => ({
      ...prev,
      collectionName: inputValue,
    }));
  };

  const handleUpdateCollectionSave = () => {
    handleUpdateCollection(currentCollectionData);
  };

  //   const handleSaveRole = async () => {
  //     const existingRoles = [];
  //     // Check if the entered role name already exists
  //     const roleExists = existingRoles.data.some((role) => role.roleName === roleName);

  //     const deletedRoles = JSON.parse(localStorage.getItem("deletedRoles")) || [];
  //     const updatedRoles = JSON.parse(localStorage.getItem("updatedRoles")) || [];

  //     if (roleExists) {
  //       setOpen(false);
  //       //   setCreateRole(false);
  //       setRoleName("");
  //       setIsInputValue(false);
  //       if (deletedRoles.length > 0 || updatedRoles.length > 0) {
  //         setIsUpdate(true);
  //       } else {
  //         setIsUpdate(false);
  //       }

  //       toast.error("Role with this name already exists!", {
  //         autoClose: 3000,
  //         hideProgressBar: false,
  //       });
  //       return;
  //     }

  //     // Proceed with creating or updating the role
  //     if (isUpdate) {
  //       handleTempUpdatedRole(roleName);
  //     } else {
  //       handleCreateRole(roleName);
  //     }

  //     // Close modal and reset form fields
  //     setOpen(false);
  //     setRoleName("");
  //     setIsInputValue(false);
  //   };

  return (
    <Modal open={open} handleClose={handleClose} style={{ width: "438px", height: "304px", bgcolor: "#fff" }}>
      <Grid container justifyContent={"center"} gap={"30px"}>
        <Grid item width="100%">
          <h7 className="tray-heading" style={{ fontSize: "23.5px", fontWeight: "500", fontFamily: "Poppins", textAlign: "center", margin: "0", color: "black", padding: "0" }}>
            {"Edit Collection Name "}
          </h7>
        </Grid>

        <Grid item width="100%">
          <OutlinedInput
            placeholder="Role Name Eg.Manager"
            value={currentCollectionData.collectionName}
            onChange={handleRoleNameChange}
            sx={{
              width: "100%",
              height: "50px",
              borderRadius: "5px",
              border: "1px solid #000",
              color: "#000",
              fontSize: "18px",
              fontFamily: "karla",
              padding: "2px",
              marginBottom: "35px",
              outline: "none",
              border: "1px solid #000000",
              "&:hover .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
            }}
          />
          <Box textAlign={"center"}>
            <Button
              width="50%"
              style={{
                border: "none",
                background: isInputValue ? "#50C878" : "#444444",
                pointerEvents: isInputValue ? "auto" : "none",
                color: "#000",
                width: "214px",
                height: "50px",
                borderRadius: "5px",
                fontFamily: "karla",
                fontSize: "18px",
                fontWeight: "400",
                textTransform: "none",
              }}
              onClick={handleUpdateCollectionSave}
            >
              Save Name
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Modal>
  );
}

export default CollectionEditModal;
