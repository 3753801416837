import { Button } from "@mui/material";
import { SettingContext } from "context/SettingContext";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const Unrestrict = ({ location }) => {
  const navigate = useNavigate();

  const { setRestrictBtn } = useContext(SettingContext);
  
  console.log("location ---->", location);
  
  const handleClick = () => {
    let state = {
      ...location.state,
      innerChnages: true,
      backPath: location.pathname,
      isRestricted: true,
      updateCatagory:"RestrictDesign"
    };
    navigate(location.pathname, { state });
    setRestrictBtn(false);
  };

  const StyledButton = styled(Button)(({ theme, variant, disabled }) => ({
    width: "110px",
    fontFamily: "karla",
    fontWeight: 400,
    fontSize: "16.8px",
    lineHeight: "21.04px",
    height: "41px",
    borderRadius: "5px",
    color: "#FFF",
    border: `1px solid #fff`,
    textTransform: "none",
    backgroundColor: "transparent",

    "&:hover": {
      backgroundColor: "#fff",
      color: "#000",
    },
  }));

  return (
    <div className="child-header-sort">
      <StyledButton onClick={() => handleClick()}>Unrestrict</StyledButton>
    </div>
  );
};

export default Unrestrict;
