import { Box, Button } from '@mui/material';
import { SettingContext } from 'context/SettingContext';
import React, { useContext } from 'react'
import styled from 'styled-components';

const SyncButton = ({ value }) => {

    const {setSyncModal,setRevertAllModal} = useContext(SettingContext)

    const handleClick = () => {
        setSyncModal(true)       
    }

    const handleRevertClick = ()=>{
        setRevertAllModal(true)
    }

    const StyledButton = styled(Button)(({ theme, variant, disabled }) => ({
        width: "fit",
        fontFamily: "karla",
        fontWeight: 400,
        fontSize: "16.8px",
        lineHeight: "21.04px",
        height: "41px",
        borderRadius: "5px",
        color: "#FFF",
        border: `1px solid #fff`,
        textTransform: "none",
        backgroundColor: "transparent",
        whiteSpace: "nowrap",

        "&:hover": {
            backgroundColor: "#fff",
            color: "#000",
        },
    }));

    return (
        <div className="child-header-sort">
            {!value &&
                <Box sx={{
                    marginLeft: {
                        xs: '0',
                        sm:'0',
                        md:'0',
                        lg: "940px"
                    }
                }}>
                    <StyledButton onClick={() => handleClick()}>Sync all</StyledButton>
                </Box>
            }
            {value == "makeType" &&
                <Box sx={{
                    marginLeft: {
                        xs: '0',
                        sm:'0',
                        md:'0',
                        lg: "580px"
                    }, display: 'flex', gap: "32px"
                }}>
                    <StyledButton onClick={() =>{}}>Sync Make type</StyledButton>
                    <StyledButton onClick={() => handleRevertClick()}>Revert all</StyledButton>
                </Box>
            }
            {value == "set" &&
                <Box sx={{
                    marginLeft: {
                        xs: '0',
                        sm:'0',
                        md:'0',
                        lg: "700px"
                    }, display: 'flex', gap: "32px"
                }}>
                    <StyledButton onClick={() => {}}>Sync Set</StyledButton>
                    <StyledButton onClick={() => handleRevertClick()}>Revert all</StyledButton>
                </Box>
            }
            {value == "collection" &&
                <Box sx={{                    
                    marginLeft: {
                        xs: '0',
                        sm:'0',
                        md:'0',
                        lg: "580px"
                    }, display: 'flex', gap:"32px"
                }}>
                    <StyledButton onClick={() =>{}}>Sync Collection</StyledButton>
                    <StyledButton onClick={() => handleRevertClick()}>Revert all</StyledButton>
                </Box>
            }
            {value == "SubCategory" &&
                <Box sx={{
                    marginLeft: {
                        xs: '0',
                        sm:'0',
                        md:'0',
                        lg: "500px"
                    }, display: 'flex', gap:"32px"
                }}>
                    <StyledButton onClick={() =>{}}>Sync Sub-category</StyledButton>
                    <StyledButton onClick={() => handleRevertClick()}>Revert all</StyledButton>
                </Box>
            }
        </div>
    )
}

export default SyncButton
