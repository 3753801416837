import React, { useContext, useEffect, useState } from "react";

import "../../../collection/styles/collectionScreen.css";

import Home from "pages/Home";
import Card from "components/ui/Card";

import { Grid, Typography } from "@mui/material";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import SortContext from "context/SortContext";
import config from "configs/config";
import axios from "axios";
import useGetCollectionCategory from "hooks/useGetCollectionCategory";
import { encodeStr, formatString } from "utils/encodeDecodeStr";
import { GlobalContext, useGlobalContext } from "context/GlobalContext";
import LoadingSmallCard from "components/ui/Skeleton/LoadingSmallCard";
import { transformData } from "utils/transformdata";
import { findSpecificPage, hasAccessPage } from "utils/checkUserRigths";
import { usePermissions } from "context/PermissionsContext";

function SubCollection() {
  const { collectionName } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const catalogUpdate = location?.state?.cataloguUpdate;

  let [subcollectionData, setSubCollectionData] = useState([]);
  const { setSortOptions, setSelectedSortValue, search, selectedSortValue, setIsEdit } = React.useContext(SortContext);
  const { setAddDesignBtn, addDesignBtn, } = useGlobalContext();
  const [loading, setLoading] = useState(true);

  let preState = location.state;
  let collectioCreateOrUpdate = location?.state?.collection;
  const permission = usePermissions();

  const hasAccessEditCollection = hasAccessPage(findSpecificPage("Collection", permission), "edit");

  // useEffect(() => {
  //   const state = window.history.state;
  //   if (state?.imgUrl && state?.styleNumber) {
  //     setImageModal({ isOpen: true, imgUrl: state?.imgUrl, designNo: state?.styleNumber });
  //   }
  // }, []);

  useEffect(() => {
    setSortOptions([
      { id: 1, name: "Alphabetical Order (A to Z) " },
      { id: 2, name: "Alphabetical Order (Z to A)" },
      { id: 3, name: "Design Quantity (High to Low)" },
      { id: 4, name: "Design Quantity (Low to High)" },
    ]);

    if (hasAccessEditCollection) {
      setAddDesignBtn(collectioCreateOrUpdate);
    } else {
      setAddDesignBtn(false);
    }

    return () => {
      setSortOptions([]);
      setSelectedSortValue(null);
      setAddDesignBtn(false);
      sessionStorage.removeItem("collectionsProductsData");
      // sessionStorage.removeItem("collectionIdOfProducts");
    };
  }, [collectioCreateOrUpdate]);

  const payload = {
    pageNumber: 1,
    limit: 100,
    // searchTerm: preState?.name[0] || window?.history?.state?.name[0], //Winter Collection
    searchTerm: preState?.name?.[0] || (window?.history?.state && window.history.state.name ? window.history.state.name[0] : ""),

    sortBy: selectedSortValue == "1" || selectedSortValue == "2" ? "alphabetical" : selectedSortValue == "3" || selectedSortValue == "4" ? "quantity" : "", //alphabetical //quantity
    order: selectedSortValue == "1" || selectedSortValue == "4" ? "asc" : "desc", //asc //desc
  };
  const { data: subcollections, isLoading, error, isError } = useGetCollectionCategory(payload);

  useEffect(() => {
    if (subcollections?.collections && subcollections?.collections.length === 0) {
      navigate("/Collection");
    } else if (subcollections?.collections && Array.isArray(subcollections.collections) && subcollections.collections.length > 0) {
      let presentCategories = subcollections.collections[0]?.categories || [];
      let allCategoryCount = subcollections.collections[0]?.collectionDetails.numberOfStyles || 0;
      let allCategory = { category: "All", count: allCategoryCount };
      setSubCollectionData([allCategory, ...presentCategories]);

      setLoading(false);

      //------------ saving data in session storage -----------\\
      const desingsIds = subcollections?.collections[0]?.designs?.map((desing) => transformData(desing));

      sessionStorage.setItem("collectionsProductsData", JSON.stringify(desingsIds));
      sessionStorage.setItem("collectionIdOfProducts", JSON.stringify(subcollections?.collections[0]?.collectionDetails?._id));
    }
  }, [subcollections, addDesignBtn, navigate]);

  useEffect(() => {
    if (subcollectionData.length > 0) {
      setLoading(false);
    }
  }, [subcollectionData]);

  const handleClick = (ele) => {
    const encodedCollectionName = encodeURIComponent(collectionName);
    const encodedCollectionCategoryName = encodeURIComponent(`${ele.category}-${ele.count}`);

    const state = { ...preState, name: [`${preState?.name[0] || window.history.state.name[0]}`, `${ele.category} - ${ele.count}`] };
    navigate(`/Collection/${encodedCollectionName}/${encodedCollectionCategoryName}`, { state });
  };

  //-----------------When User copy paste URL --------------------\\

  // const collectionName = JSON.parse(localStorage.getItem("collectionNameForPath"));

  useEffect(() => {
    const decodedName = decodeURIComponent(collectionName);
    if (!location.state?.name) {
      navigate(location.pathname, { state: { name: [decodedName] }, replace: true });
    }
  }, []);

  if (isLoading) return <LoadingSmallCard count={24} />;
  if (isError) return <div>Error: {error.message}</div>;

  return (
    <>
      {!loading && subcollectionData && subcollectionData.length > 0 ? (
        <Grid
          container
          className="Grid-padding"
          direction="row"
          justifyContent="flex-start"
          sx={{ flex: "1 1 1", display: "flex", alignItems: "center", justifyContent: "flex-start", gap: "30px", rowGap: "32px" }}
        >
          {subcollectionData &&
            subcollectionData.map((ele, i) => (
              <Grid item key={i} onClick={() => handleClick(ele)}>
                <Card style={{ minWidth: 275, maxWidth: "317px", height: 62, borderRadius: "5px" }}>
                  <Typography mr={"auto"} fontSize={"20px"} fontWeight={400} fontFamily={"Karla"}>
                    {ele.category}
                  </Typography>
                  <Typography ml={"auto"} fontSize={"20px"} fontWeight={400} fontFamily={"Karla"}>
                    {ele.count}
                  </Typography>
                </Card>
              </Grid>
            ))}
        </Grid>
      ) : (
        <Home />
      )}
    </>
  );
}

export default SubCollection;
