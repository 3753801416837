import axios from "axios";
import config from "configs/config";
import apiClient from "lib/api-client";

//--------------- Save Rights ------------------------\\

export const getRestrictedCategories = async () => {
  let response = await apiClient.post(`${config.baseUrl}/api/v1/styles/getRestrictedCategories`);
  return response.data;
};

export const getRestrictedSubCategories = async (payload) => {
  let response = await apiClient.post(`${config.baseUrl}/api/v1/styles/getRestrictedSubCategories`, payload);
  return response.data;
};

// http://localhost:8000/api/v1/styles/getStylesBySubCategory
