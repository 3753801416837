import React, { useContext } from 'react';
import Card from '../components/Card';
import { Grid } from '@mui/material';
import { SettingContext } from 'context/SettingContext';
import { useEffect } from 'react';
import SingleSyncModal from 'components/ui/RemoveModal/RemoveModal';
import { useState } from 'react';

export default function SubCategoryPage() {
    const { setCategorySyncButton, setSingleRevertModal, singleRevertModal } = useContext(SettingContext);

    const [currentSelctelement, setCurrentSelctelement] = useState(null);

    const CardData = [
        { id: 1, Name: "NS-004101", FromText: "Chain Necklace", ToText: "Peal Necklace" },
        { id: 2, Name: "NS-004102", FromText: "Chain Necklace", ToText: "Peal Necklace" },
        { id: 3, Name: "NS-004103", FromText: "Chain Necklace", ToText: "Peal Necklace" },
        { id: 4, Name: "NS-004104", FromText: "Chain Necklace", ToText: "Peal Necklace" },
        { id: 5, Name: "NS-004105", FromText: "Chain Necklace", ToText: "Peal Necklace" },
        { id: 6, Name: "NS-004106", FromText: "Chain Necklace", ToText: "Peal Necklace" },
    ]

    const SingleSyncBtnInfo = React.useMemo(
        () => [
            {
                btnName: "Discard",
                bgColor: "#E77B7B",
                click: () => setSingleRevertModal(false),
            },
            {
                btnName: "Revert",
                bgColor: "#50C878",
                click: () => "",
            },
        ],
        []
    );

    const handleClick = (data) => {
        setSingleRevertModal(true)
        setCurrentSelctelement(data?.Name)
    }

    useEffect(() => {
        setCategorySyncButton(true);
        return () => {
            setCategorySyncButton(false);
        }
    }, [])

    return (
        <>
            <Grid container spacing={4}>
                {CardData.map((data, index) => (
                    <Grid item xs={12} md={6} lg={4}>
                        <Card data={data} handleClick={handleClick} />
                    </Grid>
                ))}
            </Grid>

            {/* modal*/}
            <SingleSyncModal open={singleRevertModal} setOpen={setSingleRevertModal} btnInfo={SingleSyncBtnInfo} heading={`Are you sure you want to revert ${currentSelctelement}?`} />

        </>

    );
}
