import { Grid, Skeleton } from "@mui/material";
import React from "react";

const LoadingSmallCard = ({ count }) => {
  return (
    <Grid
      container
      className="Grid-padding"
      direction="row"
      justifyContent="flex-start"
      sx={{ flex: "1 1 1", display: "flex", alignItems: "center", justifyContent: "flex-start", gap: "30px", rowGap: "32px" }}
    >
      {Array.from(new Array(count)).map((_, index) => (
        <Grid item key={index}>
          <div style={{ minWidth: 275, maxWidth: "317px", height: 62, borderRadius: "5px" }}>
            <Skeleton variant="rectangular" height={62} borderRadius={"5px"} />
          </div>
        </Grid>
      ))}
    </Grid>
  );
};

export default LoadingSmallCard;
