import { useInfiniteQuery } from "@tanstack/react-query";
const { default: apiClient } = require("lib/api-client");

const fetchDesigns = async ({ pageParam = 1, queryKey }) => {
  const [
    ,
    {
      categoryName,
      subCategoryName,
      sortBy,
      sortOrder,
      search,
      labour,
      classType,
      diamondPieces,
      diamondWeight,
      goldGrossWeight,
      goldNetWeight,
      designInCollections,
      designInSets,
      limit,
      bestSeller,
      highestSeller,
      isRestric,
    },
  ] = queryKey;

  const response = await apiClient.post("/api/v1/styles/getStylesBySubCategory", {
    categoryName,
    subCategoryName: subCategoryName === "Blank" ? "null" : subCategoryName,
    page: pageParam,
    limit,
    sortBy,
    sortOrder,
    styleNumber: search,
    labour: labour?.length > 0 ? labour : "",
    classType: classType?.length > 0 ? classType : "",
    diamondPieces: diamondPieces?.length > 0 ? diamondPieces : "",
    diamondWeight: diamondWeight?.length > 0 ? diamondWeight : "",
    goldGrossWeight: goldGrossWeight?.length > 0 ? goldGrossWeight : "",
    goldNetWeight: goldNetWeight?.length > 0 ? goldNetWeight : "",
    designInCollections: designInCollections[0] === "No" ? "NO" : designInCollections[0] === "Yes" ? "YES" : "",
    designInSets: designInSets[0] === "No" ? "NO" : designInSets[0] === "Yes" ? "YES" : "",
    bestSeller: bestSeller[0] === "No" ? "NO" : bestSeller[0] === "Yes" ? "YES" : "",
    highestSeller: highestSeller?.length > 0 ? highestSeller : "",
    isRestric,
  });

  if (response.status !== 200) {
    throw new Error("Error fetching categories");
  }

  return {
    designs: response.data.styles,
    nextPage: response.data.pagination.currentPage + 1,
    totalPages: response.data.pagination.totalPages,
    totalCount: response.data.totalCount,
  };
};

export const useDesings = ({
  categoryName = "",
  subCategoryName = "",
  sortBy = "Design Date",
  sortOrder = "asc",
  search = "",
  labour = "",
  classType = "",
  diamondPieces = "",
  diamondWeight = "",
  goldGrossWeight = "",
  goldNetWeight = "",
  designInCollections = "",
  designInSets = "",
  bestSeller = "",
  highestSeller = "",
  limit = 10,
  isRestric = "",
}) => {
  return useInfiniteQuery({
    queryKey: [
      "designs",
      {
        categoryName,
        subCategoryName,
        sortBy,
        sortOrder,
        search,
        labour,
        classType,
        diamondPieces,
        diamondWeight,
        goldGrossWeight,
        goldNetWeight,
        designInCollections,
        designInSets,
        limit,
        bestSeller,
        highestSeller,
        isRestric,
      },
    ],

    queryFn: ({ pageParam = 1 }) =>
      fetchDesigns({
        pageParam,
        queryKey: [
          "designs",
          {
            categoryName,
            subCategoryName,
            sortBy,
            sortOrder,
            search,
            labour,
            classType,
            diamondPieces,
            diamondWeight,
            goldGrossWeight,
            goldNetWeight,
            designInCollections,
            designInSets,
            limit,
            bestSeller,
            highestSeller,
            isRestric,
          },
        ],
      }),
    getNextPageParam: (lastPage) => {
      if (lastPage.nextPage <= lastPage.totalPages) {
        return lastPage.nextPage;
      }
      return undefined;
    },
    staleTime: 5 * 60 * 1000,
    retry: 1,
  });
};
