import { useMutation, useQueryClient } from "@tanstack/react-query";

import { useGlobalContext } from "context/GlobalContext";
import apiClient from "lib/api-client";
import request from "lib/axio-utilis";
import { useNavigate } from "react-router-dom";
import { toast as myToast } from "react-toastify";

const editCatagory = (data) => {
  return request({ url: "/api/v1/styles/dyanmicEditCategory", method: "post", data: data });
};

export const useEditCatagory = ({ onCompleted }) => {
  const quaryClient = useQueryClient();
  return useMutation({
    mutationFn: editCatagory,
    onSuccess: (data) => {
      if (data?.data?.status === 200) {
        quaryClient.removeQueries("designs");
        onCompleted();
        myToast.success(data?.data?.message || "Styles updated successfully!", {
          position: "top-right",
          autoClose: 2000,
        });
      } else {
        myToast.error(`Error: ${data?.data?.message || "An unexpected error occurred"}`, {
          position: "top-right",
          autoClose: 2000,
        });
      }
    },
    onError: (error) => {
      myToast.error(`Error: ${error?.response?.data?.message || error.message || "An unexpected error occurred"}`, {
        position: "top-right",
        autoClose: 2000,
      });
    },
  });
};
