import { CircularProgress, Grid } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { ReactComponent as Background } from "assets/icons/bg.svg";

import CatalogueCard from "./CatalogueCard";
import CatalogueShareLinkModule from "./CatalogueShareLinkModuleTwo";
import { useNavigate } from "react-router-dom";
import SortContext from "context/SortContext";
import { useGlobalContext } from "context/GlobalContext";
import CatalogueCardSkeleton from "./CatalogueCardSkeleton";
import { returnCatalogCard } from "utils/returnCatalogCard";
import { useGetAllCatalogs } from "../hooks/useGetAllCatalog";
import { useInView } from "react-intersection-observer";
import { toast } from "react-toastify";
import { usePermissions } from "context/PermissionsContext";
import { findSpecificPage, hasAccessPage } from "utils/checkUserRigths";
import CatalogueAddAndEditClientDetails from "./Settings/Activity/componets/CatalogueAddAndEditClientDetails";
import { useCreateCatalogClient } from "./Settings/Activity/hooks/useCreateCatalogClient";

function CatalogueScreen() {
  const [shareLinkOpen, setShareLinkOpen] = useState(false);
  const { setSortOptions, setSelectedSortValue, search, selectedSortValue, sortOptions, setSearch } = React.useContext(SortContext);
  const { setCreateBtn, setCreateBtnName, setCreateBtnOption, setCountCart, setIsArchive, setCheckCatalogBeforeNavigate } = useGlobalContext();
  const [currentCatalogueId, setCurrentCatalogueId] = useState(null);
  const [catalogueData, setCatalogueData] = useState([]);
  const { ref, inView } = useInView({
    threshold: 1.0,
  });

  const sort = sortOptions.find((item) => item.id == selectedSortValue)?.type || "catalogDateDesc";
  const permission = usePermissions();
  const hasAccessAddCatalog = hasAccessPage(findSpecificPage("Catalog", permission), "add");
  const navigate = useNavigate();
  const [userShareLinkData, setUserShareLinkData] = useState(JSON.parse(sessionStorage.getItem("userShareLinkData")) || []);
  const { mutate: createCatalogClient, isError: isclientAddError, isSuccess: isclientAddSuccess } = useCreateCatalogClient();

  const handleCardClick = (itemId) => {
    let catalogWithProduct = { itemId };

    let countLimit = itemId?.iconsData[0]?.count;
    sessionStorage.setItem("countLimit", JSON.stringify(countLimit));
    sessionStorage.setItem("catalogWithProduct", JSON.stringify(catalogWithProduct));
    sessionStorage.setItem("updateProductsData", JSON.stringify([]));
    console.log("Checkkkkkkkkk----->", `${itemId.number} (${itemId.subname})`);
    let catalogNumberAndName = encodeURIComponent(`${itemId.number} (${itemId.subname})`);

    const state = {
      name: [`${itemId.number} (${itemId.subname})`],
      edit: true,
      subName: "Edit",
      catalogueName: `${itemId.subname}`,
      isActivity: false,
    };
    setIsArchive(false);

    setCheckCatalogBeforeNavigate(false);
    navigate(`/Catalog/${itemId.id}?catalogNumberAndName=${catalogNumberAndName}`, { state });
  };

  const handleActivityClick = (itemId) => {
    const state = {
      name: [`Activity - ${itemId.number} (${itemId.subname})`],
      isActivity: true,
    };
    navigate(`/Catalog/${itemId.id}`, { state });
  };

  const handlCatalogueShareLink = (item) => {
    const newMap = new Map(catalogueData.map((item) => [item.id, item]));
    if (newMap.has(currentCatalogueId)) {
      let updateCatalgue = newMap.get(currentCatalogueId);
      updateCatalgue.iconsData = updateCatalgue.iconsData.map((ele, index) => {
        if (index == 1) {
          return {
            ...ele,
            count: ele.count + 1,
          };
        }
        return ele;
      });

      newMap.set(currentCatalogueId, updateCatalgue);
    }

    const updatedCatalogueData = Array.from(newMap.values());
    setCatalogueData(updatedCatalogueData);
  };

  const handleNewAccesUserAdd = (newUserData) => {
    let newData = {
      catalogId: currentCatalogueId?.id,
      clientNumber: newUserData.mobileNumber,
      clientName: newUserData.clientName,
      sessionDuration: newUserData.hours,
    };
    createCatalogClient(newData);
    // setShareLinkOpen(false);
  };

  const handleCatalogSetting = (catalogId) => {
    const state = {
      catalogId: catalogId.id,
      catalogName: [`${catalogId.number} ( ${catalogId.subname} )`],
    };
    navigate(`/CatalogSetting/${catalogId.id}`, { state });
  };

  const handleButtonClick = (itemId, btnTilte, e) => {
    e.stopPropagation();
    if (btnTilte === "Share Catalog") {
      setShareLinkOpen(true);
      setCurrentCatalogueId(itemId);
      // console.log("Share Catalogue Button Clike With Catelog Id id", itemId);
    } else if (btnTilte === "ActiveLog") {
      // console.log("Active Log Button Clike With Catelog Id ", itemId);
      handleActivityClick(itemId);
    } else if (btnTilte === "EditCatalogue") {
      // console.log("Edit Catalogue Button Clike With Catelog Id ", itemId);
      handleCardClick(itemId);
    } else {
      handleCatalogSetting(itemId);
      // console.log("Edit Catalogue Button Clike With Catelog Id ", itemId);
    }
  };

  const { data, isLoading, fetchNextPage, hasNextPage, isError, isFetching, isFetchingNextPage, error } = useGetAllCatalogs({
    limit: 10,
    sort: sort,
    search: search || "",
    isArchivefilter: false,
  });

  useEffect(() => {
    setSearch("");
    setSortOptions([
      { id: 1, name: "Created By Me", type: "createdByMe" },
      { id: 2, name: "Design Quantity (Low to High)", type: "designNoAsc" },
      { id: 3, name: "Design Quantity (High to Low)", type: "designNoDesc" },
      { id: 4, name: "Catalog Date (Oldest to Newest)", type: "catalogDateAsc" },
      { id: 5, name: "Catalog Date (Newest to Oldest)", type: "catalogDateDesc" },
      { id: 6, name: "Catalog Views (Low to High)", type: "catalogViewsAsc" },
      { id: 7, name: "Catalog Views (High to Low)", type: "catalogViewsDesc" },
    ]);

    return () => {
      setSortOptions([]);
      setSelectedSortValue(null);
      setSearch("");
    };
  }, []);

  useEffect(() => {
    setCreateBtn(hasAccessAddCatalog);
    setCreateBtnName("Create Catalog");
    setCreateBtnOption([
      { option: "By Category", link: "/Category", view: hasAccessPage(findSpecificPage("Category", permission), "view") },
      { option: "By Collection", link: "/Collection", view: hasAccessPage(findSpecificPage("Collection", permission), "view") },
      { option: "By Set", link: "/Sets", view: hasAccessPage(findSpecificPage("Sets", permission), "view") },
    ]);

    return () => {
      setCreateBtn(false);
      setCreateBtnName("");
      setCreateBtnOption([]);
    };
  }, []);

  useEffect(() => {
    sessionStorage.removeItem("updateProductsData");
    sessionStorage.removeItem("catalogWithProduct");
    sessionStorage.removeItem("countLimit");
    sessionStorage.removeItem("CartData");
    setCountCart(0);
  }, []);

  const debouncedFetchNextPage = useCallback(() => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, isFetchingNextPage]);

  useEffect(() => {
    if (inView) {
      debouncedFetchNextPage();
    }
  }, [inView, hasNextPage, isFetchingNextPage, fetchNextPage]);

  const isCatalogProductPresent = (data) => {
    if (data && data.pages && data.pages.length > 0) {
      return data.pages.some((page) => page.catalogs && page.catalogs.length > 0);
    }
    return false;
  };

  if (isError) {
    toast.error(`${error}`, {
      position: "top-right",
      autoClose: 2000,
    });
  }

  if (isLoading) {
    return (
      <Grid
        container
        className="Grid-padding"
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        sx={{ flex: "1 1 1", display: "flex", alignItems: "center", justifyContent: "flex-start", gap: "30px", rowGap: "32px" }}
      >
        {Array.from({ length: 4 }, (_, index) => (
          <CatalogueCardSkeleton key={index} item={{ id: index }} />
        ))}
      </Grid>
    );
  }

  return (
    <>
      {isCatalogProductPresent(data) ? (
        <>
          <Grid
            container
            className="Grid-padding"
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            sx={{ flex: "1 1 1", display: "flex", alignItems: "center", justifyContent: "flex-start", gap: "32px", rowGap: "32px" }}
          >
            {data?.pages?.map((page, pageIndex) => (
              <React.Fragment key={pageIndex}>
                {page?.catalogs?.map((item) => {
                  const newItem = returnCatalogCard(item);
                  return <CatalogueCard key={newItem.id} item={newItem} handleButtonClick={handleButtonClick} />;
                })}
              </React.Fragment>
            ))}
            {isFetchingNextPage ? Array.from({ length: 4 }, (_, index) => <CatalogueCardSkeleton key={index} item={{ id: index }} />) : ""}
            <div ref={ref} />
          </Grid>
          {/* <div style={{ color: "red" }}>{isFetching && !isFetchingNextPage ? <CircularProgress color="secondary" /> : null}</div> */}
          {/* {shareLinkOpen && <CatalogueShareLinkModule open={shareLinkOpen} setOpen={setShareLinkOpen} handleUpdateCatalogueShareLinkInfo={handlCatalogueShareLink} />} */}
          {<CatalogueAddAndEditClientDetails open={shareLinkOpen} setOpen={setShareLinkOpen} handleRef={handleNewAccesUserAdd} isError={isclientAddError} isSuccess={isclientAddSuccess} />}
        </>
      ) : (
        <div
          style={{
            display: "flex",
            textAlign: "center",
            margin: "auto",
            padding: "auto",
            justifyContent: "center",
            alignItems: "center",
            height: "70vh",
          }}
        >
          <Background />
        </div>
      )}
    </>
  );
}

export default CatalogueScreen;
