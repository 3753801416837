import React from "react";
import { Toolbar, InputBase, IconButton, Badge } from "@mui/material";
import CartIcon from "components/Icons/CartIcon";
import NotificationIcon from "components/Icons/NotificationIcon";
import { ReactComponent as LogoutIcon } from "assets/icons/logout.svg";
import { styled } from "@mui/material/styles";
import { useLocation, useNavigate } from "react-router-dom";
import RemoveModal from "components/ui/RemoveModal/RemoveModal";
import { useSettingContext } from "context/SettingContext";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: "#FFFFFF",
  "&:hover": {
    backgroundColor: "#f0f0f0",
  },
  marginLeft: 0,
  width: "100%",
  boxShadow: "inset 0px 4px 20px 0px #00000040",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "black",
  height: "40px",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 1),
    transition: theme.transitions.create("width"),

    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "40ch",
    },
  },
}));

const SettingHeader = () => {
  const [logoutModal, setLogoutModal] = React.useState(false);
  const { setSearch } = useSettingContext();
  const location = useLocation();
  const path = location.pathname;
  const navigate = useNavigate();

  function handlelogout() {
    sessionStorage.removeItem("user");
    sessionStorage.removeItem("userData");
    localStorage.removeItem("userData");
    navigate("/login");
  }

  const modalBtnInfo = React.useMemo(
    () => [
      {
        btnName: "log out",
        bgColor: "#E77B7B",
        click: handlelogout,
      },
      {
        btnName: "Keep",
        bgColor: "#50C878",
        click: () => setLogoutModal(false),
      },
    ],
    []
  );

  const renderHeader = () => {
    switch (true) {
      case path.includes("/missing-images"):
        return (
          <div position="fixed" elevation={0} sx={{ backgroundColor: "#000" }}>
            <Toolbar sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center", gap: "32px" }}>
              <Search>
                <StyledInputBase placeholder="Search" inputProps={{ "aria-label": "search" }} sx={{ height: "40px", borderRadius: "5px" }} onChange={(e) => setSearch(e.target.value)} />
              </Search>

              <IconButton size="large" edge="end" color="success" disableRipple disableFocusRipple>
                <Badge color="success">
                  <CartIcon color="white" />
                </Badge>
              </IconButton>

              <IconButton size="large" color="success" disableRipple disableFocusRipple>
                <Badge color="success">
                  <NotificationIcon color="white" />
                </Badge>
              </IconButton>

              <IconButton size="large" edge="end" color="success" disableRipple disableFocusRipple onClick={() => setLogoutModal(true)}>
                <LogoutIcon />
              </IconButton>
            </Toolbar>
          </div>
        );

      case "test":
        return <h1>Test</h1>;

      default:
        return (
          <div position="fixed" elevation={0} sx={{ backgroundColor: "#000" }}>
            <Toolbar></Toolbar>
          </div>
        );
    }
  };

  return (
    <>
      {renderHeader()}
      <RemoveModal open={logoutModal} setOpen={setLogoutModal} btnInfo={modalBtnInfo} heading="Are you sure you want to log out?" />
    </>
  );
};

export default SettingHeader;
