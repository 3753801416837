import React, { useEffect, useState } from "react";
import { Typography, Button, Checkbox, IconButton, Badge } from "@mui/material";
import Image from "assets/img/default-img.jpg";
import Box from "@mui/material/Box";
import Card from "components/ui/Card";
import { useGlobalContext } from "context/GlobalContext";
import { getIcon } from "utils/iconsConfig";
import ViewDetails from "./ViewDetails/ViewDetails";
import ImageModal from "./ImageModal";
import Tooltip from "./Tooltip";
import BestSellerModal from "./BestSellerModal/BestSellerModal";
import SaleDetailsModal from "./SaleDetailsModal/SaleDetailsModal";
import { usePermissions } from "context/PermissionsContext";
import { findSpecificPage, findSpecificSubPage, hasAccessSubPage } from "utils/checkUserRigths";
import SmallImage from "./SmallImage";

const ProductCard = ({
  data,
  addtoCart,
  cardStyle = { borderRadius: "10px", padding: "20px" },
  btnTilte,
  isBtn,
  selectedCartProducts,
  removeCart,
  hoverEffect,
  checkedItems,
  handleCheckboxChange,
  variant = "default",
  presentInCatalog,
  style,
  btnWidth,
  handleShowDetails,
  handleHideDetails,
  handleViewCartDetails,
  updateCatagoryData,
}) => {
  const { setCountCart, setIsEditCatagoryData } = useGlobalContext();

  const [details, setDetails] = useState([]);
  const [collectionModal, setCollectionModal] = useState(false);
  const [setModal, setSetModal] = useState(false);
  const [bestSellerModal, setBestSellerModal] = useState(false);
  const [salesDetailsModal, setSaleDetailModal] = useState(false);
const [imageModal, setImageModal] = useState({ isOpen: false, imgUrl: null, designNo: null });
  //---------------- Rights Access start ---------------------\\

  const permission = usePermissions();

  const hasAccessToSeeSales = hasAccessSubPage(findSpecificSubPage(findSpecificPage("Settings", permission), "Sales"), "view");
  const hasAccessToSeeCreationDate = hasAccessSubPage(findSpecificSubPage(findSpecificPage("Settings", permission), "Design Date"), "view");
  const hasAccessToSeeBestSeller = hasAccessSubPage(findSpecificSubPage(findSpecificPage("Settings", permission), "Best Seller Border"), "view");
  const hasAccessToSeeHighestSeller = hasAccessSubPage(findSpecificSubPage(findSpecificPage("Settings", permission), "Highest Seller Trophy"), "view");
  const hasAccessToSeeWatermark = hasAccessSubPage(findSpecificSubPage(findSpecificPage("Settings", permission, "Watermark"), "Watermark"), "view");
  //---------------- Rights Access End -------------------------\\

  const handleOpenImageModal = React.useCallback((imgUrl, designNo) => {
    setImageModal({ isOpen: true, imgUrl, designNo });
  }, []);

  const handleCloseImageModal = React.useCallback(() => {
    setImageModal({ isOpen: false, imgUrl: null, designNo: null });
  }, []);

  //end img modal code

  const handleAddToCartBtn = React.useCallback(() => {
    let getCartData = JSON.parse(sessionStorage.getItem("CartData")) || [];
    getCartData.push(selectedCartProducts);
    sessionStorage.setItem("CartData", JSON.stringify(getCartData));
    setCountCart((prevCount) => prevCount + 1);
    addtoCart();
  }, [addtoCart, selectedCartProducts, setCountCart]);

  const handleAddEditCatagoryData = React.useCallback(() => {
    let getUpdateCatagoryData = JSON.parse(sessionStorage.getItem(updateCatagoryData)) || [];
    getUpdateCatagoryData.push({
      _id: selectedCartProducts?._id || selectedCartProducts?.id,
      styleNumber: selectedCartProducts?.styleNumber,
    });
    sessionStorage.setItem(updateCatagoryData, JSON.stringify(getUpdateCatagoryData));
    setIsEditCatagoryData(JSON.parse(sessionStorage.getItem(updateCatagoryData)) || []);
    addtoCart();
  }, [addtoCart, selectedCartProducts, updateCatagoryData]);

  const handleBestSeller = React.useCallback(() => {
    setBestSellerModal(true);
  }, [setBestSellerModal]);

  //showDetails.
  const showDetails = React.useMemo(
    () => [
      {
        left: [
          {
            label: data?.styleNumber
              .split("-")
              ?.map((part, i) => (i === 1 && part?.length > 5 ? part.slice(0, 5) + "..." : part))
              .join("-"),
            Icon: getIcon(data?.designClass, variant),
            tooltipTitle: null,
            access: true,
          },
          { label: data?.diamond, Icon: getIcon("diamond", variant), tooltipTitle: "Diamond Weight / Diamond Pieces", access: true },
          { label: data?.weight, Icon: getIcon("goldbar", variant), tooltipTitle: "Gold Net Weight / Gold Gross Weight", access: true },
          // { label: data.labour, Icon: getIcon("labour", variant), tooltipTitle: "Make Type" },
          {
            label: data?.sales,
            Icon: getIcon("sales", variant),
            tooltipTitle: "Sales",
            click: data?.sales > 0 ? () => setSaleDetailModal(true) : null,
            access: hasAccessToSeeSales,
          },
          {
            label: data?.date,
            Icon: getIcon("date", variant),
            tooltipTitle: "Design Creation Date",
            access: hasAccessToSeeCreationDate,
          },
        ],
      },
      {
        middle: [
          {
            label: data?.collectionDetails && data?.collectionDetails.length > 0 ? "collectionDetails" : null,
            Icon: getIcon("collection", variant),
            click: () => setCollectionModal(true),
            tooltipTitle: "Collections",
            access: true,
          },
          { label: data?.setDetails && data?.setDetails.length > 0 ? "setDetails" : null, Icon: getIcon("set", variant), click: () => setSetModal(true), tooltipTitle: "Sets", access: true },
          // { label: data?.trendUp, Icon: getIcon("trendUp", variant) ,tooltipTitle: "Trend"},
          {
            label: data?.cup ? "cup" : null,
            Icon: getIcon(data?.cup, variant),
            click: handleBestSeller,
            tooltipTitle: "Highest Seller",
            badge: data?.totalTrophy,
            access: hasAccessToSeeHighestSeller,
          },
          { label: data?.labour, Icon: getIcon(data?.labour, variant), tooltipTitle: "Make Type", access: hasAccessToSeeHighestSeller },
        ],
      },
    ],
    [data, variant]
  );

  //hideDetails - This is Used For CatalogSetting.
  const hideDetails = React.useMemo(
    () => [
      {
        left: [
          { label: data?.styleNumber, Icon: getIcon(data?.designClass, variant), tooltipTitle: null, access: true },
          { label: data.view, Icon: getIcon("catalogView", variant), click: handleViewCartDetails ? () => handleViewCartDetails(data, "view") : null, tooltipTitle: null, access: true },
          { label: data.cart, Icon: getIcon("catalogCart", variant), click: handleViewCartDetails ? () => handleViewCartDetails(data, "cart") : null, tooltipTitle: null, access: true },
          {
            label: data.removeCart,
            Icon: getIcon("catalogRemoveCart", variant),
            click: handleViewCartDetails ? () => handleViewCartDetails(data, "removeCart") : null,
            tooltipTitle: null,
            access: true,
          },
          { label: data.clock, Icon: getIcon("catalogClock", variant), click: handleViewCartDetails ? () => handleViewCartDetails(data, "clock") : null, tooltipTitle: null, access: true },
        ],
      },
    ],
    [data, handleViewCartDetails, variant]
  );

  const toggelDetails = () => {
    if (btnTilte === "Show Details") {
      handleShowDetails();
    } else {
      handleHideDetails();
    }
  };

  //Chetan Code:-
  useEffect(() => {
    if (btnTilte === "Show Details") {
      setDetails(hideDetails);
    } else {
      setDetails(showDetails);
    }
  }, [btnTilte]);

  return (
    <>
      <Card width="auto" height="auto" style={{ ...cardStyle, ...style, border: data?.bestSeller && hasAccessToSeeBestSeller ? "1px solid #C0C0C0" : null, cursor: "auto" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }} width={"100%"}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }} width={"100%"}>
            <Box className="right">
              {details[0]?.left?.map(
                (detail, index) =>
                  detail.access && (
                    // <React.Fragment key={index}>
                    <Box key={index} className={`content-new content-new-${index}`}>
                      {detail.click ? (
                        <IconButton disableFocusRipple disableRipple sx={{ p: 0 }} onClick={detail.click}>
                          {detail.Icon}
                        </IconButton>
                      ) : (
                        detail.Icon
                      )}
                      {detail?.tooltipTitle === "Sales" && <SaleDetailsModal open={salesDetailsModal} setOpen={setSaleDetailModal} styleNumber={`${details[0]?.left[0]?.label}`} />}
                      <Tooltip title={detail?.tooltipTitle} marginTop="2px">
                        <Typography className={`content-typo content-typo-${index}`} ml={1}>
                          {detail.label}
                        </Typography>
                      </Tooltip>
                    </Box>
                    // </React.Fragment>
                  )
              )}
            </Box>
            <Box className="middle">
              {details[1]?.middle?.map(
                (detail, index) =>
                  detail?.label && (
                    <React.Fragment key={index}>
                      <Tooltip title={detail?.tooltipTitle} marginTop="4px">
                        {detail?.access && (
                          <Box className={`content-new content-new-${index}`}>
                            {detail.click ? (
                              <IconButton disableRipple disableFocusRipple sx={{ p: 0 }} onClick={detail.click}>
                                <Badge
                                  badgeContent={detail?.badge > 1 ? detail?.badge : null}
                                  sx={{
                                    "& .MuiBadge-badge": {
                                      backgroundColor: "white",
                                      color: "black",
                                    },
                                  }}
                                >
                                  {detail.Icon}
                                </Badge>
                              </IconButton>
                            ) : (
                              detail.Icon
                            )}
                          </Box>
                        )}
                      </Tooltip>
                      {detail.label === "collectionDetails" && (
                        <ViewDetails
                          open={collectionModal}
                          setOpen={setCollectionModal}
                          list={data?.collectionDetails?.map((item) => ({
                            _id: item._id,
                            name: item.collectionName,
                            // Here need to pass img URL and design Number
                          }))}
                          heading={`${details[0]?.left[0]?.label} Collection`}
                          imgUrl={data.Orgimg}
                          btnName="View Collection"
                        />
                      )}
                      {detail.label === "setDetails" && (
                        <ViewDetails
                          open={setModal}
                          setOpen={setSetModal}
                          list={data?.setDetails?.map((item) => ({
                            _id: item._id,
                            name: `SET - ${item.setNumber}`,
                            imgUrl: data.Orgimg ? data.Orgimg : Image,
                            styleNumber: data["styleNumber"],
                            numOfStyles: item.numOfStyles,
                          }))}
                          heading={`${details[0]?.left[0]?.label} Set`}
                          btnName="View Set"
                        />
                      )}
                      {detail.label === "cup" && <BestSellerModal open={bestSellerModal} setOpen={setBestSellerModal} styleNumber={`${details[0]?.left[0]?.label}`} />}
                    </React.Fragment>
                  )
              )}
            </Box>
          </Box>
          <Box className={`left ${hoverEffect ? "hover-enabled" : ""}`} sx={{ width: "195px", height: "195px" }}>
            {/* <img src={data.img ? data.img : Image} loading="lazy" alt="designImg" style={{ height: isBtn ? "90%" : "100%", borderRadius: "5px" }} onClick={() => handleOpenImageModal(Image, name)} /> */}

            {/* <AsyncImage
              onClick={() => handleOpenImageModal(data.img ? data.Orgimg : Image, data["styleNumber"])}
              src={data.img ? data.img : Image}
              style={{ display: "block", height: isBtn ? "90%" : "100%", width: "100%", objectFit: "fill", borderRadius: "5px", cursor: "pointer" }}
              loader={<div style={{ background: "#888" }} />}
              error={<div style={{ background: "#eee" }} />}
              onContextMenu={(e) => e.preventDefault()}
              onTouchStart={(e) => e.preventDefault()}
              draggable="false"
            /> */}

            <SmallImage data={data} isBtn={isBtn} handleOpenImageModal={handleOpenImageModal} hasAccessToSeeWatermark={hasAccessToSeeWatermark} />

            {isBtn && !hoverEffect ? (
              <Button
                variant="contained"
                disabled={presentInCatalog ? presentInCatalog : false}
                sx={{
                  backgroundColor: btnTilte === "Add" || btnTilte === "Remove" || btnTilte === "Show Details" || btnTilte === "Hide Details" || btnTilte === "Select" ? "#FFFFFF" : "#50C878",
                  color:
                    btnTilte === "Add" || btnTilte === "Show Details" || btnTilte === "Select"
                      ? "#50C878 !important"
                      : btnTilte === "Remove" || btnTilte === "Hide Details"
                        ? "#E77B7B !important"
                        : "#ffffff !important",
                  // pointerEvents: btnTilte === "Add" || btnTilte === "Remove" ? "auto" : "none !important",
                  border:
                    btnTilte === "Remove" || btnTilte === "Hide Details"
                      ? "1px solid #E77B7B"
                      : btnTilte === "Add" || btnTilte === "Show Details" || btnTilte === "Select"
                        ? "1px solid #50C878"
                        : "none",
                  "&:hover": {
                    backgroundColor: btnTilte === "Add" || btnTilte === "Remove" || btnTilte === "Show Details" || btnTilte === "Hide Details" || btnTilte === "Select" ? "#FFFFFF" : "#50C878",
                  },
                  "&.Mui-disabled": {
                    backgroundColor: "#FFFFFF !important",
                    color: "#444444 !important",
                    border: "1px solid #444444 !important",
                  },
                  width: btnWidth ? btnWidth : "110px !important",
                  height: "40px !important",
                  padding: "0px 20px !important",
                  boxShadow: "0",
                  fontFamily: "poppins",
                }}
                onClick={
                  btnTilte === "Add"
                    ? handleAddToCartBtn
                    : btnTilte === "Select"
                      ? handleAddEditCatagoryData
                      : btnTilte === "Show Details" || btnTilte === "Hide Details"
                        ? toggelDetails
                        : () => removeCart(selectedCartProducts)
                }
              >
                {btnTilte ? btnTilte : "Add"}
              </Button>
            ) : (
              ""
            )}
            {/* {hoverEffect && <input type="checkbox" className="hoverCheck" onChange={handleCheckboxChange(data.id)} checked={!!checkedItems[data.id]} />} */}
            {hoverEffect && (
              <Checkbox
                className="hoverCheck"
                disableFocusRipple
                disableRipple
                onChange={handleCheckboxChange(data.id)}
                checked={!!checkedItems[data.id]}
                sx={{
                  p: 0,
                  "& .MuiSvgIcon-root": {
                    fontSize: 24,
                    borderRadius: "5px",
                    border: "1px solid #000",
                    boxSizing: "border-box",
                  },
                  "&.Mui-checked .MuiSvgIcon-root": {
                    color: "#E77B7B",
                    transform: "scale(1.3)", // Scale effect when checked
                    border: "none",
                  },
                }}
              />
            )}
          </Box>
        </Box>
      </Card>
      <ImageModal open={imageModal.isOpen} handleClose={handleCloseImageModal} data={imageModal} hasAccessToSeeWatermark={hasAccessToSeeWatermark} />
    </>
  );
};

export default React.memo(ProductCard);
