import React, { useState } from "react";
import { Select, MenuItem, OutlinedInput, InputAdornment } from "@mui/material";
import { getIcon } from "utils/iconsConfig";
import { ReactComponent as Arrow_Up } from "assets/filter/arrow-up.svg";
import { ReactComponent as Arrow_Down } from "assets/filter/arrow-down.svg";

const CustomSelect = ({ value, onChange, sffixValue, width, options = [], placeholder = "", customStyles = {}, menuStyles = {}, ...rest }) => {
  const [open, setOpen] = useState(false);
  return (
    <Select
      value={value}
      onChange={onChange}
      fullWidth
      displayEmpty
      open={open}
      onOpen={() => setOpen?.(true)}
      onClose={() => setOpen?.(false)}
      renderValue={(selected) => {
        if (selected === "") {
          return <span style={{ color: "#8B8B8B" }}>{placeholder}</span>;
        }
        return selected;
      }}
      label={placeholder}
      input={<OutlinedInput fullWidth endAdornment={<InputAdornment position="start">{open ? <Arrow_Up /> : <Arrow_Down />}</InputAdornment>} />}
      MenuProps={{
        PaperProps: {
          sx: {
            marginTop: "3px",
            backgroundColor: "#FFFFFF",
            color: "#000000",
            borderRadius: "5px",
            padding: "5px !important",
            // width,
            ...menuStyles,
            scrollbarWidth: "none",
            "&::-webkit-scrollbar": {
              display: "none",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "transparent",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "transparent",
            },
            "& .MuiList-root": {
              "& .MuiMenuItem-root": {
                padding: "10px 5px !important",
                margin: "0px 0px",
                display: "flex",
                justifyContent: "flex-start",
                alignContent: "center",
                "&:hover": {
                  backgroundColor: "#444444",
                  color: "#FFFFFF",
                  borderRadius: "5px",
                  margin: "0px 0px",
                },
              },
            },
          },
        },
      }}
      sx={{
        fontFamily: "Karla",
        fontSize: "18px",
        lineHeight: "22px",
        fontWeight: 500,
        height: "41px",
        borderRadius: "5px",
        textTransform: "none",
        color: open ? "#000000" : "#000000",
        backgroundColor: open ? "#FFFFFF" : "#FFFFFF",
        "&:hover": {
          backgroundColor: "#FFFFFF",
          color: "#000000",
        },
        border: "1px solid #000000",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        textAlign: "flex-start",
        padding: "0px 5px !important",
        width,
        "& .MuiSelect-select": {
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          textAlign: "flex-start",
          padding: "10px 5px !important",
        },
        "& .MuiSelect-icon": {
          display: "none",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
        "&:focus": {
          backgroundColor: "transparent",
        },
        ...customStyles,
      }}
      {...rest}
    >
      {options.map((value) => (
        <MenuItem key={value} value={value}>
          {value} {sffixValue}
        </MenuItem>
      ))}
    </Select>
  );
};

export default CustomSelect;
