import React from "react";

const TableYearlySale = ({ yearlySalesData }) => {
  return (
    <table className="table">
      <thead className="table-head">
        <tr>
          <th>Year</th>
          <th>Sales</th>
        </tr>
      </thead>
      <tbody className="table-body">
        {yearlySalesData && yearlySalesData.length > 0 ? (
          yearlySalesData.map((item) => (
            <tr key={item?.year}>
              <td>{item?.year}</td>
              <td>{item?.totalSale}</td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="2" style={{ textAlign: "center" }}>
              No sales data available.
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default TableYearlySale;
