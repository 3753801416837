import React from 'react'
import { Button } from '@mui/material'
import SingleSyncModal from 'components/ui/RemoveModal/RemoveModal'
import { SettingContext } from 'context/SettingContext'
import { useContext} from 'react'
import { useState } from 'react'
const Card = ({data,handleClick}) => {    
  
    return (
        <>
            <div key={data.id} style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "10px",
                height: "236.84px",
                width: "442.33px",
                maxWidth: "550px",
                minWidth: "390px",
                backgroundColor: "#191818",
                border: "0",
                borderRadius: "10px",
                fontFamily: "Karla, sans-serif"
            }}>
                <div style={{ padding: "0px" }}>
                    <div class="header">
                        <h4 style={{ fontWeight: "100", marginTop: "1px", fontSize: "18px" }}>{data.Name}</h4>
                    </div>
                    <p style={{ fontWeight: "200", fontSize: "16px", lineHeight: '22px', letterSpacing: '-0.41px' }}>From:-{data.FromText}</p>
                    <p style={{ fontWeight: "200", fontSize: "16px", lineHeight: '22px', letterSpacing: '-0.41px' }}>To:-{data.ToText}</p>
                </div>
                <div style={{ position: "relative" }}>
                    <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT0K8bkKpaSDmSz6Tm06ur_npj-PQ-GKs8bIg&s" alt="" style={{ height: "200px", width: '200px', border: "0", borderRadius: '5px' }} />
                    <Button
                        sx={{
                            width: "110px",
                            height: "40.84px",
                            position: "absolute",
                            top: "176px",
                            left: "45px",
                            gap: "0px",
                            borderRadius: "5px",
                            borderWidth: "0.5px",
                            borderStyle: "solid",
                            borderColor: "#50C878",
                            color: "#50C878",
                            backgroundColor: "white",
                            fontFamily: "Poppins, sans-serif",
                            fontSize: "16px",
                            fontWeight: "500",
                            textTransform: "none",
                            transition: "all 0.3s ease-in-out",
                            cursor: "pointer",
                            "&:hover": {
                                backgroundColor: "#50C878",
                                color: "#FFFFFF",
                            },
                        }}
                        onClick={() => {
                            handleClick(data)                      
                        }}
                    >
                        Revert
                    </Button>
                </div>
            </div>
            {/* Modal section */}

        </>
    )
}

export default Card