import App from "./App";
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { ThemeProvider, CssBaseline } from "@mui/material";
import darkTheme from "./theme";
import "react-phone-number-input/style.css";
import "react-phone-input-2/lib/style.css";
import "react-toastify/dist/ReactToastify.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ToastContainer } from "react-toastify";

const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById("root"));
document.title = process.env.REACT_APP_TITLE ? process.env.REACT_APP_TITLE : "Binny's app";

root.render(
  <QueryClientProvider client={queryClient}>
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <ToastContainer />
      <App />

      {process.env.REACT_APP_SHOW_DEVTOOLS === "true" && <ReactQueryDevtools initialIsOpen={false} position="left"/>}
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </ThemeProvider>
  </QueryClientProvider>
);
