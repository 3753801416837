import Home from "pages/Home";
import React, { useEffect, useState } from "react";
import { fetchRestricatCategoryDataCount, getRestrictedCategories, getRestrictedDesign } from "./api/restrictedPageApis";
import { Grid, Typography } from "@mui/material";
import LoadingSmallCard from "components/ui/Skeleton/LoadingSmallCard";
import { Card } from "components/ui";
import { useLocation, useNavigate } from "react-router-dom";
import { encodeStr } from "utils/encodeDecodeStr";
import { useSettingContext } from "context/SettingContext";

function RestrictedDesignCategoryPage() {
  const { setRestricateCatagoryCount } = useSettingContext();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const handleClick = (data) => {
    let newName = location?.state?.name[0].split(" - ");
    let newState = {
      ...location.state,
      name: [`${location?.state?.name[0]?.split(" - ")[0]}`, `${data?.category}`],
    };
    navigate(`/Setting/restricted-designs/${encodeStr(data?.category)}`, { state: newState });
  };

  useEffect(() => {
    const fetchRestrictedCategories = async () => {
      let response = await getRestrictedCategories();

      if (response.status === 200) {
        setData(response.categories);
        setLoading(false);
      } else {
        alert("something went wrong");
      }
    };

    const fetchRestricatCategoryCount = async () => {
      try {
        const response = await fetchRestricatCategoryDataCount();

        if (response) {
          setRestricateCatagoryCount(response);
        } else {
          alert("Something went wrong while fetching category data count");
        }
      } catch (error) {
        alert("Error fetching category data count");
      }
    };

    fetchRestrictedCategories();
    fetchRestricatCategoryCount();
  }, []);

  if (loading) return <LoadingSmallCard count={24} />;

  return (
    <>
      {data && data.length > 0 ? (
        <Grid
          container
          className="Grid-padding"
          direction="row"
          justifyContent="flex-start"
          sx={{ flex: "1 1 1", display: "flex", alignItems: "center", justifyContent: "flex-start", gap: "30px", rowGap: "32px" }}
        >
          {data &&
            data.map((ele, i) => (
              <Grid item key={i} onClick={() => handleClick(ele)}>
                <Card style={{ minWidth: 275, maxWidth: "317px", height: 62, borderRadius: "5px" }}>
                  <Typography mr={"auto"} fontSize={"20px"} fontWeight={400} fontFamily={"Karla"}>
                    {ele.category}
                  </Typography>
                  <Typography ml={"auto"} fontSize={"20px"} fontWeight={400} fontFamily={"Karla"}>
                    {ele.count}
                  </Typography>
                </Card>
              </Grid>
            ))}
        </Grid>
      ) : (
        <Home />
      )}
    </>
  );
}

export default RestrictedDesignCategoryPage;
