import { formatDate } from "./formatDate";
import { formatTime } from "./formatTime";

const capitalizeFirstLetter = (word) => {
  if (!word) return ""; // Handle empty or null values
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
};

const formatSubName = (word) => {
  let wordData = word.split(" ");
  let newWord = "";
  wordData.forEach((element, index) => {
    newWord += capitalizeFirstLetter(element);
    newWord += index === wordData.length - 1 ? "" : " ";
  });
  return newWord;
};

const formatOwenrWithDate = (catalog) => {
  const formattedDate = formatDate(catalog.createdAt);
  const formattedTime = formatTime(catalog.createdAt);
  const ownerName = `${capitalizeFirstLetter(catalog.userDetails?.firstName) || ""} ${capitalizeFirstLetter(catalog.userDetails?.lastName) || ""}`.trim() || "Unknown Owner";
  return `${formattedDate}, ${formattedTime.toLocaleUpperCase()}, ${ownerName}`;
};

export const returnCatalogCard = (catalog) => {
  return {
    id: catalog._id,
    number: `#${catalog.catalogNumber}`,
    subname: formatSubName(catalog.catalogName),
    owner: formatOwenrWithDate(catalog),
    isArchive: catalog.isArchive,
    profile: catalog?.userDetails?.profileImage || null,
    iconsData: [
      {
        title: "designCount",
        count: catalog.numberOfStyles,
      },
      {
        title: "linkCount",
        count: 0,
      },
      {
        title: "viewCount",
        count: catalog.views,
      },
    ],
    numberOfStyles: catalog.numberOfStyles || 0
  };
};
