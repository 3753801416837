import React from "react";
import watermarkImageUrl from "../../assets/img/watermark.png";
import { AsyncImage } from "loadable-image";
import Image from "assets/img/default-img.jpg";


const SmallImage = ({ data, isBtn, handleOpenImageModal, hasAccessToSeeWatermark }) => {
  return (
    <div style={{ position: "relative", width: "100%", height: isBtn ? "90%" : "100%" }}>
      {/* The original image */}
      <AsyncImage
        onClick={() => handleOpenImageModal(data.img ? data.Orgimg : null, data["styleNumber"])}
        src={data.img ? data.img : Image}
        style={{
          display: "block",
          height: "100%",
          width: "100%",
          objectFit: "fill",
          borderRadius: "5px",
          cursor: "pointer",
        }}
        loader={<div style={{ background: "#888", width: "100%", height: "100%" }} />}
        error={<div style={{ background: "#eee", width: "100%", height: "100%" }} />}
        onContextMenu={(e) => e.preventDefault()}
        onTouchStart={(e) => e.preventDefault()}
        draggable="false"
      />

      {hasAccessToSeeWatermark &&
        data?.img && (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "95%",
              height: isBtn ? "95%" : "95%",
              backgroundImage: `url(${watermarkImageUrl})`,
              backgroundRepeat: "repeat",
              backgroundSize: "200px 200px",
              opacity: 1,
              pointerEvents: "none",
            }}
          />
        )}
    </div>
  );
};

export default SmallImage;
