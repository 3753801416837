import React, { useEffect, useState } from "react";
import { useGetMissingImages } from "../apis/get-missing-images";
import { Grid, CircularProgress } from "@mui/material";
import ProductSkeleton from "components/ui/Skeleton/ProductSkeleton";
import { ProductCard } from "components/ui";
import { transformData } from "utils/transformdata";
import { useInView } from "react-intersection-observer";
import { useSettingContext } from "context/SettingContext";

const MissingImages = () => {
  const [countSet, setCountSet] = useState(false);
  const { setMissingImagesCount, search, setSearch } = useSettingContext();
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading, isError, error } = useGetMissingImages({
    limit: 30,
    search,
  });

  const { ref, inView } = useInView({
    triggerOnce: false,
    threshold: 0.2,
  });

  useEffect(() => {
    if (data?.pages?.[0]?.totalCount !== undefined && !countSet) {
      setMissingImagesCount(data.pages[0].totalCount);
      setCountSet(true);
    }
  }, [data?.pages, setMissingImagesCount, countSet]);

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, fetchNextPage]);

  useEffect(() => {
    return () => {
      setSearch("");
    };
  }, [setSearch]);

  if (isLoading) return <ProductSkeleton count={8} />;

  if (isError) return <div>Error: {error.message}</div>;

  return (
    <>
      <Grid
        container
        className="Grid-padding"
        direction="row"
        justifyContent="flex-start"
        sx={{
          flex: "1 1 1",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: "30px",
          rowGap: "32px",
          maxHeight: "78vh",
          overflowY: "scroll",
          "&::-webkit-scrollbar": {
            width: "0px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "transparent",
          },
        }}
      >
        {data?.pages?.map((page) =>
          page?.data?.map((design) => {
            const data = transformData(design);
            return (
              <Grid item className="product">
                <ProductCard data={data} key={design._id} style={{ maxWidth: "430px", minWidth: "430px" }} />
              </Grid>
            );
          })
        )}
        <div ref={ref} style={{ height: "20px", marginTop: "10px" }} />
        {isFetchingNextPage && (
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <CircularProgress />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default MissingImages;
