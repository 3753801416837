const { createContext, useState, useContext, useEffect } = require("react");

export const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
  const [createBtn, setCreateBtn] = useState(false);
  const [addDesignBtn, setAddDesignBtn] = useState(false);

  const [creatBtnName, setCreateBtnName] = useState("");
  const [createBtnOption, setCreateBtnOption] = useState([]);
  const [countCart, setCountCart] = useState(0);
  const [removeIcon, setRemoveIcon] = useState(false);
  const [trashModalOpen, setTrashModalOpen] = useState(false);
  const [isArchive, setIsArchive] = useState(false);
  const [isArchiveBtn, setIsArchiveBtn] = useState(false);
  const [checkCatalogBeforeNavigate, setCheckCatalogBeforeNavigate] = useState(false);
  const [newNameCatalog, setNewNameCatalog] = useState("");

  const [isCollectionEditBtn, setIsCollectionEditBtn] = useState(false);
  const [showCollectionTrashIcon, setShowCollectionTrashIcon] = useState(false);
  const [collectionUpdate, setCollectionUpdate] = useState(false);

  const [openCancelModal, setOpenCancelModal] = useState(false);

  const [editCatagoryBtn, setEditCatagoryBtn] = useState(false);
  const [editCatagoryBtnOption, setEditCatagoryBtnOption] = useState([]);
  const [isEditCatagorySelectValue, setIsEditCatagorySelectValue] = useState(false);
  const [isEditCatagoryData, setIsEditCatagoryData] = useState([]);
  const [openEditCatagoryModal, setOpenEditCatagoryModal] = useState(false);
  const [searchPlaceHolder, setSearchPlaceHolder] = useState("Search");

  //Used For Info Model in set-design:-
  const [isInfoBtn, setIsInfoBtn] = useState(false);
  const [infoModalOpen, setInfoModalOpen] = useState(false);

  return (
    <GlobalContext.Provider
      value={{
        trashModalOpen,
        setTrashModalOpen,
        removeIcon,
        setRemoveIcon,
        createBtn,
        setCreateBtn,
        creatBtnName,
        setCreateBtnName,
        createBtnOption,
        setCreateBtnOption,
        countCart,
        setCountCart,
        isArchive,
        setIsArchive,
        isArchiveBtn,
        setIsArchiveBtn,
        checkCatalogBeforeNavigate,
        setCheckCatalogBeforeNavigate,
        newNameCatalog,
        setNewNameCatalog,

        showCollectionTrashIcon,
        setShowCollectionTrashIcon,
        addDesignBtn,
        setAddDesignBtn,

        collectionUpdate,
        setCollectionUpdate,

        openCancelModal,
        setOpenCancelModal,

        editCatagoryBtn,
        setEditCatagoryBtn,
        editCatagoryBtnOption,
        setEditCatagoryBtnOption,
        isEditCatagorySelectValue,
        setIsEditCatagorySelectValue,
        isEditCatagoryData,
        setIsEditCatagoryData,
        openEditCatagoryModal,
        setOpenEditCatagoryModal,
        searchPlaceHolder,
        setSearchPlaceHolder,

        isInfoBtn,
        setIsInfoBtn,
        infoModalOpen,
        setInfoModalOpen,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobalContext = () => useContext(GlobalContext);
