const encodeStr = (str) => {
  return str
    .toLowerCase()
    .replace(/\s+/g, "-")
    .replace(/[^\w\-]+/g, "");
};

const decodeStr = (str) => {
  return str.replace(/-/g, " ").toLowerCase();
};

// Capitalize the first letter of each word and removing "-"
const formatString = (str) => {
  return str
    .replace(/-/g, " ")
    .toLowerCase()
    .replace(/\b\w/g, (char) => char.toUpperCase());
};

export { encodeStr, decodeStr, formatString };
