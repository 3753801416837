import React, { useContext, useEffect } from "react";
import { CircularProgress, Grid } from "@mui/material";
import { useLocation } from "react-router-dom";
import GoToTop from "components/ui/GoToTop";
import { ReactComponent as Background } from "assets/icons/bg.svg";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDesings } from "features/Category/categoryProd/api/get-desings";
import SortContext from "context/SortContext";
import ProductSkeleton from "components/ui/Skeleton/ProductSkeleton";
import { transformData } from "utils/transformdata";
import SingleProduct from "./SingleProduct";
import { SettingContext } from "context/SettingContext";

const RestrictProductPage = () => {
  const location = useLocation();
  const { search } = React.useContext(SortContext);
  const categoryName = location.state?.name[1];
  const subCategoryName = location.state?.name[2]?.split("-")[0]?.trim();

  const {setRestrictBtn}= useContext(SettingContext)

  useEffect(()=>{
    setRestrictBtn(true)
    return ()=>{
      setRestrictBtn(false)
    }
  },[])

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading, isError, error } = useDesings({
    limit: 30,
    categoryName,
    subCategoryName,
    search,
    isRestric: true,
  });

  if (isLoading) return <ProductSkeleton count={10} />;

  if (isError) return <div>Error: {error.message}</div>;

 

  return (
    <>
      <InfiniteScroll
        dataLength={data?.pages.flatMap((page) => page.designs).length || 0}
        next={fetchNextPage}
        hasMore={!!hasNextPage}
        loader={isFetchingNextPage && <CircularProgress sx={{ display: "flex", justifyContent: "center", margin: "20px auto" }} />}
        scrollThreshold={0.8}
        endMessage={<p style={{ textAlign: "center" }}>You have seen it all!</p>}
      >
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          sx={{
            flex: "1 1 1",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: "30px",
            rowGap: "32px",
          }}
          className="Grid-padding"
        >
          {data?.pages?.map((page) =>
            page.designs.map((design) => {
              const transformedProduct = transformData(design);
              return <SingleProduct product={transformedProduct} key={design._id} />;
            })
          )}
        </Grid>
      </InfiniteScroll>

      <GoToTop />

      {data?.pages?.length === 0 ||
        (!data?.pages.some((page) => page?.designs?.length > 0) && (
          <div
            style={{
              display: "flex",
              textAlign: "center",
              margin: "auto",
              padding: "auto",
              justifyContent: "center",
              alignItems: "center",
              height: "78vh",
            }}
          >
            <Background />
          </div>
        ))}
    </>
  );
};

export default RestrictProductPage;
