import { Box, styled, InputBase } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import Button from "components/ui/Button";
import { useGlobalContext } from "context/GlobalContext";
import SortContext from "context/SortContext";
import { removeCollections, updateCollectionNames } from "features/collection/components/CollectionProductScreen/api/getCollectionProducts";
import { useUpdateSet } from "features/set/api/update-set";
import { useRemoveDesings } from "features/set/hooks/useRemoveDesigns";
import React, { useContext } from "react";
import { toast } from "react-toastify";

import { useNavigate } from "react-router-dom";
const CancelButton = styled(Button)(({}) => ({
  fontFamily: "Poppins",
  fontWeight: 500,
  fontSize: "18px",
  lineHeight: "22px",
  height: "42px",
  borderRadius: "5px",
  color: "#E77B7B",
  border: "1px solid #E77B7B",
  textTransform: "none",
  backgroundColor: "transparent",
  "&:hover": {
    backgroundColor: "#E77B7B",
    color: "black",
  },
}));

const StyledButton = styled(Button)(({ bgcolor, variant, disabled }) => ({
  fontFamily: "Poppins",
  fontWeight: 500,
  fontSize: "18px",
  lineHeight: "22px",
  height: "40px",
  borderRadius: "5px",
  color: `${variant === "save-change" ? "#50C878" : "#ffffff"}`,
  border: `1px solid ${variant === "save-change" ? "#50C878" : "#ffffff"}`,
  textTransform: "none",
  backgroundColor: "transparent",
  "&:hover": {
    backgroundColor: "transparent",
  },
  "&.Mui-disabled": {
    backgroundColor: "#000000",
    color: "#8B8B8B",
    border: "1px solid #8B8B8B",
  },
}));

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: "#FFFFFF",
  "&:hover": {
    backgroundColor: "#f0f0f0",
  },
  marginLeft: 0,
  width: "100%",
  boxShadow: "inset 0px 4px 20px 0px #00000040",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "black",
  height: "40px",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 1),
    transition: theme.transitions.create("width"),

    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "40ch",
    },
  },
}));

const EditSetHeader = () => {
  const queryClient = useQueryClient();
  const { setIsEdit, setIsEditClick, setSearch, search, isCollectionEdit, setIsCollectionEdit } = useContext(SortContext);
  const { isBtnEnable, setIsBtnEnable } = React.useContext(SortContext);
  const { mutate: updateSetMutation } = useUpdateSet({ onCompleted });
  const { handleCancel: uiUpdate } = useRemoveDesings();
  const { setCreateBtn, setShowCollectionTrashIcon, collectionUpdate, setOpenCancelModal } = useGlobalContext();
  const navigate = useNavigate();

  function handleCancel() {
    //to remove the changes from the frotend
    uiUpdate();
    // queryClient.removeQueries(["sets"]);
    // queryClient.removeQueries(["get-designs"]);
    // localStorage.removeItem("archivedDesigns");
    // localStorage.removeItem("removedDesignsBySet");
    setIsEdit("view");
    setIsEditClick(false);
    setIsBtnEnable(false);

    //-- for colletion page --\\
    // setIsCollectionEdit("view");
    // setShowCollectionTrashIcon(false);
    //-- for colletion page --\\
  }

  const handleCancelCollectionEdit = () => {
    if (collectionUpdate) {
      setOpenCancelModal(true);
    } else {
      setIsCollectionEdit("view");
      setShowCollectionTrashIcon(false);
      // localStorage.removeItem("tempCollectionRemoveData");
      setOpenCancelModal(false);
    }
  };

  const handleCollectionEditSave = async () => {
    let collectionToBeRemoved = JSON.parse(localStorage.getItem("tempCollectionRemoveData")) || [];
    let collectionToBeUpdate = JSON.parse(localStorage.getItem("tempCollectionUpdatedData")) || [];

    if (collectionToBeRemoved.length > 0) {
      let Data = await removeCollections(collectionToBeRemoved);
      if (Data.status === 200) {
        localStorage.removeItem("tempCollectionRemoveData");
        toast.success(Data.message, {
          autoClose: 1000,
          hideProgressBar: false,
        });
        queryClient.removeQueries("collectionCountData");
        setIsCollectionEdit("view");
        setShowCollectionTrashIcon(false);
        navigate("/Collection");
      }
    }

    if (collectionToBeUpdate.length > 0) {
      let Data = await updateCollectionNames(collectionToBeUpdate);
      if (Data.status === 200) {
        localStorage.removeItem("tempCollectionUpdatedData");
        toast.success(Data.message, {
          autoClose: 1000,
          hideProgressBar: false,
        });
        setIsCollectionEdit("view");
        setShowCollectionTrashIcon(false);
      }
    }
  };

  const { createPayload } = useRemoveDesings();

  const handleSave = () => {
    updateSetMutation(createPayload());
  };

  function onCompleted() {
    // const data = localStorage.getItem("removedDesignsBySet");
    // const removedDesignsBySet = JSON.parse(data);
    // Object.keys(removedDesignsBySet).forEach((setId) => {
    //   queryClient.removeQueries(["get-designs", setId]);
    // });
    // queryClient.refetchQueries(["sets"]);
    // localStorage.removeItem("removedDesignsBySet");
    // setIsEdit("view");

    queryClient.removeQueries(["sets"]);
    queryClient.removeQueries(["setCountData"]);
    queryClient.removeQueries(["get-designs"]);
    localStorage.removeItem("archivedDesigns");
    localStorage.removeItem("removedDesignsBySet");
    // setIsEdit("view");

    setIsEdit("view");
    setIsEditClick(false);
    setIsBtnEnable(false);
  }

  return (
    <>
      <Box sx={{ flexGrow: 1 }} />
      {isCollectionEdit === "click" && (
        <Search>
          <StyledInputBase placeholder="Search" onChange={(e) => setSearch(e.target.value)} value={search} inputProps={{ "aria-label": "search" }} sx={{ height: "40px", borderRadius: "5px" }} />
        </Search>
      )}

      {isCollectionEdit === "click" ? (
        <StyledButton variant={collectionUpdate ? "save-change" : ""} onClick={handleCollectionEditSave} disabled={!collectionUpdate}>
          Save Changes
        </StyledButton>
      ) : (
        <StyledButton onClick={handleSave} disabled={isBtnEnable ? false : true} variant={isBtnEnable ? "save-change" : ""}>
          Save Changes
        </StyledButton>
      )}

      <CancelButton onClick={isCollectionEdit === "click" ? handleCancelCollectionEdit : handleCancel}>Cancel</CancelButton>
    </>
  );
};

export default EditSetHeader;
