import { useQuery } from "@tanstack/react-query";
const { default: apiClient } = require("lib/api-client");

const fetchDesigns = async (id) => {
  const response = await apiClient.post("/api/v1/set/getSetById", {
    setId: id,
  });

  if (response.status !== 200) {
    throw new Error("Error fetching categories");
  }

  return response.data;
};

export const useGetDesigns = (setID) => {
  return useQuery({
    queryKey: ["get-designs", setID],
    queryFn: () => fetchDesigns(setID),
    enabled: !!setID, // Only run the query if cardId is present
    staleTime: 120000,
    select: (data) => ({
      setNumber: data.set.setNumber,
      designs: data.set.designs,
    }),
  });
};
