import React from 'react'
import Card from '../components/Card'
import { Grid } from '@mui/material'
import { useContext } from 'react'
import { SettingContext } from 'context/SettingContext'
import { useEffect } from 'react'
import { useState } from 'react'
import SingleSyncModal from 'components/ui/RemoveModal/RemoveModal'


const SyncCollectionPage = () => {

    const {setCollectionSyncButton,setSingleRevertModal, singleRevertModal} = useContext(SettingContext);

    const [currentSelctelement,setCurrentSelctelement] = useState(null);

    const syncCollectionData = [
        { Name: "NS-00410 collection Site1", FromText: "Peal Necklace", ToText: "null" },
        { Name: "NS-00410 collection Site2", FromText: "Peal Necklace", ToText: "null" },
        { Name: "NS-00410 collection Site3", FromText: "Peal Necklace", ToText: "null" },
        { Name: "NS-00410 collection Site4", FromText: "Peal Necklace", ToText: "null" },
        { Name: "NS-00410 collection Site5", FromText: "Peal Necklace", ToText: "null" },
        { Name: "NS-00410 collection Site6", FromText: "Peal Necklace", ToText: "null" },
    ]
    
    const SingleSyncBtnInfo = React.useMemo(
      () => [
          {
              btnName: "Discard",
              bgColor: "#E77B7B",
              click: () => setSingleRevertModal(false),
          },
          {
              btnName: "Revert",
              bgColor: "#50C878",
              click: () => "",
          },
      ],
      []
  );

    const handleClick =(data) =>{
      setSingleRevertModal(true)  
      setCurrentSelctelement(data?.Name) 
    }

    useEffect(()=>{
            setCollectionSyncButton(true)
            return ()=>{
              setCollectionSyncButton(false);
            }
        },[])

  return (
    <>
    <Grid container spacing={4}>
      {syncCollectionData.map((data, index) => (
        <Grid item xs={12} md={6} lg={4}>
          <Card data={data} handleClick={handleClick} />
        </Grid>
      ))}
    </Grid>

      {/** modal */}  
    <SingleSyncModal open={singleRevertModal} setOpen={setSingleRevertModal} btnInfo={SingleSyncBtnInfo} heading={`Are you sure you want to revert ${currentSelctelement}?`} />

    </> 
  )
}

export default SyncCollectionPage