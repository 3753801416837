import { ReactComponent as Gold } from "assets/icons/gold.svg";
import { ReactComponent as Bronze } from "assets/icons/brozen.svg";
import { ReactComponent as Silver } from "assets/icons/sliver.svg";
import { ReactComponent as Diamond } from "assets/icons/diamond.svg";
import { ReactComponent as BlackDimondIcon } from "assets/icons/Set Tray Icon/Diamond.svg";
import { ReactComponent as Babor } from "assets/icons/babor.svg";
import { ReactComponent as BlackBabor } from "assets/icons/Set Tray Icon/Labor.svg";
import { ReactComponent as DateIcon } from "assets/icons/date.svg";
import { ReactComponent as BlackDateIcon } from "assets/icons/Black_Calender.svg";
import { ReactComponent as Weight } from "assets/icons/small.svg";
import { ReactComponent as BlackWeight } from "assets/icons/Set Tray Icon/gold.svg";
import { ReactComponent as CatalogClientReportProductViewEye } from "assets/catalogSetting/ViewProduct/eye.svg";
import { ReactComponent as CatalogClientReportProductCart } from "assets/catalogSetting/ViewProduct/Cart.svg";
import { ReactComponent as CatalogClientReportProductRemoveCart } from "assets/catalogSetting/ViewProduct/RemoveCart.svg";
import { ReactComponent as CatalogClientReportProductClock } from "assets/catalogSetting/ViewProduct/Clock.svg";
import { ReactComponent as SetsIcons } from "assets/sidebar/enable/sets.svg";
import { ReactComponent as CollectionIcon } from "assets/sidebar/enable/collection.svg";
import { ReactComponent as BalckCollectionIcon } from "assets/icons/Set Tray Icon/collection.svg";
import { ReactComponent as EyeBtn } from "assets/icons/CatalogueCard/eye.svg";
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import { ReactComponent as GoToTop } from "assets/icons/goTop.svg";
import { ReactComponent as Next } from "assets/icons/next.svg";
import { ReactComponent as Prev } from "assets/icons/prev.svg";
import { ReactComponent as Sales_black } from "assets/sale_icon/sale_Black.svg";
import { ReactComponent as Sales } from "assets/sale_icon/sale.svg";
import { ReactComponent as GoldCup } from "assets/cup_icon/gold-cup.svg";
import { ReactComponent as SilverCup } from "assets/cup_icon/silver-cup.svg";
import { ReactComponent as BronzeCup } from "assets/cup_icon/brown-cup.svg";
import { ReactComponent as TrendUp } from "assets/trend_icon/trend-up.svg";
import { ReactComponent as TrendDown } from "assets/trend_icon/trend-down.svg";
import { ReactComponent as H_labor } from "assets/labor_icon/H.svg";
import { ReactComponent as R_labor } from "assets/labor_icon/R.svg";
import { ReactComponent as X_labor } from "assets/labor_icon/X.svg";
import { ReactComponent as H_labor_black } from "assets/labor_icon/H_Black.svg";
import { ReactComponent as R_labor_black } from "assets/labor_icon/R_Black.svg";
import { ReactComponent as X_labor_black } from "assets/labor_icon/X_Black.svg";
import { ReactComponent as Arrow_Up } from "assets/filter/arrow-up.svg";
import { ReactComponent as Arrow_Down } from "assets/filter/arrow-down.svg";

export const getIcon = (type, variant = "default") => {
  const icons = {
    gold: variant === "black" ? <Gold /> : <Gold />,
    silver: variant === "black" ? <Silver /> : <Silver />,
    bronze: variant === "black" ? <Bronze /> : <Bronze />,
    diamond: variant === "black" ? <BlackDimondIcon /> : <Diamond />,
    goldbar: variant === "black" ? <BlackWeight /> : <Weight />,
    labour: variant === "black" ? <BlackBabor /> : <Babor />,
    date: variant === "black" ? <BlackDateIcon /> : <DateIcon />,
    catalogView: variant === "black" ? <CatalogClientReportProductViewEye /> : <CatalogClientReportProductViewEye />,
    catalogCart: variant === "black" ? <CatalogClientReportProductCart /> : <CatalogClientReportProductCart />,
    catalogRemoveCart: variant === "black" ? <CatalogClientReportProductRemoveCart /> : <CatalogClientReportProductRemoveCart />,
    catalogClock: variant === "black" ? <CatalogClientReportProductClock /> : <CatalogClientReportProductClock />,
    set: variant === "black" ? <SetsIcons /> : <SetsIcons />,
    collection: variant === "black" ? <BalckCollectionIcon /> : <CollectionIcon />,
    close: variant === "black" ? <CloseIcon /> : <CloseIcon />,
    eye: variant === "black" ? <EyeBtn /> : <EyeBtn />,
    GoToTop: variant === "black" ? <GoToTop /> : <GoToTop />,
    next: variant === "black" ? <Next /> : <Next />,
    prev: variant === "black" ? <Prev /> : <Prev />,
    trendUp: variant === "black" ? <TrendUp /> : <TrendUp />,
    trendDown: variant === "black" ? <TrendDown /> : <TrendDown />,
    h_labor: variant === "black" ? <H_labor_black /> : <H_labor />,
    r_labor: variant === "black" ? <H_labor_black /> : <R_labor />,
    x_labor: variant === "black" ? <H_labor_black /> : <X_labor />,
    sales: variant === "black" ? <Sales_black /> : <Sales />,
    gold_cup: variant === "black" ? <GoldCup /> : <GoldCup />,
    silver_cup: variant === "black" ? <SilverCup /> : <SilverCup />,
    bronze_cup: variant === "black" ? <BronzeCup /> : <BronzeCup />,
    arrow_up: variant === "black" ? <Arrow_Up /> : <Arrow_Up />,
    arrow_down: variant === "black" ? <Arrow_Down /> : <Arrow_Down />,
  };

  return icons[type] || null;
};
