import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
const { default: apiClient } = require("lib/api-client");

const deleteSet = async ({ setId }) => {
  const response = await apiClient.post(`/api/v1/set/deleteSet`, { setId });
  return response.data;
};

export const useDeleteSet = ({ onCompleted }) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteSet,
    onSuccess: (data) => {
      toast.success(data.message);
      queryClient.removeQueries("archives-sets");
      onCompleted();
    },
    onError: (error) => {
      toast.error("Failed to delete the set.");
    },
  });
};
