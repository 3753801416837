// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-textarea {
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: rgb(224, 219, 219) rgb(235, 235, 240); /* Thumb color and Track color for Firefox */
  overflow-y: auto; /* Ensure overflow behavior is set */
}

.custom-textarea::-webkit-scrollbar {
  width: 5px; /* Set the width of the scrollbar */
}

.custom-textarea::-webkit-scrollbar-track {
  background: rgb(224, 219, 219); /* Track color */
}

.custom-textarea::-webkit-scrollbar-thumb {
  background: rgb(235, 235, 240); /* Thumb color */
  border-radius: 50px; /* Rounded corners for the thumb */
  cursor: pointer; /* Make sure the pointer cursor shows */
}

.custom-textarea::-webkit-scrollbar-thumb:hover {
  background: darkred; /* Darker color when hovered */
}

/* Hide scrollbar initially and show on hover */
.custom-textarea::-webkit-scrollbar {
  display: none; /* Hide scrollbar initially */
}

.custom-textarea:hover::-webkit-scrollbar {
  display: block; /* Show scrollbar when hovered */
}
`, "",{"version":3,"sources":["webpack://./src/features/collection/components/Modal/collectiondiscription.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB,EAAE,gBAAgB;EACvC,sDAAsD,EAAE,4CAA4C;EACpG,gBAAgB,EAAE,oCAAoC;AACxD;;AAEA;EACE,UAAU,EAAE,mCAAmC;AACjD;;AAEA;EACE,8BAA8B,EAAE,gBAAgB;AAClD;;AAEA;EACE,8BAA8B,EAAE,gBAAgB;EAChD,mBAAmB,EAAE,kCAAkC;EACvD,eAAe,EAAE,uCAAuC;AAC1D;;AAEA;EACE,mBAAmB,EAAE,8BAA8B;AACrD;;AAEA,+CAA+C;AAC/C;EACE,aAAa,EAAE,6BAA6B;AAC9C;;AAEA;EACE,cAAc,EAAE,gCAAgC;AAClD","sourcesContent":[".custom-textarea {\n  scrollbar-width: thin; /* For Firefox */\n  scrollbar-color: rgb(224, 219, 219) rgb(235, 235, 240); /* Thumb color and Track color for Firefox */\n  overflow-y: auto; /* Ensure overflow behavior is set */\n}\n\n.custom-textarea::-webkit-scrollbar {\n  width: 5px; /* Set the width of the scrollbar */\n}\n\n.custom-textarea::-webkit-scrollbar-track {\n  background: rgb(224, 219, 219); /* Track color */\n}\n\n.custom-textarea::-webkit-scrollbar-thumb {\n  background: rgb(235, 235, 240); /* Thumb color */\n  border-radius: 50px; /* Rounded corners for the thumb */\n  cursor: pointer; /* Make sure the pointer cursor shows */\n}\n\n.custom-textarea::-webkit-scrollbar-thumb:hover {\n  background: darkred; /* Darker color when hovered */\n}\n\n/* Hide scrollbar initially and show on hover */\n.custom-textarea::-webkit-scrollbar {\n  display: none; /* Hide scrollbar initially */\n}\n\n.custom-textarea:hover::-webkit-scrollbar {\n  display: block; /* Show scrollbar when hovered */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
