import apiClient from "lib/api-client";

export const radioOptions = {
  "Update Design Class": [
    { option: "Gold", value: "Gold" },
    { option: "Silver", value: "Silver" },
    { option: "Bronze", value: "Bronze" },
  ],
  "Update Make Type": [
    { option: "X - Exclusive", value: "X" },
    { option: "H - High", value: "H" },
    { option: "R - Regular", value: "R" },
  ],
  "Update Sub-category": [
    { option: "Peacock Necklaces", value: "Peacock Necklaces" },
    { option: "Peal Necklaces", value: "Peal Necklaces" },
  ],
};

export const editCatagoryValues = [
  { option: "Update Design Class", updateCartData: "UpdateDesignClass", uptdateTypeKey: "designClass" },
  { option: "Update Sub-category", updateCartData: "UpdateSubCategory", uptdateTypeKey: "subCategoryName" },
  { option: "Update Make Type", updateCartData: "UpdateMakeType", uptdateTypeKey: "makeType" },
  { option: "Restrict Design", updateCartData: "RestrictDesign", uptdateTypeKey: "restrict" },
];

export const editCatagoryData = (option) => {
  let editName = editCatagoryValues.find((item) => item.option === option);
  return editName?.updateCartData;
};

export const editStyleNumberData = (updateCatagory) => {
  const editStyleNumbers = JSON.parse(sessionStorage.getItem(updateCatagory)) || [];
  const styleNumbers = editStyleNumbers.map((item) => item.styleNumber);
  return styleNumbers;
};

export const sendDataForEditCatagory = (updateCatagory, selectedData) => {
  let uptdateTypeKey = editCatagoryValues?.find((item) => item.updateCartData === updateCatagory)?.uptdateTypeKey;
  return {
    styleNumbers: editStyleNumberData(updateCatagory),
    updateType: uptdateTypeKey || null,
    updateValue: selectedData || null,
  };
};

export const fetchSubCatagoryList = async (location) => {
  try {
    const path = location?.pathname.split("/");
    const categoryName = path[2].trim();
    const subCategoryName = path[3].trim();
    const res = await apiClient.get(`/api/v1/styles/getSubCatagoryList`, {
      params: { catagoryName: categoryName, subCategoryName: subCategoryName },
    });
    return res?.data?.data;
  } catch (error) {
    console.error("Error fetching sub-category list:", error);
    return [];
  }
};

export const fetchSubCatagoryStyleCount = async (location) => {
  try {
    const path = location?.pathname.split("/");
    const categoryName = path[2].trim();
    const subCategoryName = path[3].trim();
    const res = await apiClient.get(`/api/v1/styles/getRespectiveSubCatagoryStyleCount`, {
      params: { catagoryName: categoryName, subCategoryName: subCategoryName },
    });
    return res?.data?.data;
  } catch (error) {
    console.error("Error fetching sub-category style Count:", error);
    return 0;
  }
};

export const handleSuccessNavigate = async (location, handleClose, navigate) => {
  try {
    let name = location?.state?.name;
    if (location?.state?.updateCatagory.trim() === "UpdateSubCategory" || location?.state?.updateCatagory.trim() === "RestrictDesign") {
      const count = await fetchSubCatagoryStyleCount(location);
      if (Array.isArray(name) && name.length > 1) {
        name[1] = `${name[1]?.split("-")[0].trim()} - ${count?.styleCount}`;
      }
    }
    const newState = {
      name: name || "",
      innerChnages: false,
      updateCatagory: "",
    };

    if (location?.state?.updateCatagory) {
      sessionStorage.removeItem(location.state.updateCatagory);
    }
    handleClose();
    navigate(location?.state?.backPath, { state: newState });
  } catch (error) {
    console.error("Error in handleSuccessNavigate:", error);
  }
};

export const formattedSubCategory = (subCategoryName) => {
  return subCategoryName
    ?.split(/[\s-]+/)
    ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    ?.join(" ")
    .trim();
};

export const CheckDuplicateSubCategory = (newSubCategoryName, exitSubCategory) => {
  let isExit = exitSubCategory.some((item) => formattedSubCategory(item?.value) === formattedSubCategory(newSubCategoryName));
  return isExit;
};
