// Function to find specific page details
export const findSpecificPage = (page, role, subPage) => {
  if (!role || role.isDeleted) {
    return false;
  }
  if (role && role?.isAdmin) {
    // if (subPage === "Watermark") {
    //   const pageDetails = role?.rights?.find((item) => item.menu === page);
    //   return pageDetails || false;
    // }
    return true;
  }

  const pageDetails = role?.rights?.find((item) => item.menu === page);
  return pageDetails || false;
};

// Function to check rights for a specific page
export const hasAccessPage = (pageDetails, action = null) => {
  if (pageDetails === true) {
    return true;
  }
  if (!pageDetails || !action) return false;

  return Boolean(pageDetails[action]);
};

// Function to find specific sub-page details
export const findSpecificSubPage = (pageDetails, subPage) => {
  if (pageDetails === true) {
    if (subPage === "Watermark") {
      return false;
    }
    return true;
  }

  if (!pageDetails?.subRights) {
    return false;
  }

  const subPageDetails = pageDetails.subRights.find((item) => item.subMenu === subPage);
  return subPageDetails || false;
};

// Function to check rights for a specific sub-page
export const hasAccessSubPage = (subPageDetails, action = null) => {
  if (subPageDetails === true) {
    return true;
  }

  if (!subPageDetails || !action) return false;

  return Boolean(subPageDetails[action]);
};

export const forwordRouteAccordingToRights = (role) => {
  if (!role?.isAdmin) {
    let viewAccessArrya = role?.rights
      ?.map((item) => {
        if (navObject?.map((item) => item.key).includes(item.menu) && item.view) {
          return {
            menu: item.menu,
            view: item.view,
          };
        }
        return false;
      })
      .filter((item) => item);
    let nextRoute = navObject?.find((item) => item.key === viewAccessArrya[0]?.menu)?.value || null;
    return nextRoute;
  }

  return "/Catalog";
};

export const navObject = [
  { key: "Catalog", value: "/Catalog" },
  { key: "Category", value: "/Category" },
  { key: "Collection", value: "/Collection" },
  { key: "Sets", value: "/Sets" },
  { key: "Settings", value: "/Setting" },
];
