import React from "react";
import ChildHeaderLayout from "./childHeaderLayout";
import apiClient from "lib/api-client";
import { useQuery } from "@tanstack/react-query";
import config from "configs/config";
import Skeleton from "@mui/material/Skeleton";

const CatalogChildLayoutWraaper = ({ pageName, icon, deIcon }) => {
  const fetchCatalogData = async () => {
    const response = await apiClient.get(`${config.baseUrl}/api/v1/catalog/getTotalCatalogCount`, {
      params: {
        isArchive: false,
      },
    });
    return response?.data;
  };

  const { isLoading, data } = useQuery({
    queryKey: ["catalogCountData"], // Query key
    queryFn: fetchCatalogData, // Fetch function
    cacheTime: 1000 * 60 * 60 * 24, // Cache data for 24 hours
    staleTime: 1000 * 60 * 60 * 24, // Consider the data fresh for 24 hours
    refetchOnMount: false, // Prevent refetching on mount
    refetchOnWindowFocus: false, // Prevent refetching when the window is focused
    initialDataUpdatedAt: 0, // Force the query to treat the data as stale
  });

  if (isLoading) {
    return <Skeleton animation="wave" height={60} width="20%" sx={{ margin: 0, padding: 0, marginLeft: "20px" }} />;
  }
  return <ChildHeaderLayout pageName={pageName} icon={icon} deIcon={deIcon} categoryCountWithDesgin={data || {}} />;
};

export default CatalogChildLayoutWraaper;
