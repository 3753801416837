import { useGlobalContext } from "context/GlobalContext";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MenuItem, Select } from "@mui/material";
import Button from "components/ui/Button";
import { editCatagoryData } from "utils/editCatagoryHelper";

function EditCatagoryBtn({ location }) {
  const { editCatagoryBtn, editCatagoryBtnOption, setIsEditCatagorySelectValue, setEditCatagoryBtn } = useGlobalContext();
  const [selectValue, setSelectValue] = useState("");
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleChageFun = (event) => {
    const option = event.target.value;
    setSelectValue(option);
    setIsEditCatagorySelectValue(true);
    let state = {
      ...location.state,
      innerChnages: true,
      backPath: location.pathname,
      newName: option,
      isEditCatagory: true,
      updateCatagory: editCatagoryData(option),
    };

    navigate(location.pathname, { state });
    setEditCatagoryBtn(false);
  };
  useEffect(() => {
    setSelectValue("");
  }, []);

  return (
    editCatagoryBtn &&
    editCatagoryBtnOption?.length > 0 &&
    editCatagoryBtnOption.some((item) => item.view) && (
      <div className="child-header-create-btn">
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          fullWidth
          value={selectValue}
          onChange={(e) => handleChageFun(e)}
          displayEmpty
          renderValue={(selected) => {
            if (selected === "") {
              return <span>Edit Design</span>;
            }
            return selected;
          }}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          open={open}
          MenuProps={{
            PaperProps: {
              sx: {
                marginLeft: "-45px",
                padding: "0px 5px",
                marginTop: "3px",
                backgroundColor: "#FFFFFF",
                color: "#000000",
                borderRadius: "5px",
                "& .MuiList-root": {
                  "& .MuiMenuItem-root": {
                    fontFamily: "Karla",
                    fontSize: "18px",
                    lineHeight: "21.04px",
                    fontWeight: 400,
                    height: "41px",
                    margin: "2px",
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    "&:hover": {
                      backgroundColor: "#444444",
                      color: "#FFFFFF",
                      borderRadius: "5px",
                      margin: "2px",
                    },
                  },
                },
              },
            },
          }}
          sx={{
            fontFamily: "Karla",
            fontSize: "18px",
            lineHeight: "21.04px",
            fontWeight: 400,
            height: "41px",
            borderRadius: "5px",
            textTransform: "none",
            color: open ? "#000000" : "#FFFFFF", // Change text color based on open state
            backgroundColor: open ? "#FFFFFF" : "#000000",
            "&:hover": {
              backgroundColor: "#FFFFFF",
              color: "#000000",
            },
            border: "1px solid #FFFFFF",
            width: "115px",
            padding: "0px 10px",

            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            "& .MuiSelect-select": {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              padding: "10px !important",
            },
            "& .MuiSelect-icon": {
              display: "none",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            "&:focus": {
              backgroundColor: "transparent",
            },
          }}
        >
          {editCatagoryBtnOption?.map(
            (item) =>
              item.view && (
                <MenuItem key={item.option} value={item.updateHeading}>
                  {item.option}
                </MenuItem>
              )
          )}
        </Select>
      </div>
    )
  );
}

export default EditCatagoryBtn;
