import React, { useEffect, useState } from "react";

const useProductInCatalogCheck = (product, isEditCatagory, updateCatagoryData) => {
  const [btnTitle, setBtnTitle] = useState(isEditCatagory ? "Select" : "Add");
  const [presentInCatalog, setPresentInCatalog] = useState(false);

  useEffect(() => {
    const updateCatalogProductsData = JSON.parse(sessionStorage.getItem("updateProductsData")) || [];

    if (updateCatalogProductsData.length > 0) {
      const isAlreadyAdded = updateCatalogProductsData.find((curProduct) => {
        const productId = curProduct._id || curProduct.id;
        return productId === product.id;
      });

      if (isAlreadyAdded) {
        setBtnTitle("Added");
        setPresentInCatalog(true);
      }
    }

    //check the cart data its presents or not
    const data = JSON.parse(sessionStorage.getItem(isEditCatagory ? updateCatagoryData : "CartData")) || [];
    if (data.length > 0) {
      const isAlreadyAdded = data.find((curProduct) => {
        // const productId = product._id || curProduct.id;
        const productId = curProduct._id || curProduct.id;
        return productId === product.id;
      });

      if (isAlreadyAdded) {
        setBtnTitle(isEditCatagory ? "Selected" : "Added");
      }
    }
  }, [product.id]);

  return { setBtnTitle, btnTitle, presentInCatalog };
};

export default useProductInCatalogCheck;
