import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import SetCard from "components/ui/SetCard";
import TrayModal from "components/ui/TrayModal";
import RemoveModal from "components/ui/RemoveModal/RemoveModal";
import { ReactComponent as Background } from "assets/icons/bg.svg";
import { useArchivesSets } from "../apis/get-archives-sets";
import { useInView } from "react-intersection-observer";
import { transformArchivesSetData } from "utils/transformdata";
import { useUnarchiveSet } from "../apis/unarchive-set";
import ProductSkeleton from "components/ui/Skeleton/ProductSkeleton";
import { findSpecificPage, findSpecificSubPage, hasAccessPage, hasAccessSubPage } from "utils/checkUserRigths";
import { usePermissions } from "context/PermissionsContext";
import { useDeleteSet } from "../apis/deleteSet";

const SetArchives = () => {
  const [unarchive, setUnarchive] = useState(false);
  const [deleteSetModal, setDeleteSetModal] = useState(false);
  const [viewset, setViewset] = React.useState({ id: "" });
  const [showTrayModal, setShowTrayModal] = React.useState(false);
  const selectedSetArchive = React.useRef(null);
  const toggleDrawer = (data) => {
    setShowTrayModal(true);
    setViewset({
      id: data,
    });
  };

  const permission = usePermissions();

  const { mutate: unArchiveSet } = useUnarchiveSet({
    onCompleted: () => {
      setUnarchive(false);
    },
  });

  const { mutate: deleteSet } = useDeleteSet({
    onCompleted: () => {
      setDeleteSetModal(false);
    },
  });

  const handleUnarchive = (title) => {
    if (selectedSetArchive.current) {
      const setId = selectedSetArchive.current;
      if (title === "Unarchive") {
        unArchiveSet({ setId });
      } else if (title === "Delete") {
        deleteSet({ setId });
      }
    } else {
      alert("No set ID selected for archiving");
    }
  };

  const modalBtnInfo = React.useMemo(
    () => [
      {
        btnName: "Unarchive",
        bgColor: "#E77B7B",
        click: () => handleUnarchive("Unarchive"),
      },
      {
        btnName: "Keep",
        bgColor: "#50C878",
        click: () => setUnarchive(false),
      },
    ],
    []
  );

  const deleteModalBtnInfo = React.useMemo(
    () => [
      {
        btnName: "Delete",
        bgColor: "#E77B7B",
        click: () => handleUnarchive("Delete"),
      },
      {
        btnName: "Keep",
        bgColor: "#50C878",
        click: () => setDeleteSetModal(false),
      },
    ],
    []
  );

  const unArchivesbuttons = [
    {
      btnId: 1,
      title: "Delete",
      view: hasAccessPage(findSpecificPage("Sets", permission), "archive"),
    },
    {
      btnId: 2,
      title: "Unarchive",
      view: hasAccessSubPage(findSpecificSubPage(findSpecificPage("Settings", permission), "Archives"), "add"),
    },
  ];

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading, isError, error } = useArchivesSets({
    limit: 12,
  });

  const { ref, inView } = useInView({
    triggerOnce: false,
    threshold: 1.0,
  });

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, fetchNextPage]);

  if (isLoading) return <ProductSkeleton count={20} />;
  if (isError) return <div>Error: {error.message}</div>;

  return (
    <>
      <Grid
        container
        className="Grid-padding"
        direction="row"
        justifyContent="flex-start"
        sx={{
          flex: "1 1 1",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: "30px",
          rowGap: "32px",

          maxHeight: "78vh",
          // border: "5px solid red",
          overflowY: "scroll",
          "&::-webkit-scrollbar": {
            width: "0px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "transparent",
          },
        }}
      >
        {data?.pages?.map((page) =>
          page?.sets?.map((set, _ind) => (
            <Grid item className="product" key={set._id}>
              <SetCard
                data={transformArchivesSetData(set)}
                isBtn={"click"}
                openDrawer={() => toggleDrawer(set._id)}
                // btnTitle={"Unarchive"}
                btnInfo={unArchivesbuttons}
                buttonClick={(title) => {
                  selectedSetArchive.current = set._id;
                  if (title === "Delete") {
                    setDeleteSetModal(true);
                  } else {
                    setUnarchive(true);
                  }
                }}
              />
            </Grid>
          ))
        )}
        <div ref={ref} style={{ height: "20px", marginTop: "10px" }} />
        {isFetchingNextPage && <ProductSkeleton count={12} />}
      </Grid>

      <RemoveModal open={unarchive} setOpen={setUnarchive} btnInfo={modalBtnInfo} heading="Do you want to Unarchive this Set?" />
      <RemoveModal open={deleteSetModal} setOpen={setDeleteSetModal} btnInfo={deleteModalBtnInfo} heading="Do you want to Delete this Set?" />
      <TrayModal cardId={viewset} open={showTrayModal} setOpen={setShowTrayModal} trayName={viewset.name} isView={true} />

      {data?.pages?.length === 0 ||
        (!data?.pages.some((page) => page?.sets?.length > 0) && (
          <div
            style={{
              display: "flex",
              textAlign: "center",
              margin: "auto",
              padding: "auto",
              justifyContent: "center",
              alignItems: "center",
              height: "78vh",
            }}
          >
            <Background />
          </div>
        ))}
    </>
  );
};

export default SetArchives;
