import { Box, styled, InputBase } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import Button from "components/ui/Button";
import EditCatagoryModal from "components/ui/EditCatagoryModal/EditCatagoryModal";
import RemoveModal from "components/ui/RemoveModal/RemoveModal";
import { useGlobalContext } from "context/GlobalContext";
import { useSettingContext } from "context/SettingContext";
import SortContext from "context/SortContext";
import { useEditCatagory } from "hooks/useEditCatagory";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { handleSuccessNavigate, handleSuccessSettingRestricatNavigate, sendDataForEditCatagory } from "utils/editCatagoryHelper";

const CancelButton = styled(Button)(({}) => ({
  fontFamily: "Poppins",
  fontWeight: 500,
  fontSize: "18px",
  lineHeight: "22px",
  height: "42px",
  borderRadius: "5px",
  color: "#E77B7B",
  border: "1px solid #E77B7B",
  textTransform: "none",
  backgroundColor: "transparent",
  "&:hover": {
    backgroundColor: "#E77B7B",
    color: "black",
  },
}));

const StyledButton = styled(Button)(({ bgcolor, variant, disabled }) => ({
  fontFamily: "Poppins",
  fontWeight: 500,
  fontSize: "18px",
  lineHeight: "22px",
  height: "42px",
  width: `${variant === "Next" ? "100px" : "auto"}`,
  borderRadius: "5px",
  color: `${variant === "Next" ? "#50C878" : "#ffffff"}`,
  border: `1px solid ${variant === "Next" ? "#50C878" : "#ffffff"}`,
  textTransform: "none",
  backgroundColor: "transparent",
  "&:hover": {
    backgroundColor: `${variant === "Next" ? "#50C878" : "transparent"}`,
    color: `${variant === "Next" ? "black" : "#ffffff"}`,
  },
  "&.Mui-disabled": {
    backgroundColor: "#000000",
    color: "#8B8B8B",
    border: "1px solid #8B8B8B",
  },
}));

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: "#FFFFFF",
  "&:hover": {
    backgroundColor: "#f0f0f0",
  },
  marginLeft: 0,
  width: "100%",
  boxShadow: "inset 0px 4px 20px 0px #00000040",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "black",
  height: "40px",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 1),
    transition: theme.transitions.create("width"),

    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "40ch",
    },
  },
}));

const isCatagoryEdit = (isEditCatagoryData) => {
  return isEditCatagoryData && isEditCatagoryData.length > 0 ? false : true;
};

const RestricateCategoryHeader = () => {
  const [open, setOpen] = useState(false);
  const [restricModalOpen, setRestricModalOpen] = useState(false);
  const { setSearch, search } = useContext(SortContext);
  const location = useLocation();
  const navigate = useNavigate();
  let [state, setState] = useState({});
  const quaryClient = useQueryClient();
  const { isEditCatagoryData, setIsEditCatagoryData } = useGlobalContext();
  const { setRestrictBtn } = useSettingContext();
  const { mutate: updateCatagoryFun } = useEditCatagory({
    onCompleted: () =>
      handleSuccessSettingRestricatNavigate(
        location,
        () => {
          setRestricModalOpen(false);
          setRestrictBtn(true);
        },
        navigate
      ),
  });

  const handleCancel = () => {
    let newstate = {
      name: state?.name,
      innerChnages: false,
      updateCatagory: "",
    };
    sessionStorage.removeItem("RestrictDesign");
    setRestrictBtn(true);
    setIsEditCatagoryData([]);
    // quaryClient.invalidateQueries("designs");
    // quaryClient.removeQueries("designs");
    navigate(state.backPath, { state: newstate });
  };

  const handleSave = () => {
    if ("RestrictDesign".trim() === "RestrictDesign") {
      setRestricModalOpen(true);
    }
  };

  const handleRestrictSave = () => {
    const updateStyleNumber = sendDataForEditCatagory("RestrictDesign");
    const updateStyles = {
      ...updateStyleNumber,
      updateValue: false,
    };
    updateCatagoryFun(updateStyles);
  };

  const modalBtnInfo = useMemo(
    () => [
      {
        btnName: "Discard",
        bgColor: "#E77B7B",
        click: handleCancel,
      },
      {
        btnName: "Keep",
        bgColor: "#50C878",
        click: () => setOpen(false),
      },
    ],
    [handleCancel]
  );

  const restricModalBtnInfo = useMemo(
    () => [
      {
        btnName: "Restrict",
        bgColor: "#E77B7B",
        click: handleRestrictSave,
      },
      {
        btnName: "Keep",
        bgColor: "#50C878",
        click: () => setRestricModalOpen(false),
      },
    ],
    [handleRestrictSave]
  );

  useEffect(() => {
    setState(location.state);
  }, [location]);

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", gap: 4 }}>
        <Search>
          <StyledInputBase placeholder="Search" onChange={(e) => setSearch(e.target.value)} value={search} inputProps={{ "aria-label": "search" }} sx={{ height: "40px", borderRadius: "5px" }} />
        </Search>

        <StyledButton onClick={handleSave} disabled={isCatagoryEdit(isEditCatagoryData)} variant={"Next"}>
          Next
        </StyledButton>

        <CancelButton onClick={isCatagoryEdit(isEditCatagoryData) ? handleCancel : () => setOpen(true)}>Cancel</CancelButton>
      </Box>
      <RemoveModal open={open} setOpen={setOpen} btnInfo={modalBtnInfo} heading="Do you want to cancel the changes?" />
      <RemoveModal open={restricModalOpen} setOpen={setRestricModalOpen} btnInfo={restricModalBtnInfo} heading={`Are you sure you want to unrestrict ${isEditCatagoryData.length || 0} designs?`} />
    </>
  );
};

export default RestricateCategoryHeader;
