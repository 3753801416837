import React, { useEffect, useState } from "react";
import Home from "pages/Home";
import { colors, Grid } from "@mui/material";
import { ReactComponent as GoldIcon } from "assets/icons/Design Card Icon/Goldbar.svg";
import { ReactComponent as LabourIcon } from "assets/icons/Design Card Icon/babor.svg";
import { ReactComponent as BronzeClass } from "assets/icons/Design Card Icon/brozen.svg";
import { ReactComponent as DiamonIcon } from "assets/icons/Design Card Icon/diamond.svg";
import { ReactComponent as SilverClass } from "assets/icons/Design Card Icon/sliver.svg";
import { ReactComponent as GoldClass } from "assets/icons/gold.svg";
import { ReactComponent as SetIcon } from "assets/sidebar/enable/sets.svg";
import RemoveProduct from "components/ui/RemoveModal/RemoveModal";
import { ReactComponent as CalenderIcon } from "assets/icons/Calender.svg";
import TrayModal from "components/ui/TrayModal";
import { Toast, ProductCard } from "components/ui";
import "./collectionproduct.css";
import { useLocation } from "react-router-dom";
import { useGlobalContext } from "context/GlobalContext";
import productData from "db/productsData.json";
import useProductInCatalogCheck from "hooks/useProductInCatalogCheck";
import { findSpecificPage, hasAccessPage } from "utils/checkUserRigths";
import { usePermissions } from "context/PermissionsContext";

function Product({ product, index, checkedItems, handleCheckboxChange }) {
  const { setBtnTitle, btnTitle, presentInCatalog } = useProductInCatalogCheck(product);
  const [showTrayModal, setShowTrayModal] = useState(false);
  const [selectedCartProducts, setSelectedCartProducts] = useState({});
  // const [checkedItems, setcheckedItems] = useState({});
  const [toastOpen, setToastOpen] = useState(false);
  const { setCountCart, countCart } = useGlobalContext();

  const location = useLocation();
  const innerChnages = location?.state?.innerChnages;
  const isEdit = location?.state?.edit || innerChnages;
  const permission = usePermissions();
  const hasAccessDeleteCollection = hasAccessPage(findSpecificPage("Collection", permission), "archive");

  const list = [
    {
      id: 1,
      icon: <GoldClass />,
      text: "gold",
      iconBtn: "",
    },

    {
      id: 2,
      icon: <DiamonIcon />,
      text: "diamond",
      click: () => console.log("HELLO 1"),
    },
    {
      id: 3,
      icon: <GoldIcon />,
      text: "silver",
    },
    {
      id: 4,
      icon: <LabourIcon />,
      text: "user",
    },
    {
      id: 5,
      icon: <CalenderIcon />,
      text: "date",
    },
  ];

  const addtoCart = () => {
    setToastOpen(true);
    setBtnTitle("Added");
  };

  const removeCart = (productToRemove) => {
    setBtnTitle("Add");
    let getCartData = JSON.parse(sessionStorage.getItem("CartData")) || [];
    getCartData = getCartData.filter((item) => (item.id || item._id) !== productToRemove.id);
    sessionStorage.setItem("CartData", JSON.stringify(getCartData));
    setCountCart((prevCount) => prevCount - 1);
  };

  const handleCloseToast = (Reason) => {
    if (Reason === "clickaway") {
      return;
    }
    setToastOpen(false);
  };

  const toggleDrawer = (open) => {
    setShowTrayModal(open);
  };

  useEffect(() => {
    setSelectedCartProducts(() => ({
      ...product,
      isAddedInCart: true,
    }));
  }, []);

  return (
    <>
      <Grid item className="product">
        <ProductCard
          list={list}
          data={product}
          addtoCart={addtoCart}
          btnTilte={btnTitle}
          // isBtn={true}
          style={{ maxWidth: isEdit ? "430px" : "430px", minWidth: isEdit ? "430px" : "430px" }}
          selectedCartProducts={selectedCartProducts}
          hoverEffect={hasAccessDeleteCollection ? !innerChnages : false}
          checkedItems={checkedItems}
          handleCheckboxChange={handleCheckboxChange}
          {...(innerChnages && {
            isBtn: innerChnages,
            addtoCart: addtoCart,
            btnTilte: btnTitle,
            removeCart: removeCart,
            presentInCatalog: presentInCatalog,
          })}
        />
        <Toast
          open={toastOpen}
          msg={`Design Has Been Added To Cart ${countCart} `}
          autoHideDuration={2000}
          onClose={handleCloseToast}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          ContentProps={{
            sx: { backgroundColor: "#50C878" },
          }}
        />
      </Grid>
      <TrayModal cardId={product.id} open={showTrayModal} setOpen={toggleDrawer} trayName={product.gold} />
    </>
  );
}

export default Product;
