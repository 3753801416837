// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.zoom-container {
  position: relative;
  width: 435px; /* Container dimensions */
  height: 435px;
  overflow: hidden; /* Keep zoom effect within bounds */
  cursor: pointer;
}

.zoom-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: transform 0.2s ease; /* Smooth zoom transition */
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/imageModal.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,YAAY,EAAE,yBAAyB;EACvC,aAAa;EACb,gBAAgB,EAAE,mCAAmC;EACrD,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,+BAA+B,EAAE,2BAA2B;AAC9D","sourcesContent":[".zoom-container {\n  position: relative;\n  width: 435px; /* Container dimensions */\n  height: 435px;\n  overflow: hidden; /* Keep zoom effect within bounds */\n  cursor: pointer;\n}\n\n.zoom-image {\n  width: 100%;\n  height: 100%;\n  object-fit: contain;\n  transition: transform 0.2s ease; /* Smooth zoom transition */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
