import React, { useState } from "react";
import Modal from "./Modal";
import { IconButton, Typography } from "@mui/material";
import { getIcon } from "utils/iconsConfig";
import { useLocation } from "react-router-dom";
import "./imageModal.css";
import watermarkImageUrl from "../../assets/img/watermark.png";
import Image from "assets/img/default-img.jpg";

const ImageModal = ({ open, handleClose, data, hasAccessToSeeWatermark }) => {
  const location = useLocation();
  const splitedPaths = location.pathname.split("/");
  const needMargin = splitedPaths.includes("Set") || (splitedPaths.includes("Sets") && splitedPaths[splitedPaths.length - 1] === "Sets");

  const [zoomStyle, setZoomStyle] = useState({});

  const handleMouseMove = (e) => {
    const rect = e.target.getBoundingClientRect();
    const x = ((e.clientX - rect.left) / rect.width) * 100;
    const y = ((e.clientY - rect.top) / rect.height) * 100;

    setZoomStyle({
      transform: "scale(2.5)",
      transformOrigin: `${x}% ${y}%`,
    });
  };

  const handleMouseLeave = () => {
    setZoomStyle({ transform: "scale(1)", transformOrigin: "center" });
  };

  return (
    <Modal open={open} handleClose={handleClose} style={{ width: "40vw", height: "85vh", mr: needMargin ? 60 : null, boxShadow: "none" }}>
      <div style={{ display: "flex", justifyContent: "space-between", padding: "5px 5px" }}>
        <Typography id="modal-drawer-title" variant="h5" style={{ fontFamily: "Poppins", fontWeight: "500", fontSize: "24px" }}>
          {data.designNo}
        </Typography>
        <IconButton disableFocusRipple disableRipple sx={{ p: 0 }} onClick={handleClose}>
          {getIcon("close")}
        </IconButton>
      </div>

      <div style={{ width: "100%", height: "95%", marginLeft: "-4px", marginTop: "10px" }} className="zoom-container">
        <img
          className="zoom-image"
          src={data?.imgUrl ? data?.imgUrl : Image}
          alt="Zoomable"
          style={zoomStyle}
          onMouseMove={handleMouseMove}
          onMouseLeave={handleMouseLeave}
          onContextMenu={(e) => e.preventDefault()}
          onTouchStart={(e) => e.preventDefault()}
          draggable="false"
        />
        {hasAccessToSeeWatermark && open && data?.imgUrl && (
          <div
            style={{
              position: "absolute",
              top: 10,
              left: 41,
              width: "85%",
              height: "94%",
              backgroundImage: `url(${watermarkImageUrl})`,
              backgroundRepeat: "repeat",
              backgroundSize: "400px 400px",
              opacity: 1,
              pointerEvents: "none",
            }}
          />
        )}
      </div>
    </Modal>
  );
};

export default ImageModal;
