import { FormControl, FormControlLabel, Grid, Radio, RadioGroup, Typography, styled } from "@mui/material";
import React, { useState } from "react";
import Modal from "../Modal";
import Button from "../Button";
import { useLocation, useNavigate } from "react-router-dom";
import SortContext from "context/SortContext";
import { useGlobalContext } from "context/GlobalContext";

const CustomButton = styled(Button)(({}) => ({
  width: "100%",
  fontFamily: "poppins",
  fontWeight: 500,
  fontSize: "18px",
  lineHeight: "27px",
  height: "50px",
  borderRadius: "5px",
  color: "#00000",
  border: `1px solid #ffffff `,
  textTransform: "none",
  backgroundColor: "#50C878",
  boxShadow: "none",
  "&:hover": {
    backgroundColor: "#50C878",
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
  },
  "&.Mui-disabled": {
    backgroundColor: "#444444",
    color: "#000000",
    border: "1px solid #444444",
  },
}));

const ViewDetails = ({ open, setOpen, list, heading, btnName, imgUrl }) => {
  const { isEdit } = React.useContext(SortContext);
  const [currentValue, setCurrentValue] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const state = location.state;
  const innerChnages = state && state.innerChnages ? state.innerChnages : false;
  const editCatalog = state && state.edit ? state.edit : false;

  const splitedPaths = location.pathname.split("/");
  const needMargin = splitedPaths.includes("Set") || (splitedPaths.includes("Sets") && splitedPaths[splitedPaths.length - 1] === "Sets");

  const onChange = (event) => {
    // const selectedValue = event.target.value;

    const selectedItem = list.find((item) => String(item._id) === String(event.target.value));
    // setCurrentValue(selectedItem);
    setCurrentValue((prev) => (prev === selectedItem ? "" : selectedItem));
  };

  // const onChange = (event) => {
  //   const value = event.target.value;
  //   setSelectedSortValue((prev) => (prev === value ? "" : value));
  // };
  const handleClose = () => {
    setOpen(false);
  };

  // const encodedName = encodeURIComponent(ele.collectionDetails.collectionName); // Encode the name
  // const state = {
  //   ...location.state,
  //   name: [`${ele.collectionDetails.collectionName}`],
  //   collection: collectionPresent === undefined || null ? true : collectionPresent,
  // };
  // navigate(`/Collection/${encodedName}`, { state });

  const handleCollectionNavigation = () => {
    const encodedName = encodeURIComponent(currentValue?.name);
    const state = {
      name: [`${currentValue?.name}`],
      imgUrl: imgUrl,
      styleNumber: heading.split(" ")[0],
    };

    const newTab = window.open(`/Collection/${encodedName}`, "_blank");
    newTab.onload = () => {
      newTab.history.replaceState(state, "");
    };
    handleClose();
  };

  const handleSetNavigation = () => {
    let setNumberAndCount = encodeURIComponent(`SET-${currentValue?.name.split("-")[1]}-${currentValue.numOfStyles}`);
    const state = {
      name: [`SET-${currentValue?.name.split("-")[1]}-${currentValue.numOfStyles}`],
      imgUrl: currentValue.imgUrl,
      styleNumber: currentValue?.styleNumber,
    };

    const newTab = window.open(`/Sets/${currentValue?._id}?setNumber=${setNumberAndCount}`, "_blank");
    newTab.onload = () => {
      newTab.history.replaceState(state, "");
    };
  };

  return (
    <Modal open={open} handleClose={handleClose} style={{ width: "345px", mr: needMargin ? 60 : null }}>
      <Grid container spacing={1.5}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Typography className="catalogue-share-link-modal-heading">{heading}</Typography>
        </Grid>

        {/* {isEdit === "click" || innerChnages || editCatalog ? ( */}
        {isEdit === "click" ? (
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12} mt={0} p={0}>
            {list?.map((el) => (
              <Typography fontFamily={"Karla"} fontWeight={400} fontSize={"18px"} lineHeight={"21px"} sx={{ margin: "1.5rem 0 0 0" }}>
                {el.name}
              </Typography>
            ))}
          </Grid>
        ) : (
          <>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} p={0} mt={1} mb={1.5}>
              <FormControl>
                <RadioGroup value={currentValue?._id || ""}>
                  {list?.map((item) => (
                    <FormControlLabel
                      key={item._id}
                      value={item._id}
                      control={<Radio onClick={onChange} disableRipple disableFocusRipple sx={{ color: "black", "&.Mui-checked": { color: "black" }, paddingLeft: "0px" }} />}
                      label={item.name}
                      componentsProps={{
                        typography: {
                          sx: {
                            fontSize: "18px",
                            fontWeight: 400,
                            color: "black",
                            fontFamily: "Karla",
                            lineHeight: "21.5px",
                          },
                        },
                      }}
                      sx={{ margin: 0, paddingBottom: 0, paddingTop: 0, gap: "1rem" }}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} display="flex" justifyContent="center" sx={{ width: "100%" }}>
              <CustomButton disabled={!currentValue} disableRipple disableFocusRipple variant="contained" onClick={btnName == "View Set" ? handleSetNavigation : handleCollectionNavigation}>
                {btnName}
              </CustomButton>
            </Grid>
          </>
        )}
      </Grid>
    </Modal>
  );
};

export default ViewDetails;
