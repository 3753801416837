import React, { useMemo, useState} from "react";
import { Toolbar, InputBase, IconButton, Badge, Button } from "@mui/material";
import CartIcon from "components/Icons/CartIcon";
import NotificationIcon from "components/Icons/NotificationIcon";
import { ReactComponent as LogoutIcon } from "assets/icons/logout.svg";
import { styled } from "@mui/material/styles";
import { useLocation, useNavigate } from "react-router-dom";
import RemoveModal from "components/ui/RemoveModal/RemoveModal";
import SyncModal from "components/ui/RemoveModal/RemoveModal";
import { useSettingContext } from "context/SettingContext";
import RestricateCategoryHeader from "./RestricateCategoryHeader";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: "#FFFFFF",
  "&:hover": {
    backgroundColor: "#f0f0f0",
  },
  marginLeft: 0,
  width: "100%",
  boxShadow: "inset 0px 4px 20px 0px #00000040",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "black",
  height: "40px",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 1),
    transition: theme.transitions.create("width"),

    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "40ch",
    },
  },
}));

const SettingHeader = () => {
  const [logoutModal, setLogoutModal] = React.useState(false);
  const { setSearch,syncModal,setSyncModal,ReverAlltModal,setRevertAllModal,restrictBtn} = useSettingContext();
  const location = useLocation();
  const path = location.pathname;
  const navigate = useNavigate();

  console.log("path",path)

  const isRestricted = location?.state?.isRestricted || false;

  function handlelogout() {
    sessionStorage.removeItem("user");
    sessionStorage.removeItem("userData");
    localStorage.removeItem("userData");
    navigate("/login");
  }

  const modalBtnInfo = React.useMemo(
    () => [
      {
        btnName: "log out",
        bgColor: "#E77B7B",
        click: handlelogout,
      },
      {
        btnName: "Keep",
        bgColor: "#50C878",
        click: () => setLogoutModal(false),
      },
    ],
    []
  );

  const SyncBtnInfo = React.useMemo(
    () => [
      {
        btnName: "Discard",
        bgColor: "#E77B7B",
        click: ()=>setSyncModal(false),
      },
      {
        btnName: "Confirm",
        bgColor: "#50C878",
        click: () =>"",
      },
    ],
    []
  );

  const RevertBtnInfo = React.useMemo(
    () => [
      {
        btnName: "Discard",
        bgColor: "#E77B7B",
        click: ()=>setRevertAllModal(false),
      },
      {
        btnName: `${ReverAlltModal?'revertAll':"Confirm"}`,
        bgColor: "#50C878",
        click: () =>"",
      },
    ],
    []
  );

  const renderHeader = () => {
    switch (true) {
      case path.includes("/missing-images") || path.includes("/synchronize"):
        return (
          <div position="fixed" elevation={0} sx={{ backgroundColor: "#000" }}>
            <Toolbar sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center", gap: "32px" }}>
              <Search>
                <StyledInputBase placeholder="Search" inputProps={{ "aria-label": "search" }} sx={{ height: "40px", borderRadius: "5px" }} onChange={(e) => setSearch(e.target.value)} />
              </Search>
              <IconButton size="large" edge="end" color="success" disableRipple disableFocusRipple>
                <Badge color="success">
                  <CartIcon color="white" />
                </Badge>
              </IconButton>
              <IconButton size="large" color="success" disableRipple disableFocusRipple>
                <Badge color="success">
                  <NotificationIcon color="white" />
                </Badge>
              </IconButton>
              <IconButton size="large" edge="end" color="success" disableRipple disableFocusRipple onClick={() => setLogoutModal(true)}>
                <LogoutIcon />
              </IconButton>
            </Toolbar>
          </div>
        );

      case path.includes("/restricted-designs"):
        return (
          <div position="fixed" elevation={0} sx={{ backgroundColor: "#000" }}>
            <Toolbar sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center", gap: "32px" }}>
              {!isRestricted ? (
                <>
                  {restrictBtn && <Search>
                    <StyledInputBase placeholder="Search" inputProps={{ "aria-label": "search" }} sx={{ height: "40px", borderRadius: "5px" }} onChange={(e) => setSearch(e.target.value)} />
                  </Search>}
                  <IconButton size="large" edge="end" color="success" disableRipple disableFocusRipple>
                    <Badge color="success">
                      <CartIcon color="white" />
                    </Badge>
                  </IconButton>
                  <IconButton size="large" color="success" disableRipple disableFocusRipple>
                    <Badge color="success">
                      <NotificationIcon color="white" />
                    </Badge>
                  </IconButton>
                  <IconButton size="large" edge="end" color="success" disableRipple disableFocusRipple onClick={() => setLogoutModal(true)}>
                    <LogoutIcon />
                  </IconButton>
                </>
              ) : (
                <>
                  <RestricateCategoryHeader />
                </>
              )}
            </Toolbar>
          </div>
        );

      case "test":
        return <h1>Test</h1>;

      default:
        return (
          <div position="fixed" elevation={0} sx={{ backgroundColor: "#000" }}>
            <Toolbar></Toolbar>
          </div>
        );
    }
  };

  return (
    <>
      {renderHeader()}
      <RemoveModal open={logoutModal} setOpen={setLogoutModal} btnInfo={modalBtnInfo} heading="Are you sure you want to log out?" />
      <SyncModal open={syncModal} setOpen={setSyncModal} btnInfo={SyncBtnInfo} heading="Are you sure you to sync all?" />
      <SyncModal open={ReverAlltModal} setOpen={setRevertAllModal} btnInfo={RevertBtnInfo} heading="Are you sure you want to revert All?" />
    </>
  );
};

export default SettingHeader;
