import { useMutation, useQueryClient } from "@tanstack/react-query";
import apiClient from "lib/api-client";
import { getUserDetail } from "utils/getUserDetailsHelper";
import { toast } from "react-toastify";
import { getDesingsIds } from "utils/accessLocalData";

const createSet = async () => {
  try {
    const user = getUserDetail("_id");
    const IDs = await getDesingsIds();

    const body = {
      // setNumber: `SET-${generateUniqueNumber()}`,
      userRef: user,
      selectedDesignIds: IDs,
    };

    const response = await apiClient.post(`/api/v1/set/createSet`, body);
    return response.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message || "An error occurred";
    throw new Error(errorMessage);
  }
};

export const useCreateSet = ({ onCompleted }) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createSet,
    onSuccess: (data) => {
      if (data.status === 200) {
        toast.success(data.message);
        onCompleted();
        queryClient.invalidateQueries("sets");
        queryClient.removeQueries("setCountData");
      } else {
        toast.error(data.message || data.error);
      }
    },
    onError: (error) => {
      const errorMessage = error.message || "Something went wrong";
      toast.error(errorMessage);
      console.error("Error in creating set:", error);
    },
  });
};
