import { useMutation } from "@tanstack/react-query";
import apiClient from "lib/api-client";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const updateSet = async (data) => {
  try {
    const response = await apiClient.post(`/api/v1/set/removeDesignsFromSet`, data);
    return response.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message || "An error occurred";
    throw new Error(errorMessage);
  }
};

export const useUpdateSet = ({ onCompleted }) => {
  const navigate = useNavigate();
  return useMutation({
    mutationFn: updateSet,
    onSuccess: (data) => {
      onCompleted();
      navigate("/Sets");
      toast.success("Set Updated Successfully");
    },
    onError: (error) => {
      const errorMessage = error.message || "Something went wrong";
      toast.error(errorMessage);
      console.error("Error in creating set:", error);
      // console.error("Error in Edit profile", error.message);
    },
  });
};
