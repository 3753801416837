import { formatDate } from "utils/formatDate";

export const formatInfoData = (data) => {
  let newData = {};

  return {
    ...newData,
    diamond: `${data?.totalDiaWeight?.toFixed(2)} Ct / ${data?.totalDiaPcs} Pcs`,
    goldbar: `${data?.totalGoldWeight?.toFixed(2)} g / ${data?.totalGoldGrossWeight?.toFixed(2)} g`,
    date: formatDate(data?.createdAt),
  };
};
