import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";
import Card from "components/ui/Card";
import { useLocation, useNavigate } from "react-router-dom";
import { settingsRoutes } from "constants/constants";
import "./settingscreen.css";
import { ProfileModal } from "./profile/components/profile-modal";
import { useSettingContext } from "context/SettingContext";
import { usePermissions } from "context/PermissionsContext";
import { findSpecificPage, findSpecificSubPage, hasAccessPage, hasAccessSubPage } from "utils/checkUserRigths";

function SettingScreen() {
  const permissions = usePermissions();
  const findPage = findSpecificPage("Settings", permissions);
  const navigate = useNavigate();
  const location = useLocation();

  const [openProfile, setOpenProfile] = useState(false);
  const { setIsRightPage, setSelectRoleOptions, setIsLimitPage, setSelectRoleBtnName } = useSettingContext();

  const handleClick = (name, path, replace) => {
    if (name === "Profile") {
      return setOpenProfile(true);
    }

    if (name === "Rights") {
      setIsRightPage(true);
      setSelectRoleBtnName("Select Role");
    }

    if (name === "Limits") {
      setIsLimitPage(true);
      setSelectRoleBtnName("Select User");
    }

    if (replace) {
      name = replace;
    }
    const state = { ...location.state, name: [`${name}`] };
    navigate(`/Setting/${path}`, { state });
  };

  const style = {
    justifyContent: "center",
    fontFamily: "Karla",
    fontWeight: "400",
    fontSize: "20px",
    minWidth: 275,
    maxWidth: "317px",
    height: 62,
    borderRadius: "5px",
  };

  const handleCloseProfile = () => {
    setOpenProfile(false);
  };

  return (
    <>
      <Grid
        container
        className="Grid-padding"
        sx={{ paddingLeft: "18px", paddingRight: "18px" }}
        rowSpacing={{ xs: 1, sm: 2, md: 2, lg: 2, xl: 3 }}
        columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 2, xl: 3 }}
        justifyContent="space-beetween"
        alignItems="center"
      >
        {settingsRoutes.map((route, i) => {
          const { name, path, check, replace } = route;
            if (check !== "Missing Images" && check !== "Restricted Designs" && check !== "Synchronize") {
              const findSubPage = hasAccessSubPage(findSpecificSubPage(findPage, check), "view");
              if (!findSubPage) {
                return null;
              }
            }
          return (
            <Grid item xs={12} sm={12} md={4} lg={3} xl={2.3} key={i} onClick={() => handleClick(name, path, replace)}>
              <Card style={style}>
                {/* <div>{name}</div> */}
                <Typography fontSize={"20px"} fontWeight={400} fontFamily={"Karla"}>
                  {name}
                </Typography>
              </Card>
            </Grid>
          );
        })}
      </Grid>

      <ProfileModal open={openProfile} close={handleCloseProfile} />
    </>
  );
}

export default SettingScreen;
