import React, { useEffect, useState } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Modal from "../Modal";
import { Box, Checkbox, ThemeProvider, Typography, styled, Slider, Radio, Select, MenuItem } from "@mui/material";

import { useFilter } from "context/FilterContext";
import Button from "../Button";
import { Checkbox as MuiCheckbox } from "@mui/material";

import customTheme from "thems/filterModalThems";
import "./filter.css";
import renderIcon from "./renderIcon";
import { useQueryClient } from "@tanstack/react-query";
import { usePermissions } from "context/PermissionsContext";
import { findSpecificPage, findSpecificSubPage, hasAccessSubPage } from "utils/checkUserRigths";
import CustomSelect from "./CustomSelect";

const CustomButton = styled(Button)(({}) => ({
  width: "100%",
  fontFamily: "Poppins",
  fontWeight: 500,
  fontSize: "18px",
  lineHeight: "22px",
  // height: "52px",
  borderRadius: "5px",
  color: "#00000",
  textTransform: "none",
  backgroundColor: "#50C878",
  boxShadow: "none",
  "&:hover": {
    backgroundColor: "#50C878",
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
  },
  "&.Mui-disabled": {
    backgroundColor: "#444444",
    color: "#000000",
  },
}));

const StyledSlider = styled(Slider)({
  color: "#000",
  marginLeft: "15px",
  "& .MuiSlider-track": {
    backgroundColor: "#000",
  },
  "& .MuiSlider-thumb": {
    backgroundColor: "#000",
  },
});

function FilterModal({ open, setOpen }) {
  const [newKey, setNewKey] = useState("");
  const permission = usePermissions();
  const hasAccessToHighestSeller = hasAccessSubPage(findSpecificSubPage(findSpecificPage("Settings", permission), "Highest Seller Trophy"), "view");
  const hasAccessToBestSellerBorder = hasAccessSubPage(findSpecificSubPage(findSpecificPage("Settings", permission), "Best Seller Border"), "view");
  const hasAccessToSeeSales = hasAccessSubPage(findSpecificSubPage(findSpecificPage("Settings", permission), "Sales"), "view");

  const initialList = {
    Pieces: [],
    Collections: [],
    "Diamond Pieces": [],
    "Diamond Weight": [],
    "Gold Gross-Weight": [],
    "Gold Net-Weight": [],
    "Design In Collection": ["Yes", "No", "All"],
    "Design In Sets": ["Yes", "No", "All"],
    ...(hasAccessToSeeSales && hasAccessToHighestSeller ? { "Highest Seller": ["Gold", "Silver", "Bronze"] } : {}),
    ...(hasAccessToSeeSales && hasAccessToBestSellerBorder ? { "Best Seller Design": ["Yes", "No", "All"] } : {}),
    Labour: ["R - Regular", "H - High", "X - Exclusive"],
    Class: ["Gold", "Silver", "Bronze"],
  };

  const queryClient = useQueryClient();
  const { filterValues, setFilterValues, backendFilterValues, setBackendFilterValues } = useFilter(); // global filter
  //backendFilterValues : - Filter for storing original filterValues , this filter Values will at end points of slider and filterValues(setFilterValues) will at the actual slider moved at that position
  const [tempFilterValues, setTempFilterValues] = useState({
    // Labour: [],
    // Class: [],
    // "Highest Seller": [],
    // "Diamond Pieces": [],
    // "Diamond Weight": [],
    // "Gold Gross-Weight": [],
    // "Gold Net-Weight": [],
    // "Design In Collection": [],
    // "Design In Sets": [],
    // "Best Seller Design": [],
    piecesCount: [],
    collectionName: [],
  });

  const [flag, setFlag] = useState(false);
  const [showClrAllBtn, setShowClrAllBtn] = useState(false);
  const [showResetBtn, setShowResetBtn] = useState(false);

  const compareFilterChanges = (filterValues, tempFilterValues) => {
    let changeCount = 0;
    Object.keys(filterValues).forEach((key) => {
      if (Array.isArray(filterValues[key]) && Array.isArray(tempFilterValues[key])) {
        const original = filterValues[key];
        const current = tempFilterValues[key];
        if (original.length !== current.length || !original.every((val, index) => val === current[index])) {
          changeCount++;
        }
      }
    });

    return changeCount;
  };

  const handleSelectValue = (event, category) => {
    let newSelectValue = event.target.value;
    setTempFilterValues((pre) => ({
      ...pre,
      [category]: [newSelectValue],
    }));
  };

  const handleCheckboxChange = (category) => (event) => {
    const { value, checked } = event.target;

    setTempFilterValues((prev) => {
      let newValues;
      // For single-select categories
      if (category === "Design In Sets" || category === "Design In Collection" || category === "Best Seller Design") {
        newValues = checked ? [value] : [];
      } else {
        // For multi-select categories
        newValues = [...prev[category]];

        if (checked) {
          newValues.push(value); // Add the selected value
        } else {
          newValues = newValues.filter((item) => item !== value); // Remove the unchecked value
        }
      }

      return {
        ...prev,
        [category]: newValues,
      };
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClearAllFilter = () => {
    setTempFilterValues(backendFilterValues);

    setShowClrAllBtn(false);
    setShowResetBtn(false);
  };

  const handleResetFilter = (category) => {
    setTempFilterValues((prev) => ({
      ...prev,
      [category]: backendFilterValues[category],
    }));
  };

  const handleApplyFilter = () => {
    queryClient.removeQueries("collectiondesigns");
    setFilterValues(tempFilterValues);
    handleClose();
  };

  const handleSliderChange = (category, value) => {
    setTempFilterValues((prev) => ({
      ...prev,
      [category]: value,
    }));
  };

  const hasCategoryChanged = (category) => {
    return JSON.stringify(tempFilterValues[category]) !== JSON.stringify(backendFilterValues[category]);
  };

  const CircularCheckbox = styled(MuiCheckbox)(({ theme }) => ({
    ...theme.components.MuiCheckbox.styleOverrides.root,
  }));

  // Custom Radio button for round design
  const CircularRadio = styled(Radio)(({ theme }) => ({
    color: "#000", // default color for radio button
    padding: "0px",
    "&.Mui-checked": {
      // color: "#4CAF50", // Green color when checked
      color: "#50C878",
    },
  }));

  useEffect(() => {
    // const isEmpty = filterValues.Labour.length === 0 && filterValues.Class.length === 0 && filterValues["Highest Seller"].length === 0;

    // if (isEmpty) {
    //   setTempFilterValues({
    //     Labour: [],
    //     Class: [],
    //     "Highest Seller": [],
    //   });
    // }

    setTempFilterValues((prev) => {
      const updatedValues = { ...prev };

      // Include Labour and Class values
      if (filterValues?.Labour?.length >= 0) {
        updatedValues.Labour = [...filterValues.Labour];
      }
      if (filterValues?.Class?.length >= 0) {
        updatedValues.Class = [...filterValues.Class];
      }
      if (filterValues["Highest Seller"]?.length >= 0) {
        updatedValues["Highest Seller"] = [...filterValues["Highest Seller"]];
      }
      if (filterValues["Design In Collection"]?.length >= 0) {
        updatedValues["Design In Collection"] = [...filterValues["Design In Collection"]];
      }
      if (filterValues["Design In Sets"]?.length >= 0) {
        updatedValues["Design In Sets"] = [...filterValues["Design In Sets"]];
      }
      if (filterValues["Best Seller Design"]?.length >= 0) {
        updatedValues["Best Seller Design"] = [...filterValues["Best Seller Design"]];
      }
      if (filterValues?.Pieces?.length > 0) {
        updatedValues.Pieces = [...filterValues.Pieces];
      }
      if (filterValues?.Collections?.length > 0) {
        updatedValues.Collections = [...filterValues.Collections];
      }
      // if (filterValues?.piecesCount) {
      //   updatedValues.piecesCount = [];
      // }
      // if (filterValues?.collectionName) {
      //   updatedValues.collectionName = [];
      // }

      // Handle slider values
      Object.keys(filterValues).forEach((key) => {
        if (["Diamond Pieces", "Diamond Weight", "Gold Gross-Weight", "Gold Net-Weight"].includes(key)) {
          if (!prev[key]?.length && filterValues[key]?.length === 2) {
            updatedValues[key] = [...filterValues[key]]; // Set initial slider range
          }
        }
      });

      return updatedValues;
    });
  }, [filterValues]);

  useEffect(() => {
    const changes = compareFilterChanges(filterValues, tempFilterValues);
    setShowClrAllBtn(changes > 1); // Show only if more than one value is changed
    setFlag(changes > 0);
  }, [filterValues, tempFilterValues, backendFilterValues]);

  useEffect(() => {
    const changes = compareFilterChanges(backendFilterValues, tempFilterValues);
    setShowClrAllBtn(changes > 1); // Show only if more than one value is changed
    // setFlag(changes > 0);
  }, [open, tempFilterValues]);

  return (
    <ThemeProvider theme={customTheme}>
      <Modal open={open} handleClose={handleClose} style={{ width: "355px" }}>
        <div style={{ marginLeft: "10px", marginRight: "10px", width: "100%" }}>
          {/* heading part */}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              gap: "2rem",
              position: "sticky",
              zIndex: 1,
              top: 0,
              backgroundColor: "white",
              height: "3rem",
              width: "100%",
            }}
          >
            <Typography className="filter-modal-heading">Filter By</Typography>
            {showClrAllBtn && (
              <Typography className="filter-modal-subheading" sx={{ cursor: "pointer" }} onClick={handleClearAllFilter}>
                Clear All
              </Typography>
            )}
          </div>

          {/* middle part */}
          <Box
            mt={1}
            sx={{
              width: "100%",
              position: "relative",
              height: "450px",
              overflowY: "auto",
              overflowX: "hidden",
              "&::-webkit-scrollbar": {
                width: "0px",
                height: "0px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "transparent",
              },
            }}
          >
            <FormControl sx={{ width: "100%" }}>
              {Object.keys(initialList).map((category) => {
                let isKeyExit = Object.keys(filterValues).includes(category);
                if (!isKeyExit) {
                  return null;
                }

                // Check if the category needs a slider
                const isSliderCategory = ["Diamond Pieces", "Diamond Weight", "Gold Gross-Weight", "Gold Net-Weight"].includes(category);
                const isSelectCategory = ["Pieces", "Collections"].includes(category);
                return (
                  <div key={category} style={{ marginBottom: "2rem", width: "100%", display: "flex", flexDirection: "column", gap: "1rem" }}>
                    <div style={{ position: "relative", width: "100%" }}>
                      <FormLabel>{category}</FormLabel>

                      {hasCategoryChanged(category) && isSliderCategory && (
                        <FormLabel
                          className="filter-modal-subheading"
                          sx={{ position: "absolute", right: 0, top: 0, lineHeight: "30px !important", cursor: "pointer" }}
                          onClick={() => handleResetFilter(category)}
                        >
                          Reset
                        </FormLabel>
                      )}
                    </div>

                    <div className="filter-modal-control-div" style={{ width: "100%", display: "flex", flexDirection: !isSliderCategory ? "column" : "row" }}>
                      {isSliderCategory ? (
                        <div style={{ width: "85%" }}>
                          {tempFilterValues[category] && tempFilterValues[category].length === 2 && (
                            <FormLabel sx={{ marginTop: "20px" }}>
                              {tempFilterValues[category][0]} - {tempFilterValues[category][1]}
                            </FormLabel>
                          )}

                          <StyledSlider
                            value={tempFilterValues[category] || [0, 100]}
                            min={backendFilterValues[category]?.[0] || 0}
                            max={backendFilterValues[category]?.[1] || 100}
                            step={category === "Diamond Pieces" ? 1 : 0.1}
                            onChange={(event, value) => handleSliderChange(category, value)}
                            sx={{
                              "& .MuiSlider-thumb": {
                                boxShadow: "none",
                                "&:hover": {
                                  boxShadow: "none",
                                },
                                "&:focus": {
                                  boxShadow: "none",
                                },
                              },
                            }}
                          />
                        </div>
                      ) : isSelectCategory ? (
                        <div style={{ width: "100%" }}>
                          <CustomSelect
                            value={tempFilterValues[category === "Pieces" ? "piecesCount" : "collectionName"]?.[0] || ""}
                            onChange={(e) => handleSelectValue(e, category === "Pieces" ? "piecesCount" : "collectionName")}
                            options={filterValues[category]}
                            placeholder={category === "Pieces" ? "Choose Design Quantity" : "Choose Collection"}
                            menuStyles={{
                              maxHeight: "200px",
                              overflowY: "auto",
                            }}
                            width={"93%"}
                            sffixValue={category === "Pieces" ? "Pieces" : null}
                          />
                        </div>
                      ) : (
                        // Render Checkboxes

                        initialList[`${category}`]?.map((item) => {
                          const isRadio = category === "Design In Sets" || category === "Design In Collection" || category === "Best Seller Design";

                          return (
                            <FormControlLabel
                              key={item}
                              name={item}
                              control={
                                isRadio ? (
                                  <CircularRadio checked={tempFilterValues[category]?.includes(item)} onChange={handleCheckboxChange(category)} value={item} />
                                ) : (
                                  <CircularCheckbox checked={tempFilterValues[category]?.includes(item)} onChange={handleCheckboxChange(category)} value={item} />
                                )
                              }
                              label={
                                <Box display="flex" alignItems="center">
                                  {(category === "Class" || category === "Highest Seller") && renderIcon(item, category)}
                                  <Typography className={`filter-modal-items ${category === "Class" || category === "Highest Seller" ? "filter-modal-items-margin-left" : ""}`}>{item}</Typography>
                                </Box>
                              }
                            />
                          );
                        })
                      )}
                    </div>
                  </div>
                );
              })}
            </FormControl>
          </Box>
        </div>
        {/* bottom part */}
        <Box display="flex" justifyContent="center" alignItems="end" sx={{ width: "100%", position: "sticky", bottom: 0, height: "5rem", zIndex: 10 }}>
          <CustomButton disabled={!flag} variant="contained" onClick={handleApplyFilter}>
            Apply
          </CustomButton>
        </Box>
      </Modal>
    </ThemeProvider>
  );
}

export default FilterModal;
