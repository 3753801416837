import React, { useEffect, useState } from "react";
import Modal from "../Modal";
import { Grid, IconButton, Typography } from "@mui/material";
import "./infomodal.css";
import { getIcon } from "utils/iconsConfig";
import apiClient from "lib/api-client";
import { formatInfoData } from "./formatInfoData";

const fetchYearlySalesDetails = async (setId, setLoading, setError) => {
  try {
    setLoading(true);
    setError(null);
    const res = await apiClient.get(`/api/v1/set/getSetWeightInfo/${setId}`);
    return res?.data?.getSetInfo;
  } catch (error) {
    setError("Failed to fetch Yearly sales details. Please try again.");
    return null;
  } finally {
    setLoading(false);
  }
};

const InfoModal = ({ open, setOpen, setId }) => {
  const [yearlySalesData, setYearlySetData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleClose = React.useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  useEffect(() => {
    if (open && setId) {
      (async () => {
        const data = await fetchYearlySalesDetails(setId, setLoading, setError);
        if (data) {
          setYearlySetData(data || []);
        }
      })();
    }
  }, [open, setId]);

  return (
    <>
      <Modal open={open} handleClose={handleClose} style={{ width: "250px" }}>
        <Grid container spacing={2} className="info-data">
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="main-grid">
            {/* <Typography className="info-data-heading">{`Set Details ${yearlySalesData.setNumber}`}</Typography> */}
            <Typography className="info-data-heading">{`Set Details`}</Typography>
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="sub-grid">
            {loading ? (
              <h3>Loding ...</h3>
            ) : error ? (
              <h4 style={{ color: "red" }}>{error}</h4>
            ) : (
              <>
                {Object.entries(formatInfoData(yearlySalesData)).map(([key, value]) => (
                  <div key={key} className="main-div-sub-grid">
                    <div className="main-title">{getIcon(key, "black")}</div>
                    <div className="sub-title">{value}</div>
                  </div>
                ))}
              </>
            )}
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};

export default InfoModal;
