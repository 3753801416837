import React from "react";
import { ThemeProvider, useTheme } from "@mui/material/styles";
import { Box, Card, CardActions, CardContent, Grid, IconButton, Typography } from "@mui/material";
import Button from "components/ui/Button";
import useMediaQuery from "@mui/material/useMediaQuery";
import custumTheme from "thems/catalogueCardThems";
import { ReactComponent as ActiveLogIcon } from "assets/icons/CatalogueCard/active_log.svg";
import { ReactComponent as EditIcon } from "assets/icons/CatalogueCard/edit.svg";
import { ReactComponent as ViewIcon } from "assets/icons/CatalogueCard/eye.svg";
import { ReactComponent as LinkIcon } from "assets/icons/CatalogueCard/link.svg";
import { ReactComponent as RingIcon } from "assets/icons/CatalogueCard/ring.svg";
import "../styles/catalogueCard.css";
import { useLocation, useNavigate } from "react-router-dom";
import { usePermissions } from "context/PermissionsContext";
import { findSpecificPage, findSpecificSubPage, hasAccessPage, hasAccessSubPage } from "utils/checkUserRigths";
import profileImage from "../../../assets/img/profile.png";
import { keys } from "lodash";

const icons = [
  {
    iconId: 1,
    svgIcon: <RingIcon className="catalogue-card-logo" />,
  },
  {
    iconId: 2,
    svgIcon: <LinkIcon className="catalogue-card-logo" />,
  },
  {
    iconId: 3,
    svgIcon: <ViewIcon className="catalogue-card-logo" />,
  },
];

function CatalogueCard({ item, handleButtonClick }) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const location = useLocation();
  const permission = usePermissions();
  const shareCatalogView = hasAccessPage(findSpecificPage("Catalog", permission), "edit");
  const hasAccessVieCatalogActivity = hasAccessSubPage(findSpecificSubPage(findSpecificPage("Catalog", permission), "Catalog Activity"), "view");
  const hasAccessEditCatalog = hasAccessPage(findSpecificPage("Catalog", permission), "edit");
  const hasAccessAddArchiveCatalog = hasAccessSubPage(findSpecificSubPage(findSpecificPage("Settings", permission), "Archives"), "add");
  let preState = location.state;
  const handleCardClick = (isArchive) => {
    if (isArchive) {
      const state = {
        ...preState,
        name: [...preState.name, `${item.number} (${item.subname})`],
      };

      navigate(`/Setting/archives/Catalog/${item.id}`, { state });
    } else {
      let numb = typeof item.numberOfStyles === "number" && item.numberOfStyles < 10 ? `0${item.numberOfStyles}` : `${item.numberOfStyles}`;

      let catalogNumberAndCount = encodeURIComponent(`${item.number} (${item.subname}) - ${numb}`);
      const state = {
        view: true,
        name: [`${item.number} (${item.subname}) - ${numb}`],
      };

      navigate(`/Catalog/${item.id}?catalogNumberAndCount=${catalogNumberAndCount}`, { state });
    }
  };

  const buttons = [
    {
      btnId: 1,
      title: "Share Catalog",
      view: hasAccessSubPage(findSpecificSubPage(findSpecificPage("Catalog", permission), "Share Catalog"), "view"),
      add: hasAccessSubPage(findSpecificSubPage(findSpecificPage("Catalog", permission), "Share Catalog"), "add"),
    },
    {
      btnId: 2,
      title: "Settings",
      view: hasAccessPage(findSpecificPage("Catalog Setting", permission), "view"),
    },
  ];

  const unArchivesbuttons = [
    {
      btnId: 1,
      title: "Delete",
      style: { color: "#E77B7B", fontSize: "18px", border: "1px solid #E77B7B" },
      view: hasAccessPage(findSpecificPage("Catalog", permission), "archive"),
    },
    {
      btnId: 2,
      title: "Unarchive",
      style: { color: "#50C878", fontSize: "18px", border: "1px solid #50C878" },
      view: hasAccessSubPage(findSpecificSubPage(findSpecificPage("Settings", permission), "Archives"), "add"),
    },
  ];

  return (
    <>
      <ThemeProvider theme={custumTheme} key={"catalogue" + item.id}>
        <Grid
          item
          // pb={1}
          key={"catalogue-grid" + item.id}
          // sx={{
          //   // flex: "1 1 0", // Fixed width, no grow, allow shrink
          //   maxWidth: "433.00px", // Maximum width of 433px
          //   minWidth: "433.00px", // Minimum width to allow some shrink
          // }}
          // sm={12}
          // md={6}
          // lg={4}
          // xl={1}
          // xll={1}
        >
          <Card key={"catalogue-card" + item.id} sx={{ padding: "12px", maxWidth: "433px", minWidth: "433px", cursor: "pointer" }} onClick={() => handleCardClick(item.isArchive)}>
            <CardContent sx={{ padding: "0px" }}>
              <Box className={`catalague-card-flex-between-center ${isSmallScreen ? "catalague-card-flexDirection-column" : "catalague-card-flexDirection-row"} catalague-card-witdh-100`}>
                <Box className={`catalague-card-flex-between-center ${isSmallScreen ? "catalague-card-witdh-100" : "catalague-card-witdh-auto"}`}>
                  <Typography className="catelogue-card-subheading" mr={1}>
                    {item.subname?.length > 20 ? item.subname.slice(0, 22) + "...." : item.subname}
                  </Typography>
                  <Typography className="catelogue-card-number">({item.number})</Typography>
                </Box>
                {item.isArchive ? (
                  ""
                ) : (
                  <Box
                    className={`${isSmallScreen ? "catalague-card-witdh-100" : hasAccessVieCatalogActivity && hasAccessEditCatalog ? "catalague-card-witdh-20" : ""}`}
                    mt={isSmallScreen ? 1 : 0}
                    mb={isSmallScreen ? 1 : 0}
                  >
                    <Grid container className="catalague-card-flex-between-center catalague-card-witdh-100">
                      {hasAccessVieCatalogActivity && (
                        <Grid item xs={6} sm={4} className={`${isSmallScreen ? "catalague-card-flex-center-center" : "catalague-card-flex-between-center"}`}>
                          <IconButton sx={{ p: 0, pl: 2 }} size="large" color="inherit" className="btn" onClick={(e) => handleButtonClick(item, "ActiveLog", e)} disableRipple disableFocusRipple>
                            <ActiveLogIcon className="catalogue-card-logo" />
                          </IconButton>
                        </Grid>
                      )}
                      {hasAccessEditCatalog && (
                        <Grid item xs={6} sm={4} className={`${isSmallScreen ? "catalague-card-flex-center-center" : "catalague-card-flex-between-center"}`}>
                          <IconButton sx={{ p: 0, pl: 0.8 }} size="large" color="inherit" className="btn" onClick={(e) => handleButtonClick(item, "EditCatalogue", e)} disableRipple disableFocusRipple>
                            <EditIcon className="catalogue-card-logo" />
                          </IconButton>
                        </Grid>
                      )}
                    </Grid>
                  </Box>
                )}
              </Box>
              <Box>
                <Grid container className={`${isSmallScreen ? "catalague-card-witdh-100" : "catalague-card-witdh-50"}`} pl={isSmallScreen ? 0 : 0.5} mt={1}>
                  {icons.map((icon, index) => {
                    let countValue = item.iconsData[index].count;
                    return (
                      <Grid
                        key={icon.iconId}
                        item
                        xs={"16px"}
                        sm={"16px"}
                        gap={0.35}
                        className="catalague-card-flex-between-center"
                        sx={{
                          ml: index === 0 ? 0 : "10px",
                        }}
                      >
                        <Box className="catalague-card-flex-align-center">{icon.svgIcon}</Box>
                        <Typography className="catalogue-card-count">{countValue}</Typography>
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
              <Box textAlign={isSmallScreen ? "left" : "right"} mt={2}>
                <div className="">
                  <Typography className="catalogue-card-owner catalague-card-flex-end-center">
                    {item.owner}
                    <img src={item.profile ? item.profile : profileImage} alt={profileImage} style={{ width: "40px", height: "40px", borderRadius: "40px", marginLeft: "12px" }} />
                  </Typography>
                </div>
              </Box>
            </CardContent>
            {!item.isArchive ? (
              <CardActions
                sx={{
                  gap: isSmallScreen ? 0 : 3.5,
                  padding: "0px",
                  paddingTop: buttons.some((btn) => btn.view) && "12px",
                  mt: 0.5,
                }}
              >
                {buttons
                  .filter((btn) => btn.view)
                  .map((btn) => (
                    <Button
                      key={`btn${btn.btnId}${item.id}`}
                      disabled={Object.keys(btn).includes("add") ? !btn.add : false}
                      variant="contained"
                      fullWidth
                      disableElevation
                      onClick={(e) => handleButtonClick(item, btn.title, e)}
                    >
                      {btn.title}
                    </Button>
                  ))}
              </CardActions>
            ) : (
              <CardActions
                sx={{
                  gap: isSmallScreen ? 0 : 3.5,
                  padding: "0px",
                  paddingTop: hasAccessAddArchiveCatalog && "12px",
                  mt: 0.5,
                }}
              >
                {unArchivesbuttons
                  .filter((btn) => btn.view)
                  .map((btn) => (
                    <Button sx={btn.style} key={`btn${btn.btnId}${item.id}`} variant="contained" fullWidth disableElevation onClick={(e) => handleButtonClick(item, btn.title, e)}>
                      {btn.title}
                    </Button>
                  ))}
                {/* {hasAccessAddArchiveCatalog && (
                  <Button sx={{ color: "#E77B7B", fontSize: "18px", border: "1px solid #E77B7B" }} variant="contained" fullWidth disableElevation onClick={(e) => handleButtonClick(item, e)}>
                    Delete
                  </Button>
                )}
                {hasAccessAddArchiveCatalog && (
                  <Button sx={{ color: "#50C878", fontSize: "18px", border: "1px solid #50C878" }} variant="contained" fullWidth disableElevation onClick={(e) => handleButtonClick(item, e)}>
                    Unarchive
                  </Button>
                )} */}
              </CardActions>
            )}
          </Card>
        </Grid>
      </ThemeProvider>
    </>
  );
}

export default CatalogueCard;
