// import Default from "assets/img/defulat.png";
import { convertingSecondIntoHoursFormat } from "./convertingSecondIntoHoursFormat";
import { formatDate } from "./formatDate";
import Default from "assets/img/default-img.jpg";

const deginType = [
  { value: "gold", icon: "gold" },
  { value: "silver", icon: "silver" },
  { value: "bronze", icon: "bronze" },
];

const laborData = [
  { value: "R", icon: "r_labor" },
  { value: "X", icon: "x_labor" },
  { value: "H", icon: "h_labor" },
];

const cupData = [
  { value: "Gold", icon: "gold_cup" },
  { value: "Silver", icon: "silver_cup" },
  { value: "Bronze", icon: "bronze_cup" },
];

const transformData = (design) => {
  const obj = {
    id: design._id ? design._id : design.id,
    // labour: design.makeType ? design.makeType : design.labour,
    // date: design.styleDate ? new Date(design.styleDate).toLocaleDateString("en-GB") : design.date,
    date: design.styleDate ? formatDate(design?.styleDate) : design.date,
    // gold: design.styleNumber ? design.styleNumber : design.gold,
    // diamond: design.diaPcs ? `${design.diaPcs}/${design.diaWeight}` : design.diamond,
    // diamond: design.diaPcs != null && design.diaWeight != null ? `${design.diaPcs}/${design.diaWeight}` : design.diamond,
    // weight: design.grossWeight ? `${design.grossWeight}/${design.netWeight}` : design.weight,
    diamond: design.diaPcs != null && design.diaWeight != null ? `${design.diaWeight} Ct / ${design.diaPcs} Pcs` : design.diamond,
    weight: design.grossWeight ? `${design.netWeight} g / ${design.grossWeight} g` : design.weight,
    collectionDetails: design.collectionDetails || [],
    setDetails: design.setDetails || [],
    sales: design?.totalSales || 0,
    trendUp: true,
    cup: cupData.find((item) => item.value === design?.currentTrophy)?.icon || null,
    bestSeller: design?.bestSeller ? true : false,
    labour: laborData.find((item) => item.value === design?.makeType)?.icon,
    // img: design?.imageUrl,
    img: design?.transformedImageUrl,
    Orgimg: design?.imageUrl,
    designClass: deginType.find((item) => item.value === design?.designClass.toLowerCase()).icon,
    styleNumber: design?.styleNumber || null,
    totalTrophy: design?.totalTrophy || null,
  };

  return obj;
};

const transformSetData = (set) => {
  const defaultImageUrl = Default;

  // Create an array of images, slicing the first 4 and filling missing with the default image
  const designsImgs = [
    ...set?.designImages?.slice(0, 4).map((design) => ({ image: design?.transformedImageUrl })),
    // ...Array(Math.max(0, 4 - set.designImages.length)).fill({ image: defaultImageUrl }),
  ];

  // for the max makeType calculation
  let makeTypes = set?.designs[0];
  const map = new Map();
  for (let i = 0; i < makeTypes.length; i++) {
    const type = makeTypes[i].makeType;

    if (map.has(type)) {
      map.set(type, map.get(type) + 1);
    } else {
      map.set(type, 1);
    }
  }
  const mostFrequentMakeType = [...map.entries()].reduce(
    (max, [type, count]) => {
      return count > max.count ? { type, count } : max;
    },
    { type: null, count: 0 }
  );

  // for the max metal type
  const MetalTypes = set?.designs[0]?.reduce((acc, design, ind) => {
    const metalType = design?.designClass?.toLowerCase();
    if (!metalType) return acc;
    acc[metalType] = (acc[metalType] || 0) + 1;
    return acc;
  }, {});

  const maxMetal = Object.entries(MetalTypes).reduce(
    (acc, [key, val]) => {
      return val > acc.value ? { key, value: val } : acc;
    },
    { key: null, value: -Infinity }
  );

  return {
    _id: set._id,
    setNumber: set.setNumber,
    // totalDiamonds: `${set.totalDiaPcs}/${set.totalDiaWeight.toFixed(2)}`,
    // goldWeights: `${set.totalGoldGrossWeight.toFixed(2)}/${set.totalGoldWeight.toFixed(2)}`,
    totalDiamonds: `${set.totalDiaWeight.toFixed(2)} Ct / ${set.totalDiaPcs} Pcs`,
    goldWeights: `${set.totalGoldWeight.toFixed(2)} g / ${set.totalGoldGrossWeight.toFixed(2)} g`,
    numberOfStyles: set.numberOfStyles,
    // date: new Date(set.createdAt).toLocaleDateString("en-GB"),
    date: formatDate(set.createdAt),
    designsImgs,
    labour: mostFrequentMakeType.type,
    maxMetalType: maxMetal?.key,
    sales: 456,
    trendUp: true,
    cup: cupData.find((item) => item.value === "GoldCup")?.icon,
    bestSeller: false,
    // labour: laborData.find((item) => item.value === mostFrequentMakeType.type).icon,
  };
};

const transformArchivesSetData = (set) => {
  const defaultImageUrl = Default;

  // Create an array of images, slicing the first 4 and filling missing with the default image
  // const designsImgs = [...set?.designImages?.slice(0, 4).map((design) => ({ image: design.imageUrl })), ...Array(Math.max(0, 4 - set.designImages.length)).fill({ image: defaultImageUrl })];

  const designsImgs = [
    ...set.designs
      .flat()
      .slice(0, 4)
      .map((design) => ({
        image: design?.images?.[0]?.imageUrl || defaultImageUrl,
      })),
    ...Array(Math.max(0, 4 - set.designs.flat().length)).fill({ image: defaultImageUrl }),
  ];

  return {
    _id: set._id,
    setNumber: set.setNumber,
    // totalDiamonds: `${set.totalDiaPcs}/${set.totalDiaWeight.toFixed(2)}`,
    // goldWeights: `${set.totalGoldGrossWeight.toFixed(2)}/${set.totalGoldWeight.toFixed(2)}`,
    totalDiamonds: `${set.totalDiaWeight.toFixed(2)} Ct /${set.totalDiaPcs} Pcs`,
    goldWeights: `${set.totalGoldWeight.toFixed(2)} g /${set.totalGoldGrossWeight.toFixed(2)} g`,
    numberOfStyles: set.numberOfStyles,
    // date: new Date(set.createdAt).toLocaleDateString("en-GB"),
    date: formatDate(set.createdAt),
    designsImgs,
    labour: "H",
    sales: 456,
    trendUp: true,
    cup: cupData.find((item) => item.value === "GoldCup")?.icon,
    bestSeller: false,
    // labour: laborData.find((item) => item.value === "H").icon,
  };
};

//for the set tray modal
const transformDataForTrayModal = (design) => {
  const obj = {
    id: design._id ? design._id : design.id,
    // labour: design.makeType ? design.makeType : design.labour,
    // date: design.styleDate ? new Date(design.styleDate).toLocaleDateString("en-GB") : design.date,
    date: design.styleDate ? formatDate(design?.styleDate) : design.date,
    // gold: design.styleNumber ? design.styleNumber : design.gold,
    // diamond: design.diaPcs ? `${design.diaPcs}/${design.diaWeight}` : design.diamond,
    // weight: design.grossWeight ? `${design.grossWeight}/${design.netWeight}` : design.weight,
    diamond: design.diaPcs ? `${design.diaWeight} Ct / ${design.diaPcs} Pcs` : design.diamond,
    weight: design.grossWeight ? `${design.netWeight} g / ${design.grossWeight} g` : design.weight,
    collectionDetails: design.collectionDetails || [],
    setDetails: design.setDetails || [],
    Orgimg: design.images ? design?.images[0]?.imageUrl : design?.img ? design?.img : design?.imageUrl,
    img: design.images ? design?.images[0]?.transformedImageUrl : design?.img ? design?.img : design?.transformedImageUrl,

    // img: design?.transformedImageUrl,
    // Orgimg: design?.imageUrl,
    sales: design?.totalSales || 0,
    trendUp: true,
    cup: cupData.find((item) => item.value === design?.currentTrophy)?.icon || null,
    bestSeller: design?.bestSeller ? true : false,
    labour: laborData.find((item) => item.value === design?.makeType)?.icon || design.labour || null,
    designClass: deginType.find((item) => item.value === design?.designClass?.toLowerCase())?.icon,
    styleNumber: design?.styleNumber || null,
    totalTrophy: design?.totalTrophy || null,
  };

  return obj;
};

//For used cart page:-
const transformDataForCartPage = (design) => {
  const obj = {
    id: design._id ? design._id : design.id,
    // labour: design.makeType ? design.makeType : design.labour,
    // date: design.styleDate ? new Date(design.styleDate).toLocaleDateString("en-GB") : design.date,
    date: design.styleDate ? formatDate(design?.styleDate) : design.date,
    // gold: design.styleNumber ? design.styleNumber : design.gold,
    // diamond: design.diaPcs ? `${design.diaPcs}/${design.diaWeight}` : design.diamond,
    // weight: design.grossWeight ? `${design.grossWeight}/${design.netWeight}` : design.weight,
    diamond: design.diaPcs ? `${design.diaWeight} Ct / ${design.diaPcs} Pcs` : design.diamond,
    weight: design.grossWeight ? `${design.netWeight} g / ${design.grossWeight} g` : design.weight,
    collectionDetails: design.collectionDetails || [],
    setDetails: design.setDetails || [],
    img: design.images ? design?.images[0]?.imageUrl : design?.img ? design?.img : design?.imageUrl,
    // Orgimg: design?.Orgimg ? design?.Orgimg : design?.images[0]?.imageUrl,
    Orgimg: design?.Orgimg || design?.images?.[0]?.imageUrl,
    sales: design?.totalSales || design?.sales || 0,
    trendUp: true,
    cup: cupData.find((item) => item.value === design?.currentTrophy)?.icon || design?.cup || null,
    bestSeller: design?.bestSeller ? true : false,
    labour: laborData.find((item) => item.value === design?.makeType)?.icon || design.labour || null,
    designClass: deginType.find((item) => item.value === design?.designClass.toLowerCase()).icon,
    styleNumber: design?.styleNumber || null,
    totalTrophy: design?.totalTrophy || null,
  };

  return obj;
};

//Used For CatalogProductData:-
const transformDataForCatalogDataPage = (design) => {
  const obj = {
    id: design._id ? design._id : design.id,
    // labour: design.makeType ? design.makeType : design.labour,
    // date: design.styleDate ? new Date(design.styleDate).toLocaleDateString("en-GB") : design.date,
    date: design.styleDate ? formatDate(design?.styleDate) : design.date,
    // gold: design.styleNumber ? design.styleNumber : design.gold,
    // diamond: design.diaPcs ? `${design.diaPcs}/${design.diaWeight}` : design.diamond,
    // weight: design.grossWeight ? `${design.grossWeight}/${design.netWeight}` : design.weight,
    diamond: design.diaPcs ? `${design.diaWeight} Ct / ${design.diaPcs} Pcs` : design.diamond,
    weight: design.grossWeight ? `${design.netWeight} g / ${design.grossWeight} g` : design.weight,
    collectionDetails: design.collectionDetails || [],
    setDetails: design.setDetails || [],
    // img: design.images ? design?.images[0]?.imageUrl : design?.img ? design?.img : design?.imageUrl,

    img: design?.transformedImageUrl || design?.img,
    Orgimg: design?.imageUrl || design?.Orgimg,
    sales: design?.totalSales || design?.sales || 0,
    trendUp: true,
    cup: cupData.find((item) => item.value === design?.currentTrophy)?.icon || design?.cup || null,
    bestSeller: design?.bestSeller ? true : false,
    labour: laborData.find((item) => item.value === design?.makeType)?.icon || design.labour || null,
    designClass: deginType.find((item) => item.value === design?.designClass.toLowerCase()).icon,
    styleNumber: design?.styleNumber || null,
    totalTrophy: design?.totalTrophy || null,
  };

  return obj;
};

const transformClinet = (design) => {
  console.log(design);
  const obj = {
    _id: design?.style?._id || design._id,
    view: design.view || 0,
    cart: design.cart || 0,
    clock: convertingSecondIntoHoursFormat(design.clock || 0) || "00:00:00",
    // gold: design?.style?.styleNumber ? design?.style?.styleNumber : design?.style?.gold,
    // diamond: design.diaPcs ? `${design.diaPcs}/${design.diaWeight}` : design.diamond,
    removeCart: design.removeCart || 0,
    img: design?.imageUrl,
    Orgimg: design?.imageUrl || design?.Orgimg,
    // labour: design?.style?.makeType ? design?.style?.makeType : design?.style?.labour,
    // date: design?.style?.styleDate ? new Date(design?.style?.styleDate).toLocaleDateString("en-GB") : design?.style?.date,
    date: design?.style?.styleDate ? formatDate(design?.style?.styleDate) : design?.style?.date,
    // diamond: design.diaPcs ? `${design.diaPcs}/${design.diaWeight}` : design.diamond,
    // diamond: design?.style?.diaPcs != null && design?.style?.diaWeight != null ? `${design?.style?.diaPcs}/${design?.style?.diaWeight}` : design?.style?.diamond,
    // weight: design?.style?.grossWeight ? `${design?.style?.grossWeight}/${design?.style?.netWeight}` : design?.style?.weight,
    diamond: design?.style?.diaPcs != null && design?.style?.diaWeight != null ? `${design?.style?.diaWeight} Ct / ${design?.style?.diaPcs} Pcs` : design?.style?.diamond,
    weight: design?.style?.grossWeight ? `${design?.style?.netWeight} g / ${design?.style?.grossWeight} g` : design?.style?.weight,
    sales: design?.style?.totalSales || design?.style?.sales || 0,
    trendUp: true,
    cup: cupData.find((item) => item.value === design?.style?.currentTrophy)?.icon || design?.style?.cup || null,
    bestSeller: design?.bestSeller ? true : false,
    labour: laborData.find((item) => item.value === design?.style?.makeType)?.icon || design?.style?.labour || null,
    designClass: deginType.find((item) => item.value === design?.style?.designClass.toLowerCase()).icon,
    styleNumber: design?.style?.styleNumber || null,
    totalTrophy: design?.style?.totalTrophy || null,
  };

  return obj;
};

export { transformData, transformSetData, transformArchivesSetData, transformDataForTrayModal, transformClinet, transformDataForCartPage, transformDataForCatalogDataPage };
