import React from "react";
import ChildHeaderLayout from "./childHeaderLayout";
import { Skeleton } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import apiClient from "lib/api-client";

const SetChildLayoutWrapper = ({ pageName, icon, deIcon }) => {
  const fetchCategoryData = async () => {
    const response = await apiClient.get(`/api/v1/set/getSetTotalCount`);
    return response?.data;
  };

  const { isLoading, data } = useQuery({
    queryKey: ["setCountData"],
    queryFn: fetchCategoryData,
    cacheTime: 1000 * 60 * 60 * 24,
    staleTime: 1000 * 60 * 60 * 24,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    initialDataUpdatedAt: 0,
  });

  if (isLoading) {
    return <Skeleton animation="wave" height={60} width="20%" sx={{ margin: 0, padding: 0, marginLeft: "20px" }} />;
  }

  return <ChildHeaderLayout pageName={pageName} icon={icon} deIcon={deIcon} categoryCountWithDesgin={data || {}} />;
};

export default SetChildLayoutWrapper;
