import React, { createContext, useState, useContext } from "react";

const FilterContext = createContext();

export const FilterProvider = ({ children }) => {
  const [backendFilterValues, setBackendFilterValues] = useState({
    // Labour: [],
    // Class: [],
    // "Diamond Pieces": [],
    // "Diamond Weight": [],
    // "Gold Gross-Weight": [],
    // "Gold Net-Weight": [],
    // "Design In Collection": [],
    // "Design In Sets": [],
    // "Highest Seller": [],
    // "Best Seller Design": [],
  });

  const [filterValues, setFilterValues] = useState({
    // Labour: [],
    // Class: [],
    // "Diamond Pieces": [],
    // "Diamond Weight": [],
    // "Gold Gross-Weight": [],
    // "Gold Net-Weight": [],
    // "Design In Collection": [],
    // "Design In Sets": [],
    // "Highest Seller": [],
    // "Best Seller Design": [],
  });

  const [isFilter, setFilter] = useState(false);
  const [isSetFilter,setIsSetFilter] =useState(false);

  return (
    <FilterContext.Provider value={{ filterValues, setFilterValues, isFilter, setFilter, backendFilterValues, setBackendFilterValues, isSetFilter, setIsSetFilter }}>{children}</FilterContext.Provider>
  );
};

export const useFilter = () => useContext(FilterContext);
