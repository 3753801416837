const { createContext, useState, useContext } = require("react");

export const SettingContext = createContext();

export const SettingProvider = ({ children }) => {
  const [createBtn, setCreateBtn] = useState(false);
  const [creatBtnName, setCreateBtnName] = useState("");
  const [createRole, setCreateRole] = useState(false);
  const [roleEditBtn, setRoleEditBtn] = useState(false);
  const [isDltClick, setIsDltClick] = useState(false);
  const [undoRole, setUndoRole] = useState(false);
  const [isChange, setIsChange] = useState(false);
  const [cancelEditUserModal, setCancelEditUserModal] = useState(false);
  const [cancelEditChnages, setCancelEditChanges] = useState(false);

  const [openEditPage, setOpenEditPage] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isNameChanged, setIsNameChanged] = useState(false);

  const [restrictBtn,setRestrictBtn] = useState(false);
  const [isRestrictedData,setIsRestrictedData] = useState([]);
  const [isRestrictModalOpen,setIsRestrictModalOpen] = useState(false);

  // Right and Limit Page State
  const [isRightPage, setIsRightPage] = useState(false);
  const [userCreateStatus, setUserCreatedStatus] = useState(false);
  const [selectRoleBtnName, setSelectRoleBtnName] = useState("Select Role");
  const [selectRoleOptions, setSelectRoleOptions] = useState([]);
  const [enableSaveRightsBtn, setEnableSaveRightsBtn] = useState(false);
  const [vibrateButton, setVibrateButton] = useState(false);
  const [saveRightModalOpen, setSaveRightModalOpen] = useState(false);

  // Limit Page State
  const [isLimitPage, setIsLimitPage] = useState(false);
  const [enableSaveLimitsBtn, setEnableSaveLimitsBtn] = useState(false);
  // const [saveLimitModalOpen, setSaveLimitModalOpen] = useState(false);

  //for the missing img count
  const [missingImagesCount, setMissingImagesCount] = useState(null);

  //for restricate categorData
  const [restricateCatagoryCount, setRestricateCatagoryCount] = useState(null);

  const [search, setSearch] = useState("");

  //for sync page
  const [MainSyncbutton,setMainSyncButton] = useState(false);
  const [syncButton,setSyncButton] = useState(false);
  const [categorySyncButton,setCategorySyncButton] = useState(false);
  const [syncMakeTypeButton,setSyncMakeTypeButton] = useState(false);
  const [collectionSyncbutton,setCollectionSyncButton ] = useState(false);
  const [syncModal,setSyncModal] = useState(false);
  const [ReverAlltModal,setRevertAllModal] = useState(false);
  const [singleRevertModal,setSingleRevertModal] = useState(false);
 
  return (
    <SettingContext.Provider
      value={{
        createBtn,
        setCreateBtn,
        creatBtnName,
        setCreateBtnName,
        createRole,
        setCreateRole,
        roleEditBtn,
        setRoleEditBtn,
        openEditPage,
        setOpenEditPage,
        isDltClick,
        setIsDltClick,
        isUpdate,
        setIsUpdate,

        undoRole,
        setUndoRole,
        isChange,
        setIsChange,
        cancelEditUserModal,
        setCancelEditUserModal,
        cancelEditChnages,
        setCancelEditChanges,

        isRightPage,
        setIsRightPage,
        userCreateStatus,
        setUserCreatedStatus,

        selectRoleBtnName,
        setSelectRoleBtnName,

        selectRoleOptions,
        setSelectRoleOptions,

        enableSaveRightsBtn,
        setEnableSaveRightsBtn,

        vibrateButton,
        setVibrateButton,

        saveRightModalOpen,
        setSaveRightModalOpen,

        isLimitPage,
        setIsLimitPage,

        enableSaveLimitsBtn,
        setEnableSaveLimitsBtn,

        isNameChanged,
        setIsNameChanged,
        missingImagesCount,
        setMissingImagesCount,
        search,
        setSearch,

        restrictBtn,
        setRestrictBtn,
        isRestrictedData,
        setIsRestrictedData,
        isRestrictModalOpen,
        setIsRestrictModalOpen,

        MainSyncbutton,
        setMainSyncButton,

        syncButton,
        setSyncButton,

        categorySyncButton,
        setCategorySyncButton,

        syncMakeTypeButton,
        setSyncMakeTypeButton, 
        
        collectionSyncbutton,
        setCollectionSyncButton,

        syncModal,
        setSyncModal,

        singleRevertModal,
        setSingleRevertModal,

        ReverAlltModal,
        setRevertAllModal,
        
        restricateCatagoryCount,
        setRestricateCatagoryCount,
      }}
    >
      {children}
    </SettingContext.Provider>
  );
};

export const useSettingContext = () => useContext(SettingContext);
