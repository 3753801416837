import React from "react";

const TableSales = ({ salesData, totalSales }) => {
  return (
    <table className="table">
      <thead className="table-head">
        <tr>
          <th style={{ width: "80%" }}>Total Sales</th>
          <th style={{ width: "20%" }}>{totalSales}</th>
        </tr>
      </thead>
      <tbody className="table-body ">
        {salesData && salesData.length > 0 ? (
          salesData.map((item) => (
            <tr key={item?.name}>
              <td style={{ width: "80%" }}>{item?.name}</td>
              <td style={{ width: "20%" }}>{item?.sale} Pcs</td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="2" style={{ textAlign: "center" }}>
              No sales data available.
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default TableSales;
