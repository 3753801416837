// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.best-seller .main-grid{
  padding-top: 0px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.best-seller .main-grid .best-seller-heading {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #000000;
}

.best-seller .main-grid .best-seller-span-heading {
  font-family: "Karla";
  font-weight: 500;
  font-size: 22px;
  line-height: 25.72px;
  color: #000000;
}


.best-seller .sub-grid .table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #000000;
}

.best-seller .sub-grid .table .table-head th,
.best-seller .sub-grid .table .table-body td {
  font-family: "Karla";
  font-weight: 400;
  font-size: 18px;
  line-height: 21.04px;
  color: #000000;
  border: 1px solid black; 
  padding: 8px; 
  text-align: center;
}

`, "",{"version":3,"sources":["webpack://./src/components/ui/BestSellerModal/bestSeller.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;AACA;EACE,sBAAsB;EACtB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,oBAAoB;EACpB,gBAAgB;EAChB,eAAe;EACf,oBAAoB;EACpB,cAAc;AAChB;;;AAGA;EACE,WAAW;EACX,yBAAyB;EACzB,yBAAyB;AAC3B;;AAEA;;EAEE,oBAAoB;EACpB,gBAAgB;EAChB,eAAe;EACf,oBAAoB;EACpB,cAAc;EACd,uBAAuB;EACvB,YAAY;EACZ,kBAAkB;AACpB","sourcesContent":[".best-seller .main-grid{\n  padding-top: 0px !important;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n.best-seller .main-grid .best-seller-heading {\n  font-family: \"Poppins\";\n  font-weight: 500;\n  font-size: 24px;\n  line-height: 36px;\n  color: #000000;\n}\n\n.best-seller .main-grid .best-seller-span-heading {\n  font-family: \"Karla\";\n  font-weight: 500;\n  font-size: 22px;\n  line-height: 25.72px;\n  color: #000000;\n}\n\n\n.best-seller .sub-grid .table {\n  width: 100%;\n  border-collapse: collapse;\n  border: 1px solid #000000;\n}\n\n.best-seller .sub-grid .table .table-head th,\n.best-seller .sub-grid .table .table-body td {\n  font-family: \"Karla\";\n  font-weight: 400;\n  font-size: 18px;\n  line-height: 21.04px;\n  color: #000000;\n  border: 1px solid black; \n  padding: 8px; \n  text-align: center;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
