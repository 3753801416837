import { useInfiniteQuery } from "@tanstack/react-query";
const { default: apiClient } = require("lib/api-client");

const fetchSubCategories = async ({ pageParam = 1, queryKey }) => {
  const [, { categoryName, limit, sortBy, sortOrder, searchTerm }] = queryKey;
  const response = await apiClient.post("/api/v1/styles/getSubCategoryDetailsByCategoryForApp", {
    categoryName,
    pageNumber: pageParam,
    limit,
    sortBy,
    sortOrder,
    searchTerm,
  });

  if (response.status !== 200) {
    throw new Error("Error fetching categories");
  }

  return {
    subCategories: response.data.subCategories,
    nextPage: response.data.pagination.pageNumber + 1,
    totalPages: response.data.pagination.totalPages,
  };
};

export const useSubCategories = ({ categoryName = "", limit = 10, sortBy = "alphabetical", sortOrder = "asc", searchTerm = "" }) => {
  return useInfiniteQuery({
    queryKey: ["subcategories", { categoryName, limit, sortBy, sortOrder, searchTerm }],
    queryFn: ({ pageParam = 1 }) => fetchSubCategories({ pageParam, queryKey: ["subcategories", { categoryName, limit, sortBy, sortOrder, searchTerm }] }),
    getNextPageParam: (lastPage) => {
      if (lastPage.nextPage <= lastPage.totalPages) {
        return lastPage.nextPage;
      }
      return undefined;
    },
    staleTime: 5 * 60 * 1000,
    retry: 1,
    select: (data) => {
      return {
        ...data,
        pages: data?.pages?.map((page) => ({
          ...page,
          subCategories: page.subCategories.filter((subCate) => subCate?.count >= 1),
        })),
        // filterData,
      };
    },
    // select: (data) => {
    //   const allData = data?.pages.flatMap((page) => page.subCategories);

    //   const updatedSubCate = allData?.map((subCategory) => {
    //     if (subCategory.subCategory === "Uncategorized") {
    //       return { ...subCategory, subCategory: "Blank" };
    //     }
    //     return subCategory;
    //   });

    //   //total
    //   const totalSubcategoryCount = updatedSubCate.reduce((acc, curr) => acc + curr.count, 0);

    //   // updatedSubCate.unshift({
    //   //   subCategory: "All",
    //   //   count: totalSubcategoryCount,
    //   // });

    //   const remainingSubCategories = updatedSubCate.filter((subCategory) => subCategory.subCategory !== "Blank");

    //   //find the blank
    //   const blankSubCate = updatedSubCate.find((subCategory) => subCategory.subCategory === "Blank");

    //   //order the data
    //   const orders = [{ subCategory: "All", count: totalSubcategoryCount }, ...(blankSubCate ? [blankSubCate] : []), ...remainingSubCategories];
    //   return {
    //     ...data,
    //     pages: data.pages.map((page) => ({
    //       ...page,
    //       subCategories: orders,
    //     })),
    //   };
    // },
  });
};
