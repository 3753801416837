import React from "react";
import { Box, Grid } from "@mui/material";
import { getIcon } from "utils/iconsConfig";
import Button from "components/ui/Button";
import Card from "components/ui/Card";
import "./setcard.css";
import { ImageComp } from ".";
import { usePermissions } from "context/PermissionsContext";
import { findSpecificPage, findSpecificSubPage, hasAccessPage, hasAccessSubPage } from "utils/checkUserRigths";
import Tooltip from "./Tooltip";

const SetCard = ({ isBtn, openDrawer, parentClick, btnTitle, buttonClick, innerChnages, data, isPresent, btnInfo }) => {
  const { setNumber, totalDiamonds, goldWeights, numberOfStyles, designsImgs, date, labour, maxMetalType } = data;

  const permission = usePermissions();
  const setArchiveAccess = hasAccessPage(findSpecificPage("Sets", permission), "archive");
  const viewAAccess = btnInfo && btnInfo.length > 0 && btnInfo.some((btn) => btn.view);
  const hasAccessToSeeCreationDate = hasAccessSubPage(findSpecificSubPage(findSpecificPage("Settings", permission), "Design Date"), "view");
  const hasAccessToSeeWatermark = hasAccessSubPage(findSpecificSubPage(findSpecificPage("Settings", permission), "Watermark"), "view");

  return (
    <div className="set-card" onClick={parentClick}>
      <Card style={{ padding: "20px", borderRadius: "10px", minWidth: "435px", maxWidth: "435px", height: "276px", alignItems: "flex-start" }}>
        <div className="left-side">
          <div className="content">
            {getIcon(maxMetalType)}
            <p className="heading">SET-{setNumber}</p>
          </div>

          <div className="content">
            {getIcon("set")}
            <Tooltip title={"number of styles"} marginTop="2px">
              <p className="subheading">{numberOfStyles} PCS</p>
            </Tooltip>
          </div>

          <div className="content">
            {getIcon("diamond")}
            <Tooltip title={"Diamond Weight / Diamond Pieces"} marginTop="2px">
              <p className="subheading">{totalDiamonds}</p>
            </Tooltip>
          </div>

          <div className="content">
            {getIcon("goldbar")}
            <Tooltip title={"Gold Net Weight / Gold Gross Weight"} marginTop="2px">
              <p className="subheading">{goldWeights}</p>
            </Tooltip>
          </div>
          <div className="content">
            {getIcon("labour")}
            <Tooltip title={"Make Type"} marginTop="2px">
              <p className="subheading">{labour}</p>
            </Tooltip>
          </div>

          {hasAccessToSeeCreationDate && (
            <div className="content">
              {getIcon("date")}
              <Tooltip title={"Design Creation Date"} marginTop="2px">
                <p className="subheading">{date}</p>
              </Tooltip>
            </div>
          )}
        </div>

        <div className="right-side">
          <Grid
            container
            spacing={0.5}
            m={0}
            p={0}
            onClick={(e) => {
              e.stopPropagation();
              const target = e.target.closest(".clickable-item");
              if (target) {
                const { index } = target.dataset;
                openDrawer(designsImgs[index]);
              }
            }}
          >
            {designsImgs.map((img, index) => {
              // Calculate row and column indices
              const row = Math.floor(index / 2); // Assuming 2 columns per row
              const col = index % 2;

              // Define padding logic
              const paddingTop = row === 0 ? "0!important" : "inherit"; // No padding-top for the first row
              const paddingLeft = col === 0 ? "0!important" : "inherit"; // No padding-left for the first column

              return img?.image ? (
                <Grid
                  key={index}
                  item
                  sm={6}
                  lg={6}
                  data-index={index}
                  className="clickable-item"
                  sx={{
                    width: "114px",
                    height: (isBtn === "click" && setArchiveAccess) || (isBtn === "click" && viewAAccess) || innerChnages ? "91px" : "114px",
                    paddingTop,
                    paddingLeft,
                    cursor: "pointer",
                  }}
                >
                  <div className="image-div">
                    <ImageComp url={img.image} hasAccessToSeeWatermark={hasAccessToSeeWatermark} />
                    {numberOfStyles - 4 > 0 && index === designsImgs.length - 1 ? <Box className="overlayText">+{numberOfStyles - 4}</Box> : null}
                  </div>
                </Grid>
              ) : (
                <Grid
                  key={index}
                  item
                  sm={6}
                  lg={6}
                  sx={{
                    width: "114px",
                    height: (isBtn === "click" && setArchiveAccess) || (isBtn === "click" && viewAAccess) || innerChnages ? "91px" : "114px",
                    paddingTop,
                    paddingLeft,
                  }}
                ></Grid>
              );
            })}
          </Grid>

          {/* <Grid
            container
            spacing={0.5}
            m={0}
            p={0}
            onClick={(e) => {
              e.stopPropagation();
              openDrawer();
            }}
          >
            <Grid
              item
              sm={6}
              lg={6}
              sx={{
                width: "114px",
                height: (isBtn === "click" && setArchiveAccess) || (isBtn === "click" && viewAAccess) || innerChnages ? "91px" : "114px",
                paddingTop: "0!important",
                paddingLeft: "0!important",
              }}
            >
              <div className="image-div">{designsImgs[0]?.image && <ImageComp url={designsImgs[0]?.image} hasAccessToSeeWatermark={hasAccessToSeeWatermark} />}</div>
            </Grid>

            <Grid
              item
              sm={6}
              lg={6}
              sx={{ width: "114px", height: (isBtn === "click" && setArchiveAccess) || (isBtn === "click" && viewAAccess) || innerChnages ? "91px" : "114px", paddingTop: "0!important" }}
            >
              <div className="image-div">{designsImgs[1]?.image && <ImageComp url={designsImgs[1]?.image} hasAccessToSeeWatermark={hasAccessToSeeWatermark} />}</div>
            </Grid>
            <Grid
              item
              sm={6}
              lg={6}
              sx={{ width: "114px", height: (isBtn === "click" && setArchiveAccess) || (isBtn === "click" && viewAAccess) || innerChnages ? "91px" : "114px", paddingLeft: "0!important" }}
            >
              <div className="image-div">{designsImgs[2]?.image && <ImageComp url={designsImgs[2]?.image} hasAccessToSeeWatermark={hasAccessToSeeWatermark} />}</div>
            </Grid>
            <Grid item sm={6} lg={6} sx={{ width: "114px", height: (isBtn === "click" && setArchiveAccess) || (isBtn === "click" && viewAAccess) || innerChnages ? "91px" : "114px" }}>
              <div className="image-div">
                {designsImgs[3]?.image && <ImageComp url={designsImgs[3]?.image} hasAccessToSeeWatermark={hasAccessToSeeWatermark} />}
                {numberOfStyles - 4 > 0 ? <Box className="overlayText">+{numberOfStyles - 4}</Box> : null}
              </div>
            </Grid>
          </Grid> */}

          {innerChnages ? (
            <Button
              sx={{
                "&.Mui-disabled": {
                  backgroundColor: "#FFFFFF !important",
                  color: "#444444 !important",
                  border: "1px solid #444444 !important",
                },
              }}
              fullWidth={true}
              variant="contained"
              disabled={isPresent ? isPresent : false}
              className={btnTitle === "Add" ? "btnAdd" : "btnAdded"}
              disableRipple
              onClick={buttonClick}
            >
              {btnTitle}
            </Button>
          ) : btnInfo && btnInfo.length > 0 ? (
            <Box sx={{ width: "102%", display: "flex", justifyContent: "space-between", alignItems: "center", marginRight: "5px", gap: "5px" }}>
              {isBtn === "click" &&
                btnInfo
                  ?.filter((item) => item.view)
                  .map((btn, _, filteredArr) => {
                    return (
                      <Button
                        fullWidth={true}
                        className={`${btn.title === "Delete" ? "btn-delete" : "btn-unarchive"} btn ${filteredArr.length === 1 && btn.title === "Unarchive" ? "btn-width" : ""}`}
                        variant="contained"
                        disableRipple
                        onClick={() => buttonClick(btn.title)}
                      >
                        {btn.title}
                      </Button>
                    );
                  })}
            </Box>
          ) : isBtn === "click" ? (
            setArchiveAccess && (
              <Button fullWidth={true} sx={{ border: "0.5px solid #E77B7B", width: "102%" }} variant="contained" className="btn" disableRipple onClick={buttonClick}>
                {btnTitle}
              </Button>
            )
          ) : null}

          {/* {isBtn === "click" && (
            <Button fullWidth={true} variant="contained" className="btn" disableRipple onClick={buttonClick}>
              {btnTitle}
            </Button>
          )} */}
        </div>
      </Card>
    </div>
  );
};

export default SetCard;
