import React, { useEffect, useState } from "react";
import Modal from "../Modal";
import { Grid, Typography } from "@mui/material";
import "./bestSeller.css";
import renderIconForBestSellers from "./renderIconForBestSellers";
import apiClient from "lib/api-client";

const fetchBestSalesYearlyDetails = async (styleNumber, setLoading, setError) => {
  try {
    setLoading(true);
    setError(null);
    const res = await apiClient.post(`/api/v1/styles/yearlyTray`, { styleNumber: styleNumber });
    return res?.data?.data;
  } catch (error) {
    setError("Failed to fetch Yearly Best sales details. Please try again.");
    return null;
  } finally {
    setLoading(false);
  }
};

const BestSellerModal = ({ open, setOpen, styleNumber }) => {
  const [bestSellerData, setBestSellerData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (open && styleNumber) {
      (async () => {
        const data = await fetchBestSalesYearlyDetails(styleNumber, setLoading, setError);
        if (data) {
          setBestSellerData(data || []);
        }
      })();
    }
  }, [open, styleNumber]);

  return (
    <Modal open={open} handleClose={handleClose} style={{ width: "387px" }}>
      <Grid container spacing={2} className="best-seller">
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="main-grid">
          <Typography className="best-seller-heading">Highest Seller</Typography>
          <Typography className="best-seller-span-heading">{styleNumber}</Typography>
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="sub-grid">
          {loading ? (
            <h3>Loding ...</h3>
          ) : error ? (
            <h4 style={{ color: "red" }}>{error}</h4>
          ) : (
            <table className="table">
              <thead className="table-head">
                <tr>
                  <th>Rank</th>
                  <th>Year</th>
                  <th>Sales</th>
                </tr>
              </thead>
              <tbody className="table-body">
                {bestSellerData && bestSellerData.length > 0 ? (
                  bestSellerData.map((item) => (
                    <tr key={item?.id}>
                      <td>{renderIconForBestSellers(item?.trophy)}</td>
                      <td>{item?.year}</td>
                      <td>{item?.totalSales}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="2" style={{ textAlign: "center" }}>
                      No sales data available.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </Grid>
      </Grid>
    </Modal>
  );
};

export default BestSellerModal;
