import React, { useCallback, useMemo, useState } from "react";
import Modal from "components/ui/Modal";
import "../styles/sharelinkmodal.css";
import { Box, FormControl, FormLabel, Grid, MenuItem, Select, Typography, useMediaQuery } from "@mui/material";
import CustomButton from "components/ui/Button";
import { ThemeProvider, useTheme } from "@mui/material/styles";
import custumTheme from "../../../thems/catalogueShareLinkThems";
import PhoneInput from "react-phone-input-2";

function CatalogueShareLinkModule({ open, setOpen, handleUpdateCatalogueShareLinkInfo }) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [linkData, setLinkData] = useState({
    mobileNumber: "",
    hours: "",
  });

  const handleOnChangeEvent = (e) => {
    const { name, value } = e.target;
    setLinkData((data) => ({
      ...data,
      [name]: value,
    }));
  };

  const handlePhoneChange = (value) => {
    setLinkData((data) => ({
      ...data,
      mobileNumber: value,
    }));
  };

  const handleClose = () => {
    setOpen(false);
    handleResetForm();
  };

  const handleSubmitForm = () => {
    handleUpdateCatalogueShareLinkInfo(linkData);

    // Example base URL (modify as needed)
    const baseUrl = "https://example.com/share";

    // Convert linkData to a query string or any other format
    const queryString = new URLSearchParams(linkData).toString();

    // Generate the full link
    const fullLink = `${baseUrl}?${queryString}`;

    // Copy the link to clipboard
    navigator.clipboard
      .writeText(fullLink)
      .then(() => {
        console.log("Link copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy link to clipboard: ", err);
      });

    handleClose();
  };

  const handleResetForm = () => {
    setLinkData((data) => ({
      ...data,
      mobileNumber: "",
      hours: "",
    }));
  };

  const hoursDataFun = useCallback((hours = 72) => {
    let newArray = [];
    for (let i = 0; i <= hours; i++) {
      newArray.push(i);
    }
    return newArray;
  }, []);

  const hoursData = useMemo(() => hoursDataFun(), []);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: "auto",
      },
    },
  };

  return (
    <ThemeProvider theme={custumTheme}>
      <Modal open={open} handleClose={handleClose} style={{ width: isSmallScreen ? "auto" : "400px" }}>
        <Grid container spacing={2}>
          <Grid item lg={12}>
            <Typography className="catalogue-share-link-modal-heading">Create Private Link</Typography>
          </Grid>
          <Grid item lg={12}>
            <FormControl fullWidth>
              <FormLabel>Mobile Number</FormLabel>
              <PhoneInput
                country={"in"}
                value={linkData.mobileNumber}
                onChange={handlePhoneChange}
                placeholder="Phone Number"
                inputProps={{
                  name: "mobileNumber",
                  required: true,
                  autoFocus: true,
                  autoComplete: "off",
                }}
              />
            </FormControl>
          </Grid>
          <Grid item lg={12}>
            <FormControl sx={{ minWidth: 120 }}>
              <FormLabel>Hours</FormLabel>
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <div>
                  <Select
                    sx={{ paddingLeft: "0px" }}
                    value={linkData.hours}
                    name="hours"
                    onChange={handleOnChangeEvent}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    MenuProps={MenuProps}
                  >
                    <MenuItem disabled value="">
                      <em>HH</em>
                    </MenuItem>
                    {hoursData.map((h, i) => (
                      <MenuItem key={h} value={h}>
                        {h}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <div>
                  <Typography className="catalogue-share-link-modal-hours">Between 1 To 72 Hours</Typography>
                </div>
              </Box>
            </FormControl>
          </Grid>
          <Grid item lg={12} display="flex" justifyContent="center" sx={{ width: "100%" }}>
            <CustomButton variant="contained" disabled={linkData.hours === "" || linkData.mobileNumber === ""} sx={{ width: "60%" }} onClick={handleSubmitForm}>
              Copy Link
            </CustomButton>
          </Grid>
        </Grid>
      </Modal>
    </ThemeProvider>
  );
}

export default CatalogueShareLinkModule;
