import React, { useEffect, useState } from "react";

import "../../../collection/styles/collectionScreen.css";
import collectionDataJson from "../../../../db/collectionData.json";
import { ReactComponent as Background } from "assets/icons/bg.svg";
import Card from "components/ui/Card";
import { Grid, Skeleton, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useGlobalContext } from "context/GlobalContext";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import { ReactComponent as DescriptionIcon } from "assets/icons/descriptionicon.svg";

import { ReactComponent as EditIcon } from "assets/icons/CatalogueCard/edit.svg";

import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import config from "configs/config";
import SortContext from "context/SortContext";
import { encodeStr } from "utils/encodeDecodeStr";
import LoadingSmallCard from "components/ui/Skeleton/LoadingSmallCard";
import apiClient from "lib/api-client";
import { usePermissions } from "context/PermissionsContext";
import { findSpecificPage, findSpecificSubPage, hasAccessPage, hasAccessSubPage } from "utils/checkUserRigths";
import RemoveModal from "components/ui/RemoveModal/RemoveModal";
import CollectionEditModal from "../Modal/CollectionEditModal";
import { toast } from "react-toastify";
import { formatDate } from "utils/formatDate";
import CollectionDiscriptionModal from "../Modal/CollectionDiscriptionModal";

function CollectionScreen() {
  const { search, selectedSortValue, setIsEdit, isEdit, setIsEditClick, isCollectionEdit, setIsCollectionEdit } = React.useContext(SortContext);
  const [openRemoveModal, setOpenRemoveModal] = useState(false);
  const [openEditNameModal, setOpenEditNameModal] = useState(false);
  const [openDiscriptionModal, setOpenDiscriptionModal] = useState(false);
  const [discriptionData, setDiscriptionData] = useState({});
  const [currentCollectionData, setCurrentCollectionData] = useState({});

  const [collectionRemovePayload, setCollectionRemovePayload] = useState({});
  let [collectionData, setCollectionData] = useState(collectionDataJson);

  const navigate = useNavigate();
  const location = useLocation();
  const innerChnages = location?.state?.innerChnages;
  const collectionPresent = location?.state?.collection;

  const { setCreateBtn, setCreateBtnName, setCreateBtnOption, showCollectionTrashIcon, setShowCollectionTrashIcon, setCollectionUpdate, setOpenCancelModal, openCancelModal } = useGlobalContext();
  // const [forceLoading, setForceLoading] = useState(true);
  const permission = usePermissions();
  const hasAccessAddCollection = hasAccessPage(findSpecificPage("Collection", permission), "add");
  const hasAccessEditCollection = hasAccessPage(findSpecificPage("Collection", permission), "edit");
  const hasAccessDeleteCollection = hasAccessPage(findSpecificPage("Collection", permission), "archive");
  const hasAccessEditName = hasAccessSubPage(findSpecificSubPage(findSpecificPage("Collection", permission), "Name Change"), "edit");
 

  //------------------------ Handling Date Formatting  -----------------------\\

  const formatDateToDDMMYYYY = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };

  //------------------------ Handling API Integration Start -----------------------\\
  const payload = {
    pageNumber: 1,
    limit: 100,
    searchTerm: search, //Winter Collection
    sortBy:
      selectedSortValue == "1" || selectedSortValue == "2"
        ? "alphabetical"
        : selectedSortValue == "3" || selectedSortValue == "4"
          ? "quantity"
          : selectedSortValue == "5" || selectedSortValue == "6"
            ? "collectionDate"
            : "", //alphabetical //quantity
    order: selectedSortValue == "1" || selectedSortValue == "4" || selectedSortValue == "5" ? "asc" : "desc", //asc //desc
  };

  const fetchCollections = async () => {
    const response = await apiClient.post(`${config.baseUrl}/api/v1/collection/getCollections`, payload);
    return response.data;
  };

  const {
    data: collections,
    isLoading,
    error,
    isError,
    refetch,
  } = useQuery({
    queryKey: ["collections", payload],
    queryFn: fetchCollections,
    enabled: !!payload,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (collections) {
      setCollectionData(collections?.collections);
    }
  }, [collections]);

  //------------------------ Handling API Integration End -----------------------\\

  //------------------ Handle Navigate to Collection Category Page  --------------------\\

  // const handleClick = (ele) => {
  //   const state = {
  //     ...location.state,
  //     name: [`${ele.collectionDetails.collectionName}`],
  //     collection: collectionPresent === undefined || null ? true : collectionPresent,
  //   };
  //   navigate(`/Collection/${ele.collectionDetails.collectionName}`, { state });
  // };

  const handleClick = (ele) => {
    const encodedName = encodeURIComponent(ele.collectionDetails.collectionName); // Encode the name
    const state = {
      ...location.state,
      name: [`${ele.collectionDetails.collectionName}`],
      collection: collectionPresent === undefined || null ? true : collectionPresent,
    };
    navigate(`/Collection/${encodedName}`, { state });
  };

  //----------------- handleOpenDiscriptionModal ------------------
  const handleOpenDiscriptionModal = (ele) => {
    setOpenDiscriptionModal(true);
    
    let discriptionModalData = {
      collectionName: ele.collectionName,
      collectionDiscription: ele?.discription,
      collectionId: ele?._id,
    };

    setDiscriptionData(discriptionModalData);
  };

  useEffect(() => {
    if (openDiscriptionModal === false) {
      refetch();
    }
  }, [openDiscriptionModal]);

  //------------------ Handle Open Trash Modal --------------------\\

  const handleOpenRemoveModal = (ele) => {
    setCollectionRemovePayload(ele.collectionDetails._id);
    setOpenRemoveModal(true);
  };
  //------------------ Handle Open Update Name Modal --------------------\\

  const handleOpenEditModal = (ele) => {
    setOpenEditNameModal(true);
    setCurrentCollectionData({ collectionId: ele.collectionDetails._id, collectionName: ele.collectionDetails.collectionName });
  };

  //------------------ Handle Collection Update Name --------------------\\

  const handleUpdateCollection = async (tempUpdatedData) => {
    // Check if the updated name already exists in collectionData, excluding the current ID
    const isDuplicate = collectionData.some(
      (item) => item.collectionDetails._id !== tempUpdatedData.collectionId && item.collectionDetails.collectionName.trim().toLowerCase() === tempUpdatedData.collectionName.trim().toLowerCase()
    );

    // If duplicate exists, show an error and stop execution
    if (isDuplicate) {
      toast.error("Collection name already exists!", {
        autoClose: 2000,
        hideProgressBar: false,
      });
      return;
    }

    let getLocalData = JSON.parse(localStorage.getItem("tempCollectionUpdatedData")) || [];

    getLocalData.push(tempUpdatedData);

    localStorage.setItem("tempCollectionUpdatedData", JSON.stringify(getLocalData));
    // Update the collectionData state by matching the ID
    setCollectionData((prevCollectionData) =>
      prevCollectionData.map((item) =>
        item.collectionDetails._id === tempUpdatedData.collectionId
          ? {
              ...item,
              collectionDetails: {
                ...item.collectionDetails,
                collectionName: tempUpdatedData.collectionName, // Update the name
              },
            }
          : item
      )
    );
    setCollectionUpdate(true);

    setOpenEditNameModal(false);
  };

  //------------------ Handle Collection Delete --------------------\\

  const handleDeleteCollection = async () => {
    //First Check inside "tempCollectionUpdatedData" whetehr its present iin edited List or Not
    let UpdatedOfCollection = JSON.parse(localStorage.getItem("tempCollectionUpdatedData")) || [];
    if (UpdatedOfCollection.length > 0) {
      UpdatedOfCollection = UpdatedOfCollection.filter((obj) => obj.collectionId !== collectionRemovePayload);
      //Update localstorage of "tempCollectionUpdate"
      localStorage.setItem("tempCollectionUpdatedData", JSON.stringify(UpdatedOfCollection));
    }

    let existTempDataOfCollection = JSON.parse(localStorage.getItem("tempCollectionRemoveData")) || [];

    if (!existTempDataOfCollection.includes(collectionRemovePayload)) {
      existTempDataOfCollection.push(collectionRemovePayload);
    }
    localStorage.setItem("tempCollectionRemoveData", JSON.stringify(existTempDataOfCollection));

    // Filter out the items in collectionData based on IDs in localStorage
    const filteredCollectionData = collectionData.filter((collection) => {
      if (!existTempDataOfCollection.includes(collection.collectionDetails._id)) {
        return collection;
      }
    });
    setCollectionData(filteredCollectionData);
    setOpenRemoveModal(false);
    setCollectionUpdate(true);
  };

  //------------------ Handle Visibility of Create Button --------------------\\

  useEffect(() => {
    if (isCollectionEdit !== "view") {
      setCreateBtn(false);
    } else {
      setCreateBtn(hasAccessAddCollection);
    }
  }, [isCollectionEdit]);

  //------------------ Handle Main UseEffect --------------------\\

  useEffect(() => {
    if (!innerChnages) {
      setCreateBtn(hasAccessAddCollection);

      setCreateBtnName("Create Collection");
      if (hasAccessEditCollection) {
        setIsCollectionEdit("view");
      } else {
        setIsCollectionEdit("");
      }
      setCreateBtnOption([{ option: "By Category", link: "/Category", view: hasAccessPage(findSpecificPage("Category", permission), "view") }]);
      sessionStorage.removeItem("updateProductsData");
      sessionStorage.removeItem("collectionsProductsData");

      return () => {
        setIsCollectionEdit("");
        setCreateBtn(false);
        setCreateBtnName("");
        setCreateBtnOption([]);

        setShowCollectionTrashIcon(false);
        setIsEditClick(false);
        localStorage.removeItem("tempCollectionRemoveData");
        localStorage.removeItem("tempCollectionUpdatedData");
      };
    }
  }, [innerChnages]);
  //------------------ Handle Trash Modal Button --------------------\\

  const btnInfo1 = [
    {
      btnName: "Delete",
      bgColor: "#E77B7B",
      click: async () => {
        handleDeleteCollection();
      },
    },
    {
      btnName: "Keep",
      bgColor: "#50c878",
      click: () => {
        setOpenRemoveModal(false);
      },
    },
  ];

  //------------------ Handle Cancel Modal Button --------------------\\

  const btnInfo2 = [
    {
      btnName: "Discard",
      bgColor: "#E77B7B",
      click: async () => {
        refetch();

        setIsCollectionEdit("view");
        setShowCollectionTrashIcon(false);
        localStorage.removeItem("tempCollectionRemoveData");
        localStorage.removeItem("tempCollectionUpdatedData");

        setOpenCancelModal(false);
        setCollectionUpdate(false);
      },
    },
    {
      btnName: "Keep",
      bgColor: "#50c878",
      click: () => {
        setOpenCancelModal(false);
      },
    },
  ];

  //------------------ Render UI Code  --------------------\\

  if (isLoading) return <LoadingSmallCard count={24} />;
  if (isError) return <div>Error: {error.message}</div>;

  return (
    <>
      {collectionData && collectionData.length > 0 ? (
        <Grid
          container
          className="Grid-padding"
          direction="row"
          justifyContent="flex-start"
          sx={{ flex: "1 1 1", display: "flex", alignItems: "center", justifyContent: "flex-start", gap: "30px", rowGap: "32px" }}
        >
          {collectionData.map((ele, i) => (
            <Grid item key={i}>
              <Card style={{ minWidth: 275, maxWidth: "317px", height: 62, borderRadius: "5px" }}>
                <div style={{ width: "100%" }} onClick={() => handleClick(ele)}>
                  <Typography
                    mr={"auto"}
                    fontSize={"20px"}
                    fontWeight={400}
                    fontFamily={"Karla"}
                    width={"150%"}
                    sx={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "150px", // Adjust as needed for truncation
                      transition: "all 0.3s ease-in-out",
                      position: "relative",
                    }}
                  >
                    {ele.collectionDetails.collectionName}
                  </Typography>
                  <Typography mr={"auto"} fontSize={"12px"} fontWeight={400} fontFamily={"Karla"} color={"#8B8B8B"}>
                    {/* {formatDateToDDMMYYYY(ele.collectionDetails.createdAt)} */}
                    {formatDate(ele.collectionDetails.createdAt)}
                  </Typography>
                </div>

                {showCollectionTrashIcon ? (
                  <div style={{ display: "flex", gap: "30px" }}>
                    {hasAccessEditName && <EditIcon onClick={() => handleOpenEditModal(ele)} />}
                    {hasAccessDeleteCollection && <TrashIcon onClick={() => handleOpenRemoveModal(ele)} />}
                  </div>
                ) : (
                  <div style={{ display: "flex", gap: "25px", alignItems: "center" }}>
                    <DescriptionIcon onClick={() => handleOpenDiscriptionModal(ele.collectionDetails)} />

                    <Typography ml={"auto"} fontSize={"20px"} fontWeight={400} fontFamily={"Karla"}>
                      {ele.collectionDetails.numberOfStyles}
                    </Typography>
                  </div>
                )}
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : (
        <div style={{ disply: "flex", textAlign: "center", padding: "40px", justifyContent: "center", alignItems: "center" }}>
          <Background />
        </div>
      )}
      {openRemoveModal && <RemoveModal open={openRemoveModal} setOpen={setOpenRemoveModal} btnInfo={btnInfo1} heading=" Do you want to Delete this Collection?" />}
      {openCancelModal && <RemoveModal open={openCancelModal} setOpen={setOpenCancelModal} btnInfo={btnInfo2} heading=" Do you want to Discard this changes?" />}

      {openEditNameModal && (
        <CollectionEditModal
          open={openEditNameModal}
          setOpen={setOpenEditNameModal}
          currentCollectionData={currentCollectionData}
          setCurrentCollectionData={setCurrentCollectionData}
          handleUpdateCollection={handleUpdateCollection}
          collectionData={collectionData}
        />
      )}

      {openDiscriptionModal && <CollectionDiscriptionModal open={openDiscriptionModal} setOpen={setOpenDiscriptionModal} data={discriptionData} setData={setDiscriptionData} />}
    </>
  );
}

export default CollectionScreen;
