import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import "../../../Category/categoryProd/components/product.css";
import { ProductCard, Toast } from "components/ui";
import { useLocation } from "react-router-dom";
import { useGlobalContext } from "context/GlobalContext";
import useProductInCatalogCheck from "hooks/useProductInCatalogCheck";

const SingleProduct = React.memo(({ product }) => {
  const location = useLocation();
  const innerChnages = location?.state?.innerChnages;
  const isRestricted = location?.state?.isRestricted;
  const [btnTitle, setBtnTitle] = useState("Select");

  const [open, setOpen] = useState(false);
  const { setCountCart, countCart, setIsEditCatagoryData } = useGlobalContext();
 

  const markCategoryUnRestrict = React.useCallback(() => {
    setBtnTitle("Selected");
    setOpen(true);
  }, [setBtnTitle]);

  const markCategoryRestrict = React.useCallback(
    (productToRemove) => {
      setBtnTitle("Select");
      let getUpdateCatagoryData = JSON.parse(sessionStorage.getItem("RestrictDesign")) || [];
      getUpdateCatagoryData = getUpdateCatagoryData.filter((item) => (item.id || item._id) !== productToRemove.id);
      sessionStorage.setItem("RestrictDesign", JSON.stringify(getUpdateCatagoryData));
      setIsEditCatagoryData(JSON.parse(sessionStorage.getItem("RestrictDesign")) || []);
    },
    [setBtnTitle]
  );

  const selectedCartProducts = React.useMemo(
    () => ({
      ...product,
      isAddedInCart: true,
    }),
    [product]
  );

  const handleClose = React.useCallback((reason) => {
    if (reason === "clickaway") return;
    setOpen(false);
  }, []);

  // useEffect(()=>{
  //   setBtnTitle("Select");
  // },[]);

  useEffect(() => {
    setBtnTitle("Select");
  }, [innerChnages, isRestricted]);

  return (
    <>
      <Grid item className="product">
        <ProductCard
          data={product}
          selectedCartProducts={selectedCartProducts}
          style={{ maxWidth: "430px", minWidth: "430px" }}
          {...(innerChnages && {
            isBtn: true,
            addtoCart: markCategoryUnRestrict,
            removeCart: markCategoryRestrict,
            btnTilte: btnTitle,
            updateCatagoryData: "RestrictDesign",
          })}
        />

        {/* <Toast
          open={open}
          msg={`Design Has Been Added To Cart ${countCart} `}
          autoHideDuration={2000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          ContentProps={{
            sx: { backgroundColor: "#50C878" },
          }}
        /> */}
      </Grid>
    </>
  );
});

export default React.memo(SingleProduct);
