import { Box, Grid } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { ThemeProvider } from "@mui/material/styles";
import custumTheme from "../../../../thems/userFromThems";
import { OutlineInput } from "../utilis/OutlineInput";
import { usersFilds } from "../utilis/userData";
import Button from "components/ui/Button";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSettingContext } from "context/SettingContext";
import RemoveModal from "components/ui/RemoveModal/RemoveModal";
import useGetAllUsersData from "features/Setting/users/hooks/useGetAllUsers";
import useEditUser from "features/Setting/users/hooks/useEditUser";
import useCreateUser from "features/Setting/users/hooks/useCreateUser";
import { ToastContainer, toast } from "react-toastify";
import { getAllRoles } from "features/Setting/Role/Apis/get-role";

export const CreateUserFrom = () => {
  const { cancelEditUserModal, setCancelEditUserModal, setCancelEditChanges, userCreateStatus, setUserCreatedStatus } = useSettingContext();

  const { id } = useParams();
  const navigation = useNavigate();
  const location = useLocation();
  const [initialUserInfo, setInitialUserInfo] = useState({});
  const [userInfo, setUserInfo] = useState({
    username: "",
    firstName: "",
    lastName: "",
    role: "",
    password: "",
    confirmPassword: "",
  });
  const [roleData, setRoleData] = useState([]);

  const { data: allUsers } = useGetAllUsersData();
  const { mutate: updateUser, isError: updateIsError, isSuccess: updateIsSuccess, error: updateError } = useEditUser();
  const { mutate: createUser, isError: addIsError, isSuccess: addIsSuccess, error: addError } = useCreateUser();

  const handleChnage = (e) => {
    const { name, value } = e.target;
    setUserInfo((preData) => ({
      ...preData,
      [name]: value,
    }));
  };

  const findEmptyValue = () => {
    for (let key in userInfo) {
      if (userInfo[key].trim() === "") {
        return true;
      }
    }
    if (userInfo.password.trim() !== userInfo.confirmPassword.trim()) {
      return true;
    }

    return false;
  };

  const findEditValue = (data) => {
    if (
      data["username"] === initialUserInfo["username"] &&
      data["firstName"] === initialUserInfo["firstName"] &&
      data["lastName"] === initialUserInfo["lastName"] &&
      data["password"] === initialUserInfo["password"] &&
      data["confirmPassword"] === initialUserInfo["confirmPassword"] &&
      data["role"] === initialUserInfo["role"]
    ) {
      return true;
    }

    if (userInfo.password !== userInfo.confirmPassword) {
      return true;
    }

    for (let key in userInfo) {
      if (key !== "password" && key !== "confirmPassword" && userInfo[key].trim() === "") {
        return true;
      }
    }

    return false;
  };

  const handleSubmitUserForm = () => {
    const roleRef = roleData.find((item) => item.role === userInfo.role.trim());

    const trimmedUserInfo = {
      username: userInfo.username.trim(),
      firstName: userInfo.firstName.trim(),
      lastName: userInfo.lastName.trim(),
      role: roleRef._id,
      password: userInfo.password.trim(),
      confirmPassword: userInfo.confirmPassword.trim(),
    };

    if (id) {
      const updatedUserInfo = {
        ...trimmedUserInfo,
        userId: id,
      };
      updateUser(updatedUserInfo);
    } else {
      createUser(trimmedUserInfo);
    }
  };

  const handleDiscardChanges = () => {
    const state = { ...location.state, isEditUser: false, name: [`Users`] };
    navigation("/Setting/users", { state });
    setCancelEditUserModal(false);
  };

  const modalBtnInfo = useMemo(
    () => [
      {
        btnName: "Discard",
        bgColor: "#E77B7B",
        click: handleDiscardChanges,
      },
      {
        btnName: "Keep",
        bgColor: "#50C878",
        click: () => setCancelEditUserModal(false),
      },
    ],
    [handleDiscardChanges]
  );

  useEffect(() => {
    const fetchData = () => {
      if (allUsers && id) {
        let editUserData = allUsers?.data?.User.find((user) => user._id === id);
        if (editUserData) {
          setUserInfo({
            username: editUserData.username || "",
            firstName: editUserData.firstName || "",
            lastName: editUserData.lastName || "",
            role: editUserData.role?.roleName || "",
            // password: editUserData.plainPassword || "",
            // confirmPassword: editUserData.plainPassword || "",
            password: "",
            confirmPassword: "",
          });
          // delete editUserData._id;

          setInitialUserInfo((prevState) => ({
            ...prevState,
            ...editUserData,
            role: editUserData.role?.roleName || "",
            // password: editUserData.plainPassword || "",
            // confirmPassword: editUserData.plainPassword || "",
            password: "",
            confirmPassword: "",
          }));
        }
      }
    };
    fetchData();
  }, [id, allUsers]);

  useEffect(() => {
    setCancelEditUserModal(false);
  }, [id]);

  const checkEditorNot = (data) => {
    if (data) {
      const isChnages = findEditValue(data);
      setCancelEditChanges(isChnages);
      return isChnages;
    }
  };

  useEffect(() => {
    if (addIsSuccess || updateIsSuccess) {
      setUserCreatedStatus(true);
      const state = { ...location.state, isEditUser: false, name: [`Users`] };
      navigation("/Setting/users", { state });
    } else if (addIsError || updateIsError) {
      toast.error(`${addError?.response?.data?.message || addError.message || updateError || "Something went wrong"}`, {
        position: "top-right",
        autoClose: 3000,
      });
    }
  }, [addIsSuccess, updateIsSuccess, addIsError, updateIsError, addError, updateError]);

  const fetchRoles = async () => {
    try {
      const data = await getAllRoles();
      const newData = data?.data?.map((item) => ({
        _id: item._id,
        role: item.roleName,
      }));
      setRoleData(newData);
    } catch (error) {
      console.log("Some thing Went Wrong When Call Roles");
    }
  };

  useEffect(() => {
    fetchRoles();
  }, []);

  return (
    <ThemeProvider theme={custumTheme}>
      <Box sx={{ marginTop: "15px", marginLeft: location?.state?.isEditUser ? "-13.5px" : "-10px", marginRight: "0px" }}>
        <Grid container rowGap="10px" sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          {usersFilds(roleData, id).map((item) => (
            <OutlineInput key={item.name} item={item} onChange={handleChnage} value={userInfo[item.name]} isEditUser={id && item.name === "username" ? true : false} />
          ))}
        </Grid>
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "30px" }}>
          <Button disabled={location?.state?.isEditUser ? checkEditorNot(userInfo) : findEmptyValue()} onClick={handleSubmitUserForm}>
            {location?.state?.isEditUser ? "Save Changes" : "Save User"}
          </Button>
        </Box>
      </Box>
      <RemoveModal open={cancelEditUserModal} setOpen={setCancelEditUserModal} btnInfo={modalBtnInfo} heading="Do you want to Discard All Changes?" />
      <ToastContainer />
    </ThemeProvider>
  );
};
