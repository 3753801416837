import { replace } from "lodash";
import { check } from "prettier";

export const settingsRoutes = [
  { name: "Main Activity", path: "main-activity", check: "Main Activity" },
  { name: "Orders", path: "orders", check: "Orders" },
  { name: "Users", path: "users", check: "User" },
  { name: "Roles", path: "roles", check: "Roles" },
  { name: "Rights", path: "rights", check: "Rights" },
  { name: "Profile", path: "profile", check: "Profile" },
  { name: "Archives", path: "archives", check: "Archives" },
  { name: "Limits", path: "limits", check: "Limits" },
  { name:"Synchronize",path:"synchronize",check:"Synchronize"},
  { name: "Missing Images", path: "missing-images", check: "Missing Images" },
  { name: "Restricted Designs", path: "restricted-designs", check: "Restricted Designs" ,replace:"Restricted Category" },

  // { name: "Missing Images", path: "missing-images", check: "Missing Images" },
];
