import React, { useCallback, useContext, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { AppBar, Badge, Box, IconButton, Toolbar, InputBase, Divider } from "@mui/material";
import { ReactComponent as LogoutIcon } from "assets/icons/logout.svg";
import CatalogueEditHeader from "./CatalogueEditHeader";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "components/ui/Button";
import { useGlobalContext } from "context/GlobalContext";
import CartShowHeader from "./CartShowHeader";
import CartIcon from "components/Icons/CartIcon";
import NotificationIcon from "components/Icons/NotificationIcon";
import RemoveModal from "components/ui/RemoveModal/RemoveModal";
import SortContext from "context/SortContext";
import EditSetHeader from "./EditSetHeader";
import { debounce, set } from "lodash";
import OrdersNotificationModal from "features/Setting/order/components/OrdersNotificationModal";
import Tooltip from "components/ui/Tooltip";
import EditCatagoryHeader from "./EditCatagoryHeader";
import SettingHeader from "./SettingHeader";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: "#FFFFFF",
  "&:hover": {
    backgroundColor: "#f0f0f0",
  },
  marginLeft: 0,
  width: "100%",
  boxShadow: "inset 0px 4px 20px 0px #00000040",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "black",
  height: "40px",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 1),
    transition: theme.transitions.create("width"),

    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "40ch",
    },
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  width: "100%",
  fontFamily: "Poppins",
  fontWeight: 500,
  fontSize: "18px",
  lineHeight: "22px",
  height: "42px",
  borderRadius: "5px",
  color: "#E77B7B",
  border: "1px solid #E77B7B",
  textTransform: "none",
  backgroundColor: "transparent",
  "&:hover": {
    backgroundColor: "#E77B7B",
    color: "black",
  },
}));

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [cancelDesignModal, setCancelDesignModal] = useState(false);
  const [logoutModal, setLogoutModal] = useState(false);
  const [orderModalOpen, setOrderModalOpen] = useState(false);
  const [cancelDesignModaleHeding, setCancelDesignModalHeading] = useState("Do you want to Discard the Cart ?");
  const { isEdit, setSearch, search, isCollectionEdit } = useContext(SortContext);
  let [state, setState] = useState({});
  // const state = location.state;
  const editValue = state && state.edit ? state.edit : false;
  const catalogueName = state && state.catalogueName ? state.catalogueName : null;
  // const collectionName = state && state.collectionName ? state.collectionName : null;

  const innerChnages = state && state.innerChnages ? state.innerChnages : false;
  const newName = state && state.newName ? state.newName : null;
  const openCart = state && state.openCart ? state.openCart : false;

  /* Calculate Count For Cart Icon Badge */
  const { countCart, setCountCart, showCollectionTrashIcon, setAddDesignBtn, searchPlaceHolder} = useGlobalContext();
  const [notificationCount, setNotificationCount] = useState(0);

  const modalBtnInfo = React.useMemo(
    () => [
      {
        btnName: "log out",
        bgColor: "#E77B7B",
        click: handlelogout,
      },
      {
        btnName: "Keep",
        bgColor: "#50C878",
        click: () => setLogoutModal(false),
      },
    ],
    []
  );

  const handleCancle = () => {
    if (innerChnages) {
      if (!countCart) {
        if (state?.cataloguUpdate) {
          state = {
            ...state,
            name: state.backName,
            subName: "Edit",
            edit: true,
            innerChnages: false,
          };
          navigate(state.backPath, { state });
        } else if (state?.collectionUpdate) {
          state = {
            ...state,
            name: state.backName,
            innerChnages: false,
            collection: true,
          };
          navigate(state.backPath, { state });
        } else {
          navigate(state.backPath);
        }
        setCountCart(0);
        sessionStorage.removeItem("CartData");
      } else {
        if (state.backPath) {
          let headingSection = state.backPath.slice(1);
          headingSection = `Do you want to Discard the ${headingSection} ?`;
          setCancelDesignModalHeading(headingSection);
        }
        setCancelDesignModal(true);
      }
    }
  };

  const cancelDesignModalBtnInfo = [
    {
      btnName: "Discard",
      bgColor: "#E77B7B",
      click: () => {
        if (state?.cataloguUpdate) {
          state = {
            ...state,
            name: state.backName,
            subName: "Edit",
            edit: true,
            innerChnages: false,
          };
          navigate(state.backPath, { state });
        } else if (state?.collectionUpdate) {
          state = {
            ...state,
            name: state.backName,
            innerChnages: false,
            collection: true,
          };
          setAddDesignBtn(false);

          navigate(state.backPath, { state });
        } else {
          navigate(state.backPath);
        }
        setCountCart(0);
        sessionStorage.removeItem("CartData");
        setCancelDesignModal(false);
      },
    },
    {
      btnName: "Keep",
      bgColor: "#50C878",
      click: () => setCancelDesignModal(false),
    },
  ];

  const handleShowCart = () => {
    if (innerChnages) {
      state = { ...state, openCart: true };
      navigate("/Cart", { state });
    }
  };

  function handlelogout() {
    sessionStorage.removeItem("user");
    sessionStorage.removeItem("userData");
    localStorage.removeItem("userData");
    navigate("/login");
  }
  // const handlelogout = () => {
  //   sessionStorage.removeItem("user");
  //   sessionStorage.removeItem("userData");
  //   localStorage.removeItem("userData");
  //   navigate("/login");
  // };

  const handleSearch = useCallback(
    debounce((e) => {
      setSearch(e.target.value);
    }, 500),
    []
  );

  useEffect(() => {
    setState(location.state);
  }, [location]);

  useEffect(() => {
    const cartData = JSON.parse(sessionStorage.getItem("CartData"));
    const count = cartData?.length || 0;
    setCountCart(count);
  }, []);

  return (
    <>
      <AppBar position="fixed" elevation={0} sx={{ backgroundColor: "#000", padding: "5px 5px 2px 5px" }}>
        <Toolbar>
          {!innerChnages ? <Box sx={{ flexGrow: 1 }} /> : <Box sx={{ flexGrow: 1, fontWeight: 400, fontSize: "24px" }}>{catalogueName ? catalogueName : newName}</Box>}

          {isEdit === "click" || isCollectionEdit === "click" ? (
            <Box sx={{ display: "flex", alignItems: "center", gap: 4 }}>
              <EditSetHeader />
            </Box>
          ) : null}

          {location?.state?.isEditCatagory ? (
            <Box sx={{ display: "flex", alignItems: "center", gap: 4 }}>
              <EditCatagoryHeader />
            </Box>
          ) : null}

          {/* {location?.state?.isRestricted ? (
            <Box sx={{ display: "flex", alignItems: "center", gap: 4 }}>
              <SettingHeader />
            </Box>
          ) : null} */}

          <Box sx={{ display: isEdit === "click" || isCollectionEdit === "click" || location?.state?.isEditCatagory ? "none" : null }}>
            {!editValue ? (
              <Box sx={{ display: "flex", alignItems: "center", gap: 4 }}>
                {!openCart ? (
                  <>
                    <Search>
                      <StyledInputBase
                        placeholder={searchPlaceHolder}
                        onChange={(e) => setSearch(e.target.value)}
                        value={search}
                        inputProps={{ "aria-label": "search" }}
                        sx={{ height: "40px", borderRadius: "5px" }}
                      />
                    </Search>
                    <Tooltip title="Cart" marginTop="-5px">
                      <IconButton size="large" edge="end" color="success" disableRipple disableFocusRipple onClick={handleShowCart}>
                        <Badge badgeContent={countCart} color="success">
                          <CartIcon color={!countCart ? "white" : "#50C878"} />
                        </Badge>
                      </IconButton>
                    </Tooltip>
                  </>
                ) : (
                  <CartShowHeader Search={Search} StyledInputBase={StyledInputBase} btnTitle={newName || ""} setCountCart={setCountCart} countCart={countCart} origin={state} />
                )}

                {!innerChnages ? (
                  <Tooltip title="Notifications" marginTop="-5px">
                    <IconButton size="large" color="success" disableRipple disableFocusRipple onClick={() => setOrderModalOpen(true)}>
                      <Badge badgeContent={notificationCount} color="success">
                        <NotificationIcon color={!notificationCount ? "white" : "#50C878"} />
                      </Badge>
                    </IconButton>
                  </Tooltip>
                ) : openCart ? (
                  ""
                ) : (
                  <>
                    <StyledButton onClick={handleCancle}>Cancel</StyledButton>
                    {cancelDesignModal && <RemoveModal open={cancelDesignModal} setOpen={setCancelDesignModal} btnInfo={cancelDesignModalBtnInfo} heading={cancelDesignModaleHeding} />}
                  </>
                )}
              </Box>
            ) : (
              <CatalogueEditHeader Search={Search} StyledInputBase={StyledInputBase} catalogueName={catalogueName} />
            )}
          </Box>
          <Box sx={{ display: isEdit === "click" || isCollectionEdit === "click" || editValue || location?.state?.isEditCatagory ? "none" : null }}>
            <Tooltip title="Log-Out" marginTop="-5px">
              <IconButton size="large" edge="end" color="success" onClick={() => setLogoutModal(true)} disableRipple disableFocusRipple>
                <LogoutIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Toolbar>
        {/* display: !editValue ? null : "none" */}
      </AppBar>
      <Box
        sx={{
          border: "1px solid #000",
          display: "flex",
          justifyContent: "center",
          padding: "0px",
          paddingBottom: "10px",
          position: "sticky",
          zIndex: "1000",
          top: "70px",
          backgroundColor: "black !important",
        }}
        maxWidth={"100%"}
      >
        <Divider orientation="horizontal" flexItem style={{ borderColor: "#444444", width: !innerChnages && !editValue ? "95.4%" : "97%" }} />
      </Box>

      <RemoveModal open={logoutModal} setOpen={setLogoutModal} btnInfo={modalBtnInfo} heading="Are you sure you want to log out?" />
      <OrdersNotificationModal open={orderModalOpen} handleClose={() => setOrderModalOpen(false)} />
    </>
  );
};

export default Header;
