import apiClient from "lib/api-client";

export const fetchDetails = async ({ url, method, body = {}, params = {}, setLoading, setError }) => {
  try {
    setLoading(true);
    setError(null);
    let config = method === "get" ? { params } : body;
    const res = await apiClient[method](url, config);
    return res?.data;
  } catch (error) {
    setError("Failed to fetch sales details. Please try again.");
    return null;
  } finally {
    setLoading(false);
  }
};
