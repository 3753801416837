import { AppBar, Box, Divider, IconButton, InputBase, Toolbar, Typography, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ReactComponent as CatalogueIcon } from "assets/sidebar/enable/catalogue.svg";
import BreadCrumbComponent from "components/ui/BreadCrumb";
import Button from "components/ui/Button";
import { useCatalogSettingContext } from "context/CatalogSettingContext";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Sort } from "assets/icons/sort.svg";
import { usePermissions } from "context/PermissionsContext";
import { findSpecificPage, findSpecificSubPage, hasAccessSubPage } from "utils/checkUserRigths";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: "white",
  "&:hover": {
    backgroundColor: "#f0f0f0",
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "black",
  height: "40px",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 1),
    transition: theme.transitions.create("width"),

    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "40ch",
    },
  },
}));

const StyledButton = styled(Button)(({ theme, bgcolor }) => ({
  width: "100%",
  fontFamily: "Karla",
  fontWeight: 500,
  fontSize: "18px",
  lineHeight: "21.5px",
  height: "41px",
  borderRadius: "5px",
  color: bgcolor ? bgcolor : "#ffffff",
  border: `1px solid ${bgcolor ? bgcolor : " #ffffff"} `,
  textTransform: "none",
  backgroundColor: "transparent",
  "&:hover": {
    backgroundColor: bgcolor ? bgcolor : "#ffffff",
    color: "black",
  },
  "&.Mui-disabled": {
    backgroundColor: "transparent",
    color: "#8B8B8B",
    border: "1px solid #8B8B8B",
  },
}));

export const CatalogSettingHeader = ({ location }) => {
  const navigate = useNavigate();
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const catalogName = location?.state?.catalogName;
  const catalogId = location?.state?.catalogId;
  const {
    setShareLinkOpen,
    userActivity,
    userReport,
    userCurrentPath,
    setTimeSpentCatalogOpen,
    setUploadDataModal,
    setSortModal,
    isRenewAccess,
    setRenewAccessModal,
    revokAccessModal,
    setRevokAccessModal,
    setUpdateClient,
    setIsEditClinet,
    serarch,
    setSerarch,
  } = useCatalogSettingContext();

  const permission = usePermissions();
  const hasViewMobileNumber = hasAccessSubPage(findSpecificSubPage(findSpecificPage("Catalog Setting", permission), "Add Mobile No."), "view");
  const hasAddMobileNumber = hasAccessSubPage(findSpecificSubPage(findSpecificPage("Catalog Setting", permission), "Add Mobile No."), "add");
  const hasViewUploadBulk = hasAccessSubPage(findSpecificSubPage(findSpecificPage("Catalog Setting", permission), "Bulk Mobile No. List"), "view");
  const hasAddUploadBulk = hasAccessSubPage(findSpecificSubPage(findSpecificPage("Catalog Setting", permission), "Bulk Mobile No. List"), "add");
  const hasViewCatalogReport = hasAccessSubPage(findSpecificSubPage(findSpecificPage("Catalog Setting", permission), "Catalog Report"), "view");
  const hasViewRevokAccess = hasAccessSubPage(findSpecificSubPage(findSpecificPage("Client Activity", permission), "Renew & Revoke Access"), "view");
  const hasAddRevokAccess = hasAccessSubPage(findSpecificSubPage(findSpecificPage("Client Activity", permission), "Renew & Revoke Access"), "add");

  const handlAddMobileModal = () => {
    setUpdateClient({});
    setIsEditClinet(false);
    setShareLinkOpen(true);
  };

  const handlTimeSpentCatalog = () => {
    setTimeSpentCatalogOpen(true);
  };

  const handleUploadDataModal = () => {
    setUploadDataModal(true);
  };

  const handlOnCatalogTimeSpent = () => {
    navigate(`Catalog-Report/${catalogId}`, { ...location });
  };

  const handleRenewAccess = () => {
    setRenewAccessModal(true);
  };

  const handleRevokAccess = () => {
    //  setRevokData(curData);
    setRevokAccessModal(true);
  };

  useEffect(() => {
    let newBreadcrumbs = [];
    newBreadcrumbs.push({
      name: "Catalog Access Management",
      path: `/CatalogSetting/${catalogId}`,
      // path: location?.state?.pathname,
    });

    if (Object.keys(userCurrentPath).length > 0) {
      newBreadcrumbs.push(userCurrentPath);
    }

    setBreadcrumbs(newBreadcrumbs);
  }, [userCurrentPath]);
  return (
    <>
      <div style={{ height: "auto" }}>
        <div position="fixed" elevation={0} sx={{ backgroundColor: "#000" }}>
          <Toolbar>
            <Box sx={{ flexGrow: 1 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", gap: "10px", paddingLeft: "4px" }}>
                  <IconButton sx={{ padding: "0px" }}>
                    <CatalogueIcon />
                  </IconButton>
                  <Typography
                    sx={{
                      color: "#FFFFFF",
                      fontFamily: "Poppins",
                      fontWeight: 400,
                      fontSize: "24px",
                      lineHeight: "36px",
                    }}
                  >
                    Catalog Setting - {catalogName}
                  </Typography>
                </Box>
                {!userReport && (
                  <Search>
                    <StyledInputBase
                      value={serarch}
                      onChange={(e) => setSerarch(e.target.value)}
                      placeholder="Search"
                      inputProps={{ "aria-label": "search" }}
                      sx={{ height: "40px", borderRadius: "5px" }}
                    />
                  </Search>
                )}
              </Box>
            </Box>
          </Toolbar>
        </div>
      </div>
      <div style={{ height: "auto" }}>
        <div style={{ position: "sticky", zIndex: "1000", top: "70px", background: "black" }}>
          <Box
            sx={{
              border: "1px solid #000",
              display: "flex",
              justifyContent: "center",
              padding: "0px",
              paddingBottom: "10px",
              position: "sticky",
              zIndex: "999",
              top: "70px",
              backgroundColor: "black !important",
            }}
            maxWidth={"100%"}
          >
            <Divider orientation="horizontal" flexItem style={{ borderColor: "#444444", width: "95.4%" }} />
          </Box>
        </div>
        <div style={{ height: "auto" }}>
          <Box className="child-header" sx={{ padding: "20px", paddingBottom: "15px", paddingTop: "15px", paddingLeft: "20px", paddingRight: "20px" }}>
            <div className="child-header-sub">
              <BreadCrumbComponent breadcrumbs={breadcrumbs} />
            </div>
            {!userReport && (
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <div className="child-header-create-btn">
                  <StyledButton onClick={handlTimeSpentCatalog} disableRipple disableFocusRipple>
                    Total Time Spent on Catalog
                  </StyledButton>
                </div>
                <div className="child-header-sort">
                  <IconButton onClick={() => setSortModal(true)} disableRipple disableFocusRipple>
                    <Sort />
                  </IconButton>
                </div>
              </div>
            )}
            {!userActivity &&
              (isRenewAccess
                ? hasViewRevokAccess && (
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                      <div className="child-header-create-btn">
                        <StyledButton disabled={!hasAddRevokAccess} bgcolor="#50C878" sx={{ width: "150px" }} onClick={handleRenewAccess}>
                          Renew Access
                        </StyledButton>
                      </div>
                    </div>
                  )
                : hasViewRevokAccess && (
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                      <div className="child-header-create-btn">
                        <StyledButton disabled={!hasAddRevokAccess} bgcolor={"#E77B7B"} sx={{ width: "150px" }} onClick={handleRevokAccess}>
                          Revoke Access
                        </StyledButton>
                      </div>
                    </div>
                  ))}
            {userActivity && userReport && (
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                {hasViewMobileNumber && (
                  <div
                    className="child-header-create-btn-catalog-setting"
                    style={{ paddingRight: hasViewUploadBulk || hasViewCatalogReport || (hasViewUploadBulk && hasViewCatalogReport) ? "" : "10px" }}
                  >
                    <StyledButton disableRipple disableFocusRipple disabled={!hasAddMobileNumber} onClick={handlAddMobileModal}>
                      Add Mobile Number
                    </StyledButton>
                  </div>
                )}
                {hasViewUploadBulk && (
                  <div className="child-header-create-btn-catalog-setting" style={{ paddingRight: hasViewCatalogReport ? "" : "10px" }}>
                    <StyledButton onClick={handleUploadDataModal} disabled={!hasAddUploadBulk} disableRipple disableFocusRipple>
                      Upload Bulk List of Number
                    </StyledButton>
                  </div>
                )}
                {hasViewCatalogReport && (
                  <div className="child-header-create-btn">
                    <StyledButton onClick={handlOnCatalogTimeSpent} disableRipple disableFocusRipple>
                      Catalog Reports
                    </StyledButton>
                  </div>
                )}
              </div>
            )}
          </Box>
        </div>
      </div>
    </>
  );
};
