import { ReactComponent as GoldCup } from "assets/cup_icon/gold-cup.svg";
import { ReactComponent as SilverCup } from "assets/cup_icon/silver-cup.svg";
import { ReactComponent as BronzeCup } from "assets/cup_icon/brown-cup.svg";

const renderIconForBestSellers = (item) => {
  switch (item) {
    case "Gold":
      return <GoldCup />;
    case "Silver":
      return <SilverCup />;
    case "Bronze":
      return <BronzeCup />;
    default:
      return null;
  }
};

export default renderIconForBestSellers;
