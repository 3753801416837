import React from "react";
import { Modal as MuiModal, Box, Slide } from "@mui/material";

function Modal({ open, handleClose, style, children }) {
  const modalStyle = {
    position: "fixed",
    bottom: 0,
    right: 0,
    width: style?.width || "350px",
    height: style?.height || "auto",
    maxHeight: "87vh",
    overflowY: style?.scroll || "auto",
    "&::-webkit-scrollbar": {
      width: "0px",
      height: "0px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "transparent",
    },
    boxShadow: style?.boxShadow ? style.boxShadow : 24,
    // border: "1px solid red",
    // p: "auto",
    // p: "30px 20px 30px 20px",
    p: style?.padding || "30px 20px 30px 20px",
    m: "auto",
    mb: 2,
    mr: style.mr ? style.mr : 3.5,
    bgcolor: "white",
    color: "#00000",
    borderRadius: "5px",
    outline: "none",
  };
  return (
    <MuiModal open={open} onClose={handleClose} BackdropProps={{ style: { backgroundColor: "transparent" } }}>
      <Slide direction="left" in={open} timeout={500} mountOnEnter unmountOnExit>
        <Box sx={modalStyle}>{children}</Box>
      </Slide>
    </MuiModal>
  );
}

export default Modal;
