import { ReactComponent as GoldIcon } from "assets/filter/gold.svg";
import { ReactComponent as SilverIcon } from "assets/filter/silver.svg";
import { ReactComponent as BronzeIcon } from "assets/filter/bronze.svg";
import { ReactComponent as GoldCup } from "assets/cup_icon/gold-cup.svg";
import { ReactComponent as SilverCup } from "assets/cup_icon/silver-cup.svg";
import { ReactComponent as BronzeCup } from "assets/cup_icon/brown-cup.svg";


const renderIcon = (item, category) => {
  switch (item) {
    case "Gold":
      return category === "Class" ? <GoldIcon /> : <GoldCup />;
    case "Silver":
      return category === "Class" ? <SilverIcon /> : <SilverCup />;
    case "Bronze":
      return category === "Class" ? <BronzeIcon /> : <BronzeCup />;
    default:
      return null;
  }
};

export default renderIcon;
